import React from 'react';
import IconButton from './IconButton/IconButton';
import './IconButtonsGrid.css';

function IconButtonsGrid({id, mode, buttons, defaultAction}) {
    const renderButtons = () => {
        return buttons.map((button, index) => (
            <IconButton
                key={`${id}-icon-button-${index}`}
                icon={button.icon}
                label={button.label}
                labelColor={button.labelColor}
                backgroundColor={button.backgroundColor}
                defaultAction={defaultAction}
                action={button.action}
                active={button.active}
                activeIcon={button.activeIcon}
                activeLabel={button.activeLabel}
                activeLabelColor={button.activeLabelColor}
                activeBackgroundColor={button.activeBackgroundColor}
                stopPropagation={button.stopPropagation}
                showPulseEffect={button.showPulseEffect}
                showPulseWhenActive={button.showPulseWhenActive}
                visible={button.visible}
                disabled={button.disabled}
            />
        ));
    };

    return (
        <div className={`icon-buttons-grid ${mode}`}>
            {renderButtons()}
        </div>
    );
}

export default IconButtonsGrid;