import React, {useState} from 'react';
import ImageWrapper from "../../ImageWrapper/ImageWrapper";
import './ActionBarMobileItem.css';

function ActionBarMobileItem({
    active,
    enabled,
    label,
    icon,
    activeIcon,
    disabledIcon,
    labelColor,
    backgroundColor,
    activeLabelColor,
    activeBackgroundColor,
    disabledLabelColor,
    disabledBackgroundColor,
    indicatorBackgroundColor,
    indicatorLabelColor,
    showNotification,
    notificationNumber,
    onClick
}) {
    const [isHovering, setIsHovering] = useState(false);
    const validLabelColor = labelColor || '#414042';
    const validBackgroundColor = backgroundColor || '#FFFFFF';
    const validActiveLabelColor = activeLabelColor || '#FFFFFF';
    const validActiveBackgroundColor = activeBackgroundColor || '#B3E9FF';
    const validDisabledLabelColor = disabledLabelColor || '#E3E3E3';
    const validDisabledBackgroundColor = disabledBackgroundColor || '#FFFFFF';
    const validIndicatorBackgroundColor = indicatorBackgroundColor || '#FFB819';
    const validIndicatorLabelColor = indicatorLabelColor || '#111111';

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const handleClick = () => {
        if (onClick && enabled) onClick();
    };

    const normalStateStyle = () => {
        return {
            opacity: enabled && !active && !isHovering ? 1 : 0
        };
    };

    const activeStateStyle = () => {
        return {
            opacity: enabled && (active || isHovering) ? 1 : 0
        };
    };

    const disabledStateStyle = () => {
        return {
            opacity: enabled ? 0 : 1
        };
    };

    const itemStyle = () => {
        return {
            background: enabled ? (active || isHovering ? validActiveBackgroundColor : validBackgroundColor) : validDisabledBackgroundColor
        };
    };

    const labelStyle = () => {
        return {
            color: enabled ? (active || isHovering ? validActiveLabelColor : validLabelColor) : validDisabledLabelColor
        };
    };

    const indicatorStyle = () => {
        return {
            background: validIndicatorBackgroundColor,
            color: validIndicatorLabelColor
        };
    };

    return (
        <div className='action-bar-mobile-item' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick} style={itemStyle()}>
            <div className='action-bar-mobile-item-icon'>
                <div className='action-bar-mobile-item-normal-state' style={normalStateStyle()}>
                    <ImageWrapper image={icon} alt={label} display='block' width={40} height='auto' loadDelay={0}/>
                </div>
                <div className='action-bar-mobile-item-active-state' style={activeStateStyle()}>
                    <ImageWrapper image={activeIcon || icon} alt={label} display='block' width={40} height='auto' loadDelay={0}/>
                </div>
                <div className='action-bar-mobile-item-disabled-state' style={disabledStateStyle()}>
                    <ImageWrapper image={disabledIcon || icon} alt={label} display='block' width={40} height='auto' loadDelay={0}/>
                </div>
            </div>
            <p className='action-bar-mobile-item-label' style={labelStyle()}>{label}</p>
            {showNotification && !!notificationNumber && notificationNumber > 0 && (
                <div className='action-bar-mobile-item-number' style={indicatorStyle()}>{notificationNumber}</div>
            )}
        </div>
    );
}

export default ActionBarMobileItem;