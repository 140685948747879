import React, {useState} from 'react';
import Text from '../../../helpers/Text';
import './CustomSelectOption.css';

function CustomSelectOption({label, value, customRenderer, showValueInLabel, formatLabel, hoverColor, onClick}) {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const handleClick = (event) => {
        event.stopPropagation();
        if (onClick) onClick(value);
    };

    const getLabel = () => {
        const formattedLabel = label ? (formatLabel ? Text.capitalizeAll(label) : label) : undefined;
        return showValueInLabel ? (formattedLabel ? `${formattedLabel} (${value})` : value) : (formattedLabel || value);
    };

    const hasCustomRenderer = () => {
        return !!customRenderer;
    };

    const optionStyle = () => {
        return {
            backgroundColor: isHovering ? (hoverColor ? hoverColor : '#B3E9FF') : 'transparent'
        };
    };

    return (
        <div className={`custom-select-option ${hasCustomRenderer() ? 'custom' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick} style={optionStyle()}>
            {hasCustomRenderer() ? customRenderer() : getLabel()}
        </div>
    );
}

export default CustomSelectOption;