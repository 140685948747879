import React, {useRef, useEffect, useState} from 'react';
import noImage from './images/no-image.png';
import './ImageWrapper.css';

function ImageWrapper({image, alt, display, width, height, borderRadius, transition = true, loadDelay, onLoad}) {
    const imageRef = useRef(null);
    const [isLoadingImage, setIsLoadingImage] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const element = imageRef.current;
        element.onload = () => {
            setTimeout(() => {
                setIsLoadingImage(false);
                setImageLoaded(true);
                if (onLoad) onLoad();
            }, loadDelay * 1000);
        };
        element.onerror = () => {
            setTimeout(() => {
                setIsLoadingImage(false);
                setImageLoaded(false);
            }, loadDelay * 1000);
        };
        element.src = getImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const element = imageRef.current;
        const newImage = getImage();
        const oldImage = element.src;
        if (newImage !== oldImage) {
            setIsLoadingImage(true);
            setTimeout(() => {element.src = newImage}, loadDelay * 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image]);

    const getImage = () => {
        return !image || (!isLoadingImage && !imageLoaded) ? noImage : image;
    };

    const containerStyle = () => {
        return {
            display: display,
            width: width || 30,
            height: height || 30
        };
    }

    const imageStyle = () => {
        return {
            width: width || 30,
            height: height || 30,
            transform: `scale(${isLoadingImage ? 0 : 1})`,
            borderRadius: borderRadius || 0,
            transition: transition ? 'transform 0.2s ease' : 'unset'
        };
    };

    return (
        <div className='image-wrapper-container' style={containerStyle()}>
            <img className='image-wrapper' ref={imageRef} alt={alt} style={imageStyle()} draggable={false}/>
        </div>
    );
}

export default ImageWrapper;