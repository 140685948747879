import React from 'react';
import './ProductLoader.css';

function ProductLoader() {
    return (
        <span className='product-loader'>
        </span>
    );
}

export default ProductLoader;