import React, {useRef, useEffect, useState} from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import ImageWrapper from '../ImageWrapper/ImageWrapper';
import Debug from '../../helpers/Debug';
import Activity from '../../helpers/Activity';
import Viewport from '../../helpers/Viewport';
import defaultQrScanImage from './images/scan.gif';
import QRCode from 'qrcode';
import './HelpModal.css';

function HelpModal({helpUrl, qrScanImage, cancelButtonBackgroundColor, cancelButtonBorderColor, cancelButtonLabelColor, onClose, metadata}) {
    const canvasRef = useRef(null);
    const [error, setError] = useState(false);
    const viewport = Viewport.dimensions;
    const smallModeTreshold = {width: 768, height: 600};

    useEffect(() => {
        if (helpUrl) renderQrCode();
        else setError(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const inSmallMode = () => {
        return viewport.width <= smallModeTreshold.width || viewport.height <= smallModeTreshold.height;
    };

    const handleClose = (closeType) => {
        if (onClose) {
            Activity.log(metadata, 'help-modal', closeType, 'close', undefined);
            onClose();
        }
    };

    const renderQrCode = () => {
        const canvas = canvasRef.current;
        QRCode.toCanvas(canvas, helpUrl, {width: 200, height: 200}, (error) => {
            if (error) {
                Debug.printToLog('error', error);
                setError(true);
            }
        });
    };

    return (
        <Modal mode='center' smallMode={inSmallMode()} backgroundColor='#FFFFFF' borderRadius={{topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50}} padding={40} width={800} maxWidth='100%' height='auto' maxHeight='100%' showDefaultCloseButton={false} onClose={handleClose}>
            <div className='help-modal'>
                {!error && (
                    <React.Fragment>
                        <p className='help-modal-title'><span className='bold'>¿Necesitas ayuda?</span></p>
                        <p className='help-modal-description'><span className='bold'>Contacta a nuestros asistentes</span> y resuelve todas tus dudas</p>
                        <div className='help-modal-content'>
                            <div className='help-modal-image'>
                                <ImageWrapper image={qrScanImage || defaultQrScanImage} alt='Scan' display='block' width={200} height={200} borderRadius='100%' loadDelay={0.2}/>
                            </div>
                            <div className='help-modal-qr-image-container'>
                                <canvas ref={canvasRef} className='help-modal-qr-image-canvas'></canvas>
                                <p className='help-modal-qr-image-description'>Escanea el código</p>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {error && (
                    <div className='help-modal-error'>Lo sentimos, se ha producido un error al generar el código QR</div>
                )}
                <div className='help-modal-actions'>
                    <div className='help-modal-action'>
                        <Button label='Cerrar' labelColor={cancelButtonLabelColor} backgroundColor={cancelButtonBackgroundColor} borderColor={cancelButtonBorderColor} minWidth='auto' maxWidth='100%' boxShadow='unset' onClick={() => handleClose('custom-close-button')}/>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default HelpModal