import React, {useRef, useState, useEffect} from 'react';
import './MainLogo.css';

function MainLogo({backgroundColor, backgroundTopPadding, backgroundRightPadding, backgroundBottomPadding, backgroundLeftPadding, backgroundWidth, image, imageAlign, imageWidth, imageHeight}) {
    const imageRef = useRef(null);
    const [loaded, setLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const element = imageRef.current;
        element.onload = () => {
            setTimeout(() => {
                setIsLoading(false);
                setLoaded(true);
            }, 200);
        };
        element.onerror = () => {
            setTimeout(() => {
                setIsLoading(false);
                setLoaded(false);
            }, 200);
        };
        element.src = image;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const element = imageRef.current;
        const newImage = image;
        const oldImage = element.src;
        if (newImage !== oldImage) {
            setIsLoading(true);
            setTimeout(() => {element.src = newImage}, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image]);

    const backgroundStyle = () => {
        return {
            backgroundColor: backgroundColor || '#FFFFFF',
            paddingTop: isLoading || !image ? 0 : (backgroundTopPadding || 0),
            paddingRight: isLoading || !image ? 0 : (backgroundRightPadding || 0),
            paddingBottom: isLoading || !image ? 0 : (backgroundBottomPadding || 0),
            paddingLeft: isLoading || !image ? 0 : (backgroundLeftPadding || 0),
            width: backgroundWidth || '100%',
            height: imageHeight ? (imageHeight + (backgroundTopPadding + backgroundBottomPadding)) : 'auto',
            textAlign: imageAlign || 'center',
            transform: `scale(${image && !isLoading && loaded ? 1 : 0})`
        };
    };

    const imageStyle = () => {
        return {
            width: imageWidth || 'auto',
            height: imageHeight || 'auto'
        };
    };

    return (
        <div className='main-logo-background' style={backgroundStyle()}>
            <img ref={imageRef} className='main-logo' alt='Main Logo' style={imageStyle()}/>
        </div>
    );
}

export default MainLogo;