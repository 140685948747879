import React, {useState, useEffect, forwardRef} from 'react';
import DataError from '../DataError/DataError';
import CrossLoader from '../CrossLoader/CrossLoader';
import Navigation from '../../config/Navigation';
import './ModelViewer.css';

const ModelViewer = forwardRef(
    function ModelViewer({url, errorActionButtonLabelColor, errorActionButtonBackgroundColor, errorActionButtonBorderColor}, ref) {
        const [error, setError] = useState(false);
        const [isLoading, setIsLoading] = useState(true);
        const [loaded, setLoaded] = useState(false);

        useEffect(() => {
            if (url) {
                // noinspection JSUnresolvedReference
                const iframe = ref.current;
                iframe.onload = () => {
                    setTimeout(() => {
                        setIsLoading(false);
                        setLoaded(true);
                    }, 1000);
                };
                iframe.onerror = () => {
                    setTimeout(() => {
                        setIsLoading(false);
                        setLoaded(false);
                    }, 1000);
                };
                iframe.src = url;
            } else setError(true);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const iframeStyle = () => {
            const show = !isLoading && loaded;
            return {
                opacity: show ? 1 : 0
            };
        };

        const loaderStyle = () => {
            const show = url && isLoading;
            return {
                opacity: show ? 1 : 0
            };
        };

        const errorStyle = () => {
            const show = !isLoading && !loaded;
            return {
                opacity: show ? 1 : 0,
                width: show ? 'auto' : 0,
                height: show ? 'auto' : 0
            };
        };

        return (
            <div className='model-viewer'>
                {!error && (
                    <div className='model-viewer-wrapper'>
                        <iframe ref={ref} className='model-viewer-iframe' title='3D Model' style={iframeStyle()}>
                        </iframe>
                        <div className='model-viewer-loader' style={loaderStyle()}>
                            <CrossLoader size={200}/>
                        </div>
                        <div className='model-viewer-error' style={errorStyle()}>
                            <DataError
                                message='Se ha producido un error al cargar la escena 3D'
                                action={Navigation.reload}
                                actionLabel='Reintentar'
                                actionButtonLabelColor={errorActionButtonLabelColor}
                                actionButtonBackgroundColor={errorActionButtonBackgroundColor}
                                actionButtonBorderColor={errorActionButtonBorderColor}
                            />
                        </div>
                    </div>
                )}
                {error && (
                    <div className='model-viewer-error'>
                        <DataError message='El enlace de la escena 3D no es válido'/>
                    </div>
                )}
            </div>
        );
    }
);

export default ModelViewer;