import React from 'react';
import Activity from '../../../helpers/Activity';
import checkIcon from './images/check.svg';
import './NormalFilter.css';

function NormalFilter({category, searchQuery, filterId, optionId, filterLabel, optionLabel, selected, checkboxBackgroundColor, onClick, metadata}) {

    const handleClick = () => {
        if (onClick) {
            Activity.log(metadata, 'filter-option', optionId, selected ? 'deselect' : 'select', {category_id: category.id, category_name: category.name, search_query: searchQuery || null, filter_id: filterId, option_id: optionId, filter_label: filterLabel, option_label: optionLabel});
            onClick(filterId, optionId);
        }
    };

    const checkContainerStyle = () => {
        return {
            backgroundColor: selected ? (checkboxBackgroundColor || '#005B80') : '#FFFFFF',
            border: `solid 1px ${selected ? 'transparent' : '#979797'}`
        };
    };

    return (
        <div className={`normal-filter ${selected ? 'selected' : ''}`} onClick={handleClick}>
            <div className='normal-filter-check-container' style={checkContainerStyle()}>
                {selected && <img className='normal-filter-check' src={`${checkIcon}`} alt='Selected'/>}
            </div>
            <p className='normal-filter-label'>{optionLabel || 'Label'}</p>
        </div>
    );
}

export default NormalFilter;