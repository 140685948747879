import React from 'react';
import ImageWrapper from "../../ImageWrapper/ImageWrapper";
import Currency from "../../../config/Currency";
import CurrencyFormat from "react-currency-format";
import './VariantBox.css';

function VariantBox({
    variant,
    selected,
    isLast,
    discountBackgroundColor,
    discountLabelColor,
    exclusivePriceLabelColor,
    exclusivePriceIcon,
    selectedBorderColor,
    onClick
}) {
    const currencyFormat = Currency.CLP;

    const handleClick = () => {
        if (onClick) onClick(variant.id);
    };

    const getImage = () => {
        return variant.getMainImage('m');
    };

    const variantStyle = () => {
        const image = getImage();
        const margin = 20;
        return {
            margin: `${margin}px 5px ${isLast ? margin : 0}px 5px`,
            borderRadius: image ? 15 : 5,
            border: `solid 5px ${selected ? (selectedBorderColor || '#6F909D') : 'transparent'}`,
            padding: image ? 10 : '30px 10px'
        };
    };

    const nameStyle = () => {
        return {
            marginTop: getImage() ? 20 : 0
        };
    };

    const discountStyle = () => {
        return {
            background: discountBackgroundColor || '#005B80',
            color: discountLabelColor || '#FFFFFF'
        };
    };

    const exclusivePriceStyle = () => {
        return {
            color: exclusivePriceLabelColor || '#005B80'
        };
    };

    const renderAsExclusivePrice = (price, discount) => {
        return (
            <div className='variant-box-info-price exclusive' style={exclusivePriceStyle()}>
                <CurrencyFormat
                    prefix={currencyFormat.prefix}
                    suffix={currencyFormat.suffix}
                    thousandSeparator={currencyFormat.thousandsSeparator}
                    decimalSeparator={currencyFormat.decimalSeparator}
                    decimalScale={currencyFormat.decimalScale}
                    value={price}
                    displayType='text'
                />
                {exclusivePriceIcon && (
                    <div className='variant-box-info-card'>
                        <ImageWrapper image={exclusivePriceIcon} alt='Card' display='block' width={35} height='auto' loadDelay={0.2}/>
                    </div>
                )}
                {discount && discount > 0 ? <div className='variant-box-info-discount' style={discountStyle()}>{discount}%</div> : null}
            </div>
        );
    };

    const renderAsOfferPrice = (price, discount) => {
        return (
            <div className='variant-box-info-price offer'>
                <CurrencyFormat
                    prefix={currencyFormat.prefix}
                    suffix={currencyFormat.suffix}
                    thousandSeparator={currencyFormat.thousandsSeparator}
                    decimalSeparator={currencyFormat.decimalSeparator}
                    decimalScale={currencyFormat.decimalScale}
                    value={price}
                    displayType='text'
                />
                {discount && discount > 0 ? <div className='variant-box-info-discount' style={discountStyle()}>{discount}%</div> : null}
            </div>
        );
    };

    const renderAsNormalPrice = (price) => {
        return (
            <div className='variant-box-info-price normal'>
                <span>Antes: </span>
                <div className='variant-box-info-normal-price-wrapper'>
                    <CurrencyFormat
                        prefix={currencyFormat.prefix}
                        suffix={currencyFormat.suffix}
                        thousandSeparator={currencyFormat.thousandsSeparator}
                        decimalSeparator={currencyFormat.decimalSeparator}
                        decimalScale={currencyFormat.decimalScale}
                        value={price}
                        displayType='text'
                    />
                </div>
            </div>
        );
    };

    const image = getImage();

    return (
        <div id={variant.id} className='variant' onClick={handleClick} style={variantStyle()}>
            {image && <ImageWrapper image={image} alt={variant.name} width='100%' height='auto' display='block' borderRadius={10}/>}
            <div className='variant-name' style={nameStyle()}>{variant.name}</div>
            {variant.hasAnyPrice() && (
                <div className='variant-box-info-prices'>
                    {variant.exclusivePrice && renderAsExclusivePrice(variant.exclusivePrice, variant.getExclusiveDiscountPercentage())}
                    {variant.offerPrice && renderAsOfferPrice(variant.offerPrice, variant.getOfferDiscountPercentage())}
                    {variant.normalPrice && variant.normalPrice !== variant.offerPrice && (variant.offerPrice ? renderAsNormalPrice(variant.normalPrice) : renderAsOfferPrice(variant.normalPrice, undefined))}
                </div>
            )}
        </div>
    );
}

export default VariantBox;