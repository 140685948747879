import React, {useState, useEffect, useRef} from 'react';
import CrossLoader from '../../CrossLoader/CrossLoader';
import Color from "../../../helpers/Color";
import Activity from '../../../helpers/Activity';
import noImage from './images/no-image.png';
import './CategoryOptionV2.css';

function CategoryOptionV2({parent, searchQuery, category, width, marginRight, selected, activeColor, pulseDuration, onClick, metadata}) {
    const wrapperRef = useRef(null);
    const imageRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingImage, setIsLoadingImage] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [pulseEffect, setPulseEffect] = useState(null);
    const [pulseTimeout, setPulseTimeout] = useState(null);

    useEffect(() => {
        const element = imageRef.current;
        element.onload = () => {
            setTimeout(() => {
                setIsLoadingImage(false);
                setImageLoaded(true);
            }, 1000);
        };
        element.onerror = () => {
            setTimeout(() => {
                setIsLoadingImage(false);
                setImageLoaded(false);
            }, 1000);
        };
        element.src = getImage();
        setIsLoading(false);
        startPulseEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const element = imageRef.current;
        const newImage = getImage();
        const oldImage = element.src;
        if (newImage !== oldImage) {
            setIsLoadingImage(true);
            setTimeout(() => {element.src = newImage}, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category.image]);

    useEffect(() => {
        if (pulseEffect) {
            const timeout = setTimeout(stopPulseEffect, pulseDuration * 1000);
            setPulseTimeout(timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pulseEffect]);

    useEffect(() => {
        if (pulseEffect) startPulseEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeColor]);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const handleClick = () => {
        if (onClick) {
            Activity.log(metadata, 'category-filter', category.id, selected ? 'deselect' : 'select', {parent_id: parent.id, parent_name: parent.name, category_id: category.id, category_name: category.name, search_query: searchQuery || null});
            onClick(category.id);
        }
    };

    const getImage = () => {
        return !category.image || (!isLoadingImage && !imageLoaded) ? noImage : category.image;
    };

    const startPulseEffect = () => {
        if (wrapperRef) {
            stopPulseEffect();
            stopPulseTimeout();
            const rgb = Color.hexToRgb(activeColor) || {r: 0, g: 91, b: 128};
            // noinspection JSUnresolvedReference
            const pulseEffect = wrapperRef.current.animate([
                {boxShadow: `0 0 0 0 rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9)`},
                {boxShadow: `0 0 0 10px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0)`},
                {boxShadow: `0 0 0 0 rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0)`}
            ], {
                duration: 1500,
                iterations: Infinity
            });
            setPulseEffect(pulseEffect);
        }
    };

    const stopPulseEffect = () => {
        if (pulseEffect) {
            pulseEffect.cancel();
            setPulseEffect(undefined);
        }
    };

    const stopPulseTimeout = () => {
        if (pulseTimeout) {
            clearTimeout(pulseTimeout)
            setPulseTimeout(undefined);
        }
    };

    const categoryStyle = () => {
        return {
            width: width ? width : 100,
            minWidth: width ? width : 100,
            marginRight: marginRight ? marginRight : 15,
            transform: `scale(${isLoading ? 0 : 1})`
        };
    };

    const imageWrapperStyle = () => {
        return {
            boxShadow: selected ? '0 0 15px rgba(0, 0, 0, 0.188693)' : 'unset',
            transform: `scale(${selected || isHovering ? 1.05 : 1})`,
            border: selected ? `solid 3px ${activeColor}` : 'solid 1px rgba(0, 0, 0, 0.05)'
        };
    };

    const imageStyle = () => {
        return {
            opacity: isLoadingImage ? 0 : 1
        };
    };

    const loaderStyle = () => {
        return {
            opacity: isLoadingImage ? 1 : 0
        };
    };

    const labelStyle = () => {
        return {
            color: selected ? (activeColor ? activeColor : '#005B80') : '#414042',
            fontWeight: selected ? '700' : '400'
        };
    };

    return (
        <div id={category.id} className='category-option-v2' style={categoryStyle()}>
            <div ref={wrapperRef} className={`category-image-wrapper-v2 ${selected ? 'selected' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick} style={imageWrapperStyle()}>
                <img ref={imageRef} className='category-image-v2' alt={category.name} style={imageStyle()}/>
                <div className='category-image-loader-v2' style={loaderStyle()}>
                    <CrossLoader/>
                </div>
            </div>
            <p className='category-name-v2' style={labelStyle()}>{category.name || 'Sin nombre'}</p>
        </div>
    );
}

export default CategoryOptionV2;