import React, {useEffect, useRef, useState} from 'react';
import defaultIcon from './images/accessibility.png';
import './AccessibilityButton.css';

function AccessibilityButton({icon, backgroundColor, action}) {
    const imageRef = useRef(null);
    const [isLoadingImage, setIsLoadingImage] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const loadDelay = 0.2; // Seconds.

    useEffect(() => {
        const element = imageRef.current;
        element.onload = () => {
            setTimeout(() => {
                setIsLoadingImage(false);
                setImageLoaded(true);
            }, loadDelay * 1000);
        };
        element.onerror = () => {
            setTimeout(() => {
                setIsLoadingImage(false);
                setImageLoaded(false);
            }, loadDelay * 1000);
        };
        element.src = getImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const element = imageRef.current;
        const newImage = getImage();
        const oldImage = element.src;
        if (newImage !== oldImage) {
            setIsLoadingImage(true);
            setTimeout(() => {element.src = newImage}, loadDelay * 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [icon]);

    const getImage = () => {
        return !icon || (!isLoadingImage && !imageLoaded) ? defaultIcon : icon;
    };

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const handleAction = () => {
        if (action) action();
    };

    const accessibilityButtonStyle = () => {
        return {
            backgroundColor: backgroundColor || '#B3E9FF',
            transform: `scale(${isLoadingImage ? 0 : (isHovering ? 1.1 : 1)})`
        };
    };

    return (
        <div className='accessibility-button' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleAction} style={accessibilityButtonStyle()}>
            <img ref={imageRef} className='accessibility-button-icon' alt='Accessibility'/>
        </div>
    );
}

export default AccessibilityButton;