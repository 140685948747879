import React from 'react';
import SidebarTab from './SidebarTab/SidebarTab';
import './SidebarTabs.css';

function SidebarTabs({tabs, currentTab, height, hoverLabelColor, hoverBorderColor, onClick}) {
    const renderTabs = () => {
        return tabs.map((tab) => {
            return (
                <SidebarTab
                    key={`product-details-sidebar-tab-${tab.id}`}
                    id={tab.id}
                    label={tab.label}
                    selected={currentTab === tab.id}
                    hoverLabelColor={hoverLabelColor}
                    hoverBorderColor={hoverBorderColor}
                    onClick={onClick}
                />
            );
        });
    };

    return (
        <div className='product-details-sidebar-tabs' style={{height: height}}>
            {renderTabs()}
        </div>
    );
}

export default SidebarTabs;