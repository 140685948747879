import React from 'react';
import './CrossLoader.css';

function CrossLoader({size}) {
    const defaultSize = 48; // Pixels.

    const loaderStyle = () => {
        return {
            width: size ? size : defaultSize,
            height: size ? size : defaultSize
        }
    };

    return (
        <span className='cross-loader' style={loaderStyle()}>
        </span>
    );
}

export default CrossLoader;