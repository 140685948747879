import React, {useState, useEffect} from 'react';
import Currency from "../../../config/Currency";
import Measure from "../../../config/Measure";
import Activity from "../../../helpers/Activity";
import CurrencyFormat from "react-currency-format";
import './FilterOption.css';

function FilterOption({category, searchQuery, parentFilter, filterOption, width, marginRight, labelColor, backgroundColor, onDelete, metadata}) {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getLabel = () => {
        const type = parentFilter.type;
        switch (type) {
            case 'size':
                return getSizeLabel();
            case 'price':
                return getPriceLabel();
            default:
                return getNormalLabel();
        }
    };

    const getMetadata = () => {
        const type = parentFilter.type;
        let metadata = {category_id: category.id, category_name: category.name, search_query: searchQuery || null, filter_id: parentFilter.id, option_id: filterOption.id, filter_label: parentFilter.label, option_label: filterOption.label};
        switch (type) {
            case 'size':
                metadata.value = filterOption.value;
                metadata.unit = filterOption.unit;
                return metadata;
            case 'price':
                metadata.start_value = filterOption.startValue;
                metadata.end_value = filterOption.endValue;
                metadata.currency = filterOption.currency;
                return metadata;
            default:
                return metadata;
        }
    };

    const getNormalLabel = () => {
        return (
            <React.Fragment>
                <span>{parentFilter.label || 'Filtro'}</span>
                <br/>
                <span>({filterOption.label || '?'})</span>
            </React.Fragment>
        )
    };

    const getPriceLabel = () => {
        const currencyFormat = Currency.CLP;
        return (
            <React.Fragment>
                <span>
                    Desde: <CurrencyFormat
                        prefix={currencyFormat.prefix}
                        suffix=''
                        thousandSeparator={currencyFormat.thousandsSeparator}
                        decimalSeparator={currencyFormat.decimalSeparator}
                        decimalScale={currencyFormat.decimalScale}
                        value={filterOption.startValue || 0}
                        displayType='text'
                    /> {filterOption.currency || ''}
                </span>
                <br/>
                <span>
                    Hasta: <CurrencyFormat
                        prefix={currencyFormat.prefix}
                        suffix=''
                        thousandSeparator={currencyFormat.thousandsSeparator}
                        decimalSeparator={currencyFormat.decimalSeparator}
                        decimalScale={currencyFormat.decimalScale}
                        value={filterOption.endValue || 0}
                        displayType='text'
                    /> {filterOption.currency || ''}
                </span>
            </React.Fragment>
        );
    };

    const getSizeLabel = () => {
        const measureFormat = Measure.default;
        return (
            <React.Fragment>
                <span>{filterOption.label || 'Filtro'}</span>
                <br/>
                <span>
                    (Hasta <CurrencyFormat
                        prefix={measureFormat.prefix}
                        suffix={` ${filterOption.unit}`}
                        thousandSeparator={measureFormat.thousandsSeparator}
                        decimalSeparator={measureFormat.decimalSeparator}
                        decimalScale={measureFormat.decimalScale}
                        value={filterOption.value || 0}
                        displayType='text'
                    />)
                </span>
            </React.Fragment>
        );
    };

    const handleDelete = () => {
        if (onDelete) {
            Activity.log(metadata, 'filter-option', filterOption.id, 'remove', getMetadata());
            onDelete(parentFilter.id, filterOption.id);
        }
    };

    const filterOptionStyle = () => {
        return {
            width: width ? width : 200,
            minWidth: width ? width : 200,
            marginRight: marginRight ? marginRight : 15,
            transform: `scale(${isLoading ? 0 : 1})`,
            background: backgroundColor || '#005B80'
        };
    };

    const labelStyle = () => {
        return {
            fontSize: parentFilter.type === 'price' ? 12 : 16,
            color: labelColor || '#FFFFFF'
        };
    };

    const deleteButtonStyle = () => {
        return {
            background: 'transparent',
            color: labelColor || '#FFFFFF',
            borderColor: labelColor || '#FFFFFF'
        };
    };

    return (
        <div className='filter-option' style={filterOptionStyle()}>
            <p className='filter-option-label' style={labelStyle()}>{getLabel()}</p>
            <div className='filter-option-delete-button' onClick={handleDelete} style={deleteButtonStyle()}>
                <span className='filter-option-delete-button-icon'>x</span>
            </div>
        </div>
    );
}

export default FilterOption;