export default class Search {
    static normalizeText(text) {
        const specialCharacters = ["á", "é", "í", "ó", "ú", "ñ"];
        const normalCharacters = ["a", "e", "i", "o", "u", "n"];
        const letters = text.toString().toLowerCase().split("");
        for (let i = 0; i < letters.length; i++) {
            for (let j = 0; j < specialCharacters.length; j++) {
                if (letters[i] === specialCharacters[j]) {
                    letters[i] = normalCharacters[j];
                }
            }
        }
        return letters.join("");
    };


    static searchList(list, keys, keyword) {
        let matches = [];
        if (keyword !== undefined && keyword !== null && keyword.length > 0) {
            keyword = this.normalizeText(keyword);
            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                for (let j = 0; j < keys.length; j++) {
                    const key = keys[j];
                    let value = item[key];
                    if (value !== undefined && value !== null && value.length > 0) {
                        value = value.toString();
                        value = this.normalizeText(value);
                        if (value.includes(keyword)) {
                            matches.push(item);
                            break;
                        }
                    }
                }
            }
        } else {
            matches = list;
        }
        return matches;
    };
}