import React from "react";
import PaginatorPage from "./PaginatorPage/PaginatorPage";
import blackLeftArrow from "./images/black-left-arrow.png";
import blackRightArrow from "./images/black-right-arrow.png";
import whiteLeftArrow from "./images/white-left-arrow.png";
import whiteRightArrow from "./images/white-right-arrow.png";
import "./Paginator.css";

function Paginator({currentPage, totalPages, activeItemBackgroundColor, activeItemLabelColor, action}) {
    const renderPages = () => {
        const previousPage = currentPage - 1;
        const nextPage = currentPage + 1;
        let pages = [];
        if (totalPages > 1) {
            pages.push(<PaginatorPage key="previous" type='previous' name={undefined} icon={blackLeftArrow} hoverIcon={whiteLeftArrow} value={previousPage} active={false} enabled={currentPage !== 1} action={action} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
            pages.push(<PaginatorPage key="page-1" name="1" value={1} active={currentPage === 1} enabled={true} action={action} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
            if (currentPage === 1 && totalPages > 2) {
                pages.push(<PaginatorPage key="page-2" name="2" value={2} active={false} enabled={true} action={action} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
                if (totalPages > 4) pages.push(<PaginatorPage key="page-space-left" name="..." active={false} enabled={false} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
                else if (totalPages > 3) pages.push(<PaginatorPage key="page-3" name="3" value={3} active={false} enabled={true} action={action} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
            }
            for (let i = 2; i < totalPages; i++) {
                const isCurrentPage = currentPage === i;
                if (isCurrentPage) {
                    if (i > 2) {
                        if (i > 4) pages.push(<PaginatorPage key="page-space-left" name="..." active={false} enabled={false} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
                        if (i === 4) pages.push(<PaginatorPage key={"page-" + (i - 2)} name={i - 2} value={i - 2} active={false} enabled={true} action={action} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
                        pages.push(<PaginatorPage key={"page-" + (i - 1)} name={i - 1} value={i - 1} active={false} enabled={true} action={action} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
                    }
                    pages.push(<PaginatorPage key={"page-" + i} name={i} value={i} active={true} enabled={true} action={action} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
                    if (i < totalPages - 1) {
                        pages.push(<PaginatorPage key={"page-" + (i + 1)} name={(i + 1)} value={i + 1} active={false} enabled={true} action={action} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
                        if (i < totalPages - 3) pages.push(<PaginatorPage key="page-space-right" name="..." active={false} enabled={false} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
                        else if (i === totalPages - 3) pages.push(<PaginatorPage key={"page-" + (i + 2)} name={(i + 2)} value={i + 2} active={false} enabled={true} action={action} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
                    }
                }
            }
            if (currentPage === totalPages) {
                if (totalPages > 4) {
                    pages.push(<PaginatorPage key="page-space-right" name="..." active={false} enabled={false} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
                    pages.push(<PaginatorPage key={"page-" + (totalPages - 1)} name={(totalPages - 1)} value={totalPages - 1} active={false} enabled={true} action={action} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
                } else if (totalPages > 3) {
                    pages.push(<PaginatorPage key={"page-" + (totalPages - 2)} name={(totalPages - 2)} value={totalPages - 2} active={false} enabled={true} action={action} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
                    pages.push(<PaginatorPage key={"page-" + (totalPages - 1)} name={(totalPages - 1)} value={totalPages - 1} active={false} enabled={true} action={action} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
                } else if (totalPages > 2) {
                    pages.push(<PaginatorPage key={"page-" + (totalPages - 1)} name={(totalPages - 1)} value={totalPages - 1} active={false} enabled={true} action={action} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
                }
            }
            pages.push(<PaginatorPage key={"page-" + totalPages} name={totalPages} value={totalPages} active={currentPage === totalPages} enabled={true} action={action} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
            pages.push(<PaginatorPage key="next" type='next' name={undefined} icon={blackRightArrow} hoverIcon={whiteRightArrow} value={nextPage} active={false} enabled={currentPage !== totalPages} action={action} activeBackgroundColor={activeItemBackgroundColor} activeLabelColor={activeItemLabelColor}/>);
        }
        return pages;
    };

    return (
        <div className="paginator">
            {renderPages()}
        </div>
    );
}

export default Paginator;