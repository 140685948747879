import React, {useEffect, useRef, useState} from 'react';
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import ImageWrapper from "../ImageWrapper/ImageWrapper";
import Color from "../../helpers/Color";
import './Button.css';

function Button({icon, label, labelColor, backgroundColor, borderColor, borderWidth, borderRadius, padding, margin, width, minWidth, maxWidth, height, minHeight, maxHeight, fontSize, boxShadow, isLoading, loadLabel, disabled, showPulseEffect, notificationNumber, notificationBackgroundColor, notificationLabelColor, onClick}) {
    const ref = useRef(null);
    const [pulseEffect, setPulseEffect] = useState(null);

    useEffect(() => {
        if (showPulseEffect) startPulseEffect();
        else stopPulseEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backgroundColor, showPulseEffect]);

    const handleClick = (event) => {
        event.stopPropagation();
        if (onClick && !disabled && !isLoading) onClick();
    };

    const buttonStyle = () => {
        return {
            width: width || 'auto',
            minWidth: minWidth || 'unset',
            maxWidth: maxWidth || 'unset',
            height: height || 'auto',
            minHeight: minHeight || 'unset',
            maxHeight: maxHeight || 'unset',
            borderRadius: borderRadius || 50,
            margin: margin || 0,
            fontSize: fontSize || 20,
        };
    };

    const buttonBoxShadowStyle = () => {
        let style = {
            borderWidth: borderWidth || 2,
            borderRadius: borderRadius || 50,
            boxShadow: boxShadow || '0 0 10px rgba(0, 0, 0, 0.5)',
            padding: padding || '15px 30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center'
        };
        if (disabled || isLoading) {
            style.color = '#ACACAC';
            style.borderColor = '#F7F7F7';
            style.background = '#F7F7F7';
        } else {
            style.color = labelColor || '#6F909D';
            style.borderColor = borderColor || '#B3E9FF';
            style.background = backgroundColor || '#B3E9FF';
        }
        return style;
    };

    const notificationStyle = () => {
        return {
            background: notificationBackgroundColor || '#FFB819',
            color: notificationLabelColor || '#111111'
        };
    };

    const startPulseEffect = () => {
        if (ref) {
            stopPulseEffect();
            const rgb = Color.hexToRgb(backgroundColor) || Color.hexToRgb(borderColor) || {r: 0, g: 91, b: 128};
            // noinspection JSUnresolvedReference
            const pulseEffect = ref.current.animate([
                {boxShadow: `0 0 0 0 rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9)`},
                {boxShadow: `0 0 0 10px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0)`},
                {boxShadow: `0 0 0 0 rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0)`}
            ], {
                duration: 1500,
                iterations: Infinity
            });
            setPulseEffect(pulseEffect);
        }
    };

    const stopPulseEffect = () => {
        if (pulseEffect) {
            pulseEffect.cancel();
            setPulseEffect(undefined);
        }
    };

    return (
        <div ref={ref} className='button' onClick={handleClick} style={buttonStyle()}>
            <div className='button-box-shadow' style={buttonBoxShadowStyle()}>
                {!!notificationNumber && notificationNumber > 0 && (
                    <div className='button-notification' style={notificationStyle()}>{notificationNumber}</div>
                )}
                {isLoading && (
                    <div className='button-loader'>
                        <SpinnerLoader size={40} color='#ACACAC'/>
                    </div>
                )}
                {!isLoading && icon && (
                    <div className='button-icon-container'>
                        <ImageWrapper image={icon} display='block' alt='Filters' width={30} height={30} borderRadius={0} loadDelay={0}/>
                    </div>
                )}
                {isLoading && loadLabel ? loadLabel : label}
            </div>
        </div>
    );
}

export default Button;