import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import usePrevious from '../../helpers/UsePrevious';
import ActionBarMobileItem from './ActionBarMobileItem/ActionBarMobileItem';
import Modal from "../Modal/Modal";
import Navigation from '../../config/Navigation';
import Activity from '../../helpers/Activity';
import {ACTION_BAR_ITEMS} from "../../config/App";
import homeBlackIcon from './images/home-black.svg';
import homeWhiteIcon from './images/home-white.svg';
import homeGrayIcon from './images/home-gray.svg';
import searchBlackIcon from './images/search-black.svg';
import searchWhiteIcon from './images/search-white.svg';
import searchGrayIcon from './images/search-gray.svg';
import helpBlackIcon from './images/support-black.png';
import helpWhiteIcon from './images/support-white.png';
import helpGrayIcon from './images/support-gray.png';
import surveyBlackIcon from './images/rate-black.png';
import surveyWhiteIcon from './images/rate-white.png';
import surveyGrayIcon from './images/rate-gray.png';
import cartBlackIcon from './images/cart-black.svg';
import cartWhiteIcon from './images/cart-white.svg';
import cartGrayIcon from './images/cart-gray.svg';
import backBlackIcon from './images/back-black.svg';
import backWhiteIcon from './images/back-white.svg';
import backGrayIcon from './images/back-gray.svg';
import './ActionBarMobile.css';

function ActionBarMobile({
    id,
    mode,
    shoppingCart,
    activeSearch,
    activeHelp,
    hasHelpUrl,
    activeSurvey,
    hasSurveyUrl,
    activeCart,
    searchAction,
    helpAction,
    surveyAction,
    cartAction,
    closeAction,
    style,
    metadata
}) {
    const navigate = useNavigate();
    const previousActiveSearch = usePrevious(activeSearch);
    const previousActiveHelp = usePrevious(activeHelp);
    const previousActiveSurvey = usePrevious(activeSurvey);
    const previousActiveCart = usePrevious(activeCart);

    useEffect(() => {
        const searchChanged  = previousActiveSearch  !== activeSearch;
        const helpChanged    = previousActiveHelp    !== activeHelp;
        const surveyChanged  = previousActiveSurvey  !== activeSurvey;
        const cartChanged    = previousActiveCart    !== activeCart;
        if (searchChanged || helpChanged || surveyChanged || cartChanged) {
            let newActions = {...actions};
            if (searchChanged) actions.search.active = activeSearch;
            if (helpChanged && actions.help) actions.help.active = activeHelp;
            if (surveyChanged && actions.survey) actions.survey.active = activeSurvey;
            if (cartChanged) actions.cart.active = activeCart;
            setActions(newActions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSearch, activeHelp, activeSurvey, activeCart]);

    const handleHomeAction = () => {
        Activity.log(metadata, 'action-bar-mobile', 'home-button', 'redirect', undefined);
        const url = Navigation.getHomeUrl();
        navigate(url);
    };

    const handleBackAction = () => {
        Activity.log(metadata, 'action-bar-mobile', 'back-button', 'redirect', undefined);
        navigate(-1);
    };

    const handleSearchAction = () => {
        if (searchAction) {
            Activity.log(metadata, 'action-bar-mobile', 'search-button', 'open', undefined);
            searchAction();
        }
        silentClose();
    };

    const handleHelpAction = () => {
        if (helpAction) {
            Activity.log(metadata, 'action-bar-mobile', 'help-button', 'open', undefined);
            helpAction();
        }
        silentClose();
    };

    const handleSurveyAction = () => {
        if (surveyAction) {
            Activity.log(metadata, 'action-bar-mobile', 'survey-button', 'open', undefined);
            surveyAction();
        }
        silentClose();
    };

    const handleCartAction = () => {
        if (cartAction) {
            Activity.log(metadata, 'action-bar-mobile', 'cart-button', 'open', undefined);
            cartAction();
        }
        silentClose();
    };

    const silentClose = () => {
        if (closeAction) closeAction('silent');
    };

    const handleClose = (closeType) => {
        if (closeAction) closeAction(closeType);
    };

    const moveStyle = style && style.move ? style.move : {};

    const buildActions = () => {
        let actionList = {};
        actionList.home   = {active: false, label: 'Inicio', icon: homeBlackIcon, activeIcon: homeWhiteIcon, disabledIcon: homeGrayIcon, _function: handleHomeAction};
        actionList.search = {active: activeSearch, label: 'Buscar', icon: searchBlackIcon, activeIcon: searchWhiteIcon, disabledIcon: searchGrayIcon, _function: handleSearchAction};
        if (hasHelpUrl) actionList.help = {active: activeHelp, label: 'Ayuda', icon: helpBlackIcon, activeIcon: helpWhiteIcon, disabledIcon: helpGrayIcon, _function: handleHelpAction};
        if (hasSurveyUrl) actionList.survey = {active: activeSurvey, label: 'Encuesta', icon: surveyBlackIcon, activeIcon: surveyWhiteIcon, disabledIcon: surveyGrayIcon, _function: handleSurveyAction};
        actionList.cart   = {active: activeCart, label: 'Carro', icon: cartBlackIcon, activeIcon: cartWhiteIcon, disabledIcon: cartGrayIcon, _function: handleCartAction};
        actionList.back   = {active: false, label: 'Volver', icon: backBlackIcon, activeIcon: backWhiteIcon, disabledIcon: backGrayIcon, _function: handleBackAction};
        return actionList;
    };

    const [actions, setActions] = useState(buildActions());

    useEffect(() => {
        setActions(buildActions());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasHelpUrl, hasSurveyUrl]);

    const getActionsByMode = () => {
        switch (mode) {
            case 'catalog':
                return ACTION_BAR_ITEMS['catalog'];
            case 'products-list':
                return ACTION_BAR_ITEMS['products-list'];
            case 'product':
                return ACTION_BAR_ITEMS['product'];
            default:
                return [];
        }
    };

    const getCartItemsCount = () => {
        return shoppingCart && shoppingCart.items ? shoppingCart.items.reduce((accumulator, item) => (accumulator + item.quantity), 0) : 0;
    };

    const renderModeActions = () => {
        const enabledActions = getActionsByMode();
        return Object.keys(actions).map((key, index) => {
            const action = actions[key];
            const actionStyle = style && style[key] ? style[key] : {};
            let enabled = !!enabledActions.find(name => name === key);
            let showNotification = false;
            let notificationNumber = undefined;
            if (key === 'cart') {
                showNotification = true;
                notificationNumber = getCartItemsCount();
            }
            return (
                <ActionBarMobileItem
                    key={`${id}-item-${index}`}
                    active={action.active}
                    enabled={enabled}
                    label={action.label}
                    icon={actionStyle['icon'] || action.icon}
                    activeIcon={actionStyle['activeIcon'] || action.activeIcon}
                    disabledIcon={actionStyle['disabledIcon'] || action.disabledIcon}
                    labelColor={actionStyle['labelColor']}
                    backgroundColor={actionStyle['backgroundColor']}
                    activeLabelColor={actionStyle['activeLabelColor']}
                    activeBackgroundColor={actionStyle['activeBackgroundColor']}
                    disabledLabelColor={actionStyle['disabledLabelColor']}
                    disabledBackgroundColor={actionStyle['disabledBackgroundColor']}
                    indicatorBackgroundColor={actionStyle['indicatorBackgroundColor']}
                    indicatorLabelColor={actionStyle['indicatorLabelColor']}
                    showNotification={showNotification}
                    notificationNumber={notificationNumber}
                    onClick={action._function}
                />
            );
        });
    };

    return (
        <Modal
            mode='left'
            smallMode={false}
            backgroundColor={moveStyle['backgroundColor'] || '#FFFFFF'}
            borderRadius={0}
            padding='0'
            width={280}
            minWidth={280}
            maxWidth={280}
            height='100%'
            minHeight='100%'
            maxHeight='100%'
            showDefaultCloseButton={true}
            customDefaultCloseButtonPosition={{top: 10, left: 10}}
            onClose={handleClose}>
            <div className='action-bar-mobile-items'>
                {renderModeActions()}
            </div>
        </Modal>
    );
}

export default ActionBarMobile;