import React from 'react';
import CategoryTitle from './CategoryTitle/CategoryTitle';
import Subcategory from './Subcategory/Subcategory';
import './Category.css';

function Category({category, titleBackgroundColor, titleLabelColor, titleBorderColor, pulseColor, pulseDuration, onPulseFinish, metadata}) {
    const renderSubcategories = () => {
        if (category.children && category.children.length > 0) {
            const sorted = category.children.sort((a, b) => a.order - b.order);
            return (
                <div className='category-children'>
                    {sorted.map((child) => <Subcategory key={`subcategory-${child.id}`} parent={category} subcategory={child} pulseColor={pulseColor} pulseDuration={pulseDuration} onPulseFinish={onPulseFinish} metadata={metadata}/>)}
                </div>
            );
        } else return undefined;
    };

    return (
        <div id={category.id} className='category'>
            <CategoryTitle title={category.name} fallbackTitle='Sin nombre' icon={category.icon} backgroundColor={titleBackgroundColor} labelColor={titleLabelColor} borderColor={titleBorderColor}/>
            {renderSubcategories()}
        </div>
    );
}

export default Category;