import React from 'react';
import {useNavigate} from "react-router-dom";
import Modal from '../Modal/Modal';
import DataError from '../DataError/DataError';
import Viewport from '../../helpers/Viewport';
import Activity from '../../helpers/Activity';
import Navigation from "../../config/Navigation";
import packageErrorImage from './images/package-error.png';
import './NoStockModal.css';

function NoStockModal({
    product,
    variant,
    similar,
    actionButtonBackgroundColor,
    actionButtonBorderColor,
    actionButtonLabelColor,
    cancelButtonBackgroundColor,
    cancelButtonBorderColor,
    cancelButtonLabelColor,
    onClose,
    openSimilarProducts,
    metadata
}) {
    const viewport = Viewport.dimensions;
    const smallModeTreshold = {width: 768, height: 600};
    const navigate = useNavigate();

    const inSmallMode = () => {
        return viewport.width <= smallModeTreshold.width || viewport.height <= smallModeTreshold.height;
    };

    const handleClose = (closeType) => {
        Activity.log(metadata, 'no-stock-modal', closeType, 'close', {product_id: product.id, product_name: product.name, variant_id: variant ? variant.id : null, variant_name: variant ? variant.name : null});
        if (onClose) onClose();
    };

    const silentClose = () => {
        if (onClose) onClose();
    };

    const hasSimilarProducts = () => {
        return similar && similar.length > 0;
    };

    const viewSimilarProducts = () => {
        Activity.log(metadata, 'no-stock-modal', 'similar-products', 'click', {product_id: product.id, product_name: product.name, variant_id: variant ? variant.id : null, variant_name: variant ? variant.name : null});
        if (openSimilarProducts) openSimilarProducts();
        silentClose();
    };

    const viewCategoryProducts = () => {
        Activity.log(metadata, 'no-stock-modal', 'category-products', 'click', {product_id: product.id, product_name: product.name, variant_id: variant ? variant.id : null, variant_name: variant ? variant.name : null});
        const categoryId = product.getMainCategory();
        const url = categoryId ? Navigation.getCategoryUrl(categoryId, undefined, undefined) : Navigation.getHomeUrl();
        navigate(url);
    };

    return (
        <Modal mode='center' smallMode={inSmallMode()} backgroundColor='#FFFFFF' borderRadius={{topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50}} padding={0} width={800} maxWidth='100%' height='auto' maxHeight='100%' showDefaultCloseButton={false} onClose={handleClose}>
            <div className='no-stock-modal'>
                <p className='no-stock-modal-title'>¡Lo sentimos!</p>
                <p className='no-stock-modal-description'>Este producto <span className='bold'>no tiene stock</span> y no puede ser añadido al carro</p>
                <div className='no-stock-modal-content'>
                    <DataError
                        message={undefined}
                        customImage={packageErrorImage}
                        actionLabel={'Ver productos similares'}
                        action={hasSimilarProducts() ? viewSimilarProducts : viewCategoryProducts}
                        actionButtonLabelColor={actionButtonLabelColor}
                        actionButtonBackgroundColor={actionButtonBackgroundColor}
                        actionButtonBorderColor={actionButtonBorderColor}
                        actionButtonMinWidth={undefined}
                        secondaryActionLabel='Cerrar'
                        secondaryAction={() => handleClose('custom-close-button')}
                        secondaryActionButtonLabelColor={cancelButtonLabelColor}
                        secondaryActionButtonBackgroundColor={cancelButtonBackgroundColor}
                        secondaryActionButtonBorderColor={cancelButtonBorderColor}
                        secondaryActionButtonMinWidth={250}
                        maxWidth='unset'
                        padding='20px 20px 0 20px'
                    />
                </div>
            </div>
        </Modal>
);
}

export default NoStockModal;