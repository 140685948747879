import React, {useRef, useEffect, useState} from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import ImageWrapper from "../ImageWrapper/ImageWrapper";
import Debug from '../../helpers/Debug';
import Viewport from '../../helpers/Viewport';
import Activity from "../../helpers/Activity";
import defaultQrScanImage from './images/scan.gif';
import QRCode from 'qrcode';
import './ARModal.css';

function ARModal({product, arUrl, qrScanImage, cancelButtonBackgroundColor, cancelButtonBorderColor, cancelButtonLabelColor, onClose, metadata}) {
    const canvasRef = useRef(null);
    const [error, setError] = useState(false);
    const viewport = Viewport.dimensions;
    const smallModeTreshold = {width: 768, height: 600};

    useEffect(() => {
        if (arUrl) renderQrCode();
        else setError(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const inSmallMode = () => {
        return viewport.width <= smallModeTreshold.width || viewport.height <= smallModeTreshold.height;
    };

    const handleClose = (closeType) => {
        if (onClose) {
            Activity.log(metadata, 'ar-modal', closeType, 'close', {product_id: product.id, product_name: product.name});
            onClose();
        }
    };

    const renderQrCode = () => {
        const canvas = canvasRef.current;
        QRCode.toCanvas(canvas, arUrl, {width: 200, height: 200}, (error) => {
            if (error) {
                Debug.printToLog('error', error);
                setError(true);
            }
        });
    };

    return (
        <Modal mode='center' smallMode={inSmallMode()} backgroundColor='#FFFFFF' borderRadius={{topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50}} padding={40} width={800} maxWidth='100%' height='auto' maxHeight='100%' showDefaultCloseButton={false} onClose={handleClose}>
            <div className='ar-modal'>
                {!error && (
                    <React.Fragment>
                        <p className='ar-modal-title'>Ver en <span className='bold'>Realidad Aumentada</span></p>
                        <p className='ar-modal-description'>Escanea el QR de este producto para <span className='bold'>visualizarlo en Realidad Aumentada con la cámara de tu celular</span></p>
                        <div className='ar-modal-content'>
                            <div className='ar-modal-image'>
                                <ImageWrapper image={qrScanImage || defaultQrScanImage} alt='Scan' display='block' width={200} height={200} borderRadius='100%' loadDelay={0.2}/>
                            </div>
                            <div className='ar-modal-qr-image-container'>
                                <canvas ref={canvasRef} className='ar-modal-qr-image-canvas'></canvas>
                                <p className='ar-modal-qr-image-description'>Escanea el código</p>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {error && (
                    <div className='ar-modal-error'>Lo sentimos, se ha producido un error al generar el código QR</div>
                )}
                <div className='ar-modal-actions'>
                    <div className='ar-modal-action'>
                        <Button label='Cerrar' labelColor={cancelButtonLabelColor} backgroundColor={cancelButtonBackgroundColor} borderColor={cancelButtonBorderColor} minWidth='auto' maxWidth='100%' boxShadow='unset' onClick={() => handleClose('custom-close-button')}/>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ARModal;