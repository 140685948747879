// noinspection JSUnusedGlobalSymbols,JSUnresolvedReference

export class Store {
    constructor({id, name, address, geolocation, backupStores}) {
        this.id = id;
        this.name = name;
        this.address = address;
        this.geolocation = geolocation;
        this.backupStores = backupStores;
    };

    clone() {
        return new Store({
            id: this.id,
            name: this.name,
            address: this.address,
            geolocation: this.geolocation,
            backupStores: this.backupStores
        });
    };
}

export const storeConverter = {
    toFirestore() {
        return null;
    },
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return new Store({
            id: snapshot.id,
            name: data.name,
            address: data.address,
            geolocation: data.geolocation,
            backupStores: data.backup_stores
        });
    }
};