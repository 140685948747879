import {useEffect, useRef} from 'react';

function usePrevious(value) {
    const ref = useRef(null);
    useEffect(() => {
        ref.current = value;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    return ref.current;
}

export default usePrevious;