import React from 'react';
import ImageWrapper from '../../../../ImageWrapper/ImageWrapper';
import './PaymentOption.css';

function PaymentOption({type, icon, label1, label2, boldLabel1, boldLabel2, labelLineBreak, waitingMode, waitingModeIcon, waitingModeLabel, onClick}) {
    const allowAction = () => {
        return !waitingMode && !!onClick;
    };

    const handleClick = () => {
        if (allowAction()) onClick(type);
    };

    const labelContainerStyle = () => {
        return {
            margin: label2 ? '20px 0 0 0' : '40px 0 0 0'
        };
    };

    const labelStyle = (bold) => {
        return {
            fontWeight: bold ? 900 : 400
        };
    };

    return (
        <div className={`payment-option${allowAction() ? ' hover' : ''}`} onClick={handleClick}>
            {icon && (
                <div className='payment-option-icon'>
                    <ImageWrapper image={icon} alt={type} display='block' width='100%' height='auto' loadDelay={0.2}/>
                </div>
            )}
            <p className='payment-option-label' style={labelContainerStyle()}>
                {label1 && <span className='payment-option-label-item' style={labelStyle(boldLabel1)}>{label1}</span>}
                {labelLineBreak && <br/>}
                {label2 && <span className='payment-option-label-item' style={labelStyle(boldLabel2)}>{label2}</span>}
            </p>
            {waitingMode && (
                <div className='payment-option-timer'>
                    <img className='payment-option-timer-icon' src={waitingModeIcon} alt='Waiting'/>
                    <div className='payment-option-timer-label'>{waitingModeLabel}</div>
                </div>
            )}
        </div>
    );
}

export default PaymentOption;