import React, {useState, useEffect} from 'react';
import './CustomInput.css';

function CustomInput({
    id,
    label,
    insetLabel,
    value,
    placeholder,
    showClearButton,
    formatFunction,
    focusBorderColor,
    clearButtonBackgroundColor,
    clearButtonLabelColor,
    isAddressInput,
    disabled,
    onFocus,
    onChange,
    onAnyKeyPress,
    onEnterKeyPress
}) {
    const [isFocused, setIsFocused] = useState(false);
    const [autocomplete, setAutocomplete] = useState(undefined);

    useEffect(() => {
        if (isAddressInput) initializeAsAddressInput();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initializeAsAddressInput = () => {
        const element = document.getElementById(id);
        if (element) {
            const options = {
                componentRestrictions: {country: ["cl"]}, // ALLOW ONLY CHILEAN ADDRESSES.
                types: ["address"], // ALLOW ONLY ADDRESSES (DO NOT RECOMEND COUNTRIES OR LANDMARKS).
                fields: ["formatted_address", "address_components"], // RETURN FORMATTED ADDRESS.
            };
            // noinspection JSUnresolvedVariable, JSUnresolvedFunction
            const newAutoComplete = new window.google.maps.places.Autocomplete(element, options);
            setAutocomplete(newAutoComplete);
        }
    };

    useEffect(() => {
        if (autocomplete) autocomplete.addListener("place_changed", handlePlaceChange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autocomplete]);

    const handleKeyDown = (e) => {
        if (!disabled) {
            if (onAnyKeyPress) onAnyKeyPress();
            if (e.key === 'Enter' && onEnterKeyPress) onEnterKeyPress();
        }
    };

    const handleChange = (e) => {
        if (!disabled) {
            const newValue = e.target.value;
            applyChange({value: newValue, components: undefined});
        }
    };

    const handlePlaceChange = () => {
        // noinspection JSUnresolvedFunction
        const place = autocomplete.getPlace();
        // noinspection JSUnresolvedVariable
        const address = place.formatted_address;
        // noinspection JSUnresolvedVariable
        const components = place.address_components;
        if (onChange && !disabled) onChange({value: address, components: components});
    };

    const handleFocus = () => {
        if (!disabled) {
            setIsFocused(true);
            if (onFocus) onFocus();
        }
    };

    const handleBlur = () => {
        if (!disabled) {
            setIsFocused(false);
            if (formatFunction) {
                const newValue = formatFunction(value);
                applyChange({value: newValue, components: undefined});
            }
        }
    };

    const handleClear = () => {
        if (!disabled) applyChange({value: '', components: undefined});
    };

    const applyChange = (data) => {
        if (!disabled && onChange) onChange(data);
    };

    const wrapperStyle = () => {
        return {
            border: `solid 2px ${isFocused ? (focusBorderColor || '#13D4F3') : '#CCCCCC'}`,
            background: disabled ? '#EEEEEE' : '#FFFFFF'
        };
    };

    const inputStyle = () => {
        return {
            paddingLeft: insetLabel ? 0 : 20,
            paddingRight: showClearButton ? 0 : 20
        };
    };

    const clearButtonStyle = () => {
        return {
            background: clearButtonBackgroundColor || '#DE1C24',
            color: clearButtonLabelColor || '#FFFFFF'
        };
    };

    return (
        <div className='custom-input'>
            {label && !insetLabel && <label className='custom-input-label' htmlFor={id}>{label}</label>}
            <div className='custom-input-wrapper' style={wrapperStyle()}>
                {label && insetLabel && (
                    <div className='custom-input-inset-label-container'>
                        <div className='custom-input-inset-label'>{label}</div>
                    </div>
                )}
                <input id={id} className='custom-input-value' type='text' placeholder={placeholder} value={value || ''} onFocus={handleFocus} onBlur={handleBlur} onKeyDown={handleKeyDown} onChange={handleChange} disabled={disabled} style={inputStyle()}/>
                {showClearButton && (
                    <div className='custom-input-clear-button-container'>
                        <div className='custom-input-clear-button' onClick={handleClear} style={clearButtonStyle()}>
                            <div className='custom-input-clear-button-icon'>x</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CustomInput;