export const MAX_INACTIVITY_TIME = 300; // Seconds.

export const INACTIVITY_MODAL_TIME = 240; // Seconds.

export const LOG_INACTIVITY_TIME = false;

export const CONFIG_PRESS_TIME = 5; // Seconds.

export const SHOW_APP_VERSION = true;

export const PLP_ITEMS_PER_PAGE = 100;

export const CLIENT_CHECKOUT_URL = 'https://getcheckout-x3herayjcq-uc.a.run.app';

export const UPDATE_PRODUCT_URL = 'https://extractitemsbyskulist-x3herayjcq-uc.a.run.app';

export const GET_CLIENT_ENTITY_URL = 'https://getcliententity-x3herayjcq-uc.a.run.app';

export const GET_SII_ENTITY_URL = 'https://getsiientity-x3herayjcq-uc.a.run.app';

export const GET_STATES_URL = 'https://getstates-x3herayjcq-uc.a.run.app';

export const GET_DISTRICTS_URL = 'https://getdistricts-x3herayjcq-uc.a.run.app';

export const GET_CITIES_URL = 'https://getcities-x3herayjcq-uc.a.run.app';

export const GET_ACTIVITIES_URL = 'https://getactivities-x3herayjcq-uc.a.run.app';

export const GET_HQ_DATA_URL = 'https://gethqdata-x3herayjcq-uc.a.run.app';

export const GET_SEGMENTS_URL = 'https://getsegments-x3herayjcq-uc.a.run.app';

export const SAVE_CUSTOMER_URL = 'https://savecustomer-x3herayjcq-uc.a.run.app';

export const TRANSBANK_POS_POLL_URL = 'https://localhost:5001/poll';

export const TRANSBANK_POS_SALE_URL = 'https://localhost:5001/sale';

export const TRANSBANK_POS_LAST_SALE_URL = 'https://localhost:5001/lastSale';

export const SENTRY_ERROR_TESTING_MODE = false;

export const ALGOLIA_APP_ID = '3AE8XYBBIG';

export const ALGOLIA_API_KEY = 'cee79ed8283627db4beef8e96d31b24c';

export const ACTION_BAR_ITEMS = {
    'catalog': ['home', 'search', 'help', 'survey', 'cart'],
    'products-list': ['home', 'search', 'help', 'survey', 'cart', 'back'],
    'product': ['home', 'search', 'cart', 'help', 'survey', 'back']
};

export const PRICE_FILTER_STEP_THRESHOLD = 100000;

export const PRICE_FILTER_LOWER_STEP = 1000;

export const PRICE_FILTER_HIGER_STEP = 10000;

export const OTHER_FILTERS_IGNORED_KEYS = [
    'descripción', 'Descripción', 'descripcion', 'Descripcion',
    'modelo', 'Modelo',
    'marca', 'Marca',
    'dimensiones', 'Dimensiones',
    'precio', 'Precio',
    'precios', 'Precios',
    'alto', 'Alto',
    'ancho', 'Ancho',
    'largo', 'Largo',
    'profundidad', 'Profundidad'
];

export const SENTRY_ENABLED_ENVIRONMENTS = ['production', 'staging'];