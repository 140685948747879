import algoliaInsights from 'search-insights';
import {ALGOLIA_API_KEY, ALGOLIA_APP_ID} from './App';
import Currency from "./Currency";

export default class AlgoliaInsights {
    constructor(environment, client) {
        this.environment = environment;
        this.client = client;
        this.index = `${this.environment}_${this.client}_items`;
        this.initialized = false;
    }

    init() {
        if (!this.initialized) {
            algoliaInsights('init', {appId: ALGOLIA_APP_ID, apiKey: ALGOLIA_API_KEY});
            this.initialized = true;
        }
    };

    clickedObjectIDs(sessionId, productsIds) {
        algoliaInsights('clickedObjectIDs', {
            userToken: sessionId,
            authenticatedUserToken: sessionId,
            index: this.index,
            eventName: 'Product Clicked',
            objectIDs: productsIds
        });
    };

    clickedObjectIDsAfterSearch(sessionId, queryId, productsIds, positions) {
        algoliaInsights('clickedObjectIDsAfterSearch', {
            userToken: sessionId,
            authenticatedUserToken: sessionId,
            eventName: 'Product Clicked After Search',
            index: this.index,
            queryID: queryId,
            objectIDs: productsIds,
            positions: positions
        });
    };

    addedToCartObjectIDs(sessionId, productsIds, productsData) {
        algoliaInsights('addedToCartObjectIDs', {
            userToken: sessionId,
            authenticatedUserToken: sessionId,
            eventName: 'Product Added To Cart',
            index: this.index,
            objectIDs: productsIds,
            objectData: productsData,
            value: productsData.reduce((total, product) => total + product.price, 0),
            currency: Currency.CLP.symbol,
        });
    };

    addedToCartObjectIDsAfterSearch(sessionId, queryId, productsIds, productsData) {
        algoliaInsights('addedToCartObjectIDsAfterSearch', {
            userToken: sessionId,
            authenticatedUserToken: sessionId,
            eventName: 'Product Added To Cart After Search',
            index: this.index,
            queryID: queryId,
            objectIDs: productsIds,
            objectData: productsData,
            value: productsData.reduce((total, product) => total + product.price, 0),
            currency: Currency.CLP.symbol
        });
    };

    convertedObjectIDs(sessionId, productsIds) {
        algoliaInsights('convertedObjectIDs', {
            userToken: sessionId,
            authenticatedUserToken: sessionId,
            eventName: 'Products Converted',
            index: this.index,
            objectIDs: productsIds
        });
    };

    convertedObjectIDsAfterSearch(sessionId, queryId, productsIds) {
        algoliaInsights('convertedObjectIDsAfterSearch', {
            userToken: sessionId,
            authenticatedUserToken: sessionId,
            eventName: 'Products Converted After Search',
            index: this.index,
            queryID: queryId,
            objectIDs: productsIds
        });
    };

    purchasedObjectIDs(sessionId, shoppingCart) {
        const objectIDs = shoppingCart.listProductsIds();
        const objectData = shoppingCart.items.map((item) => item.toAlgoliaInsights());
        let method;
        let eventName;
        if (shoppingCart.hasQueryIds()) {
            method = 'purchasedObjectIDsAfterSearch';
            eventName = 'Purchased Products After Search';
        } else {
            method = 'purchasedObjectIDs';
            eventName = 'Purchased Products';
        }
        algoliaInsights(method, {
            userToken: sessionId,
            authenticatedUserToken: sessionId,
            eventName: eventName,
            index: this.index,
            objectIDs: objectIDs,
            objectData: objectData,
            value: shoppingCart.getTotal(),
            currency: Currency.CLP.symbol
        });
    };
}