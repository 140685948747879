import React, {useEffect, useRef, useState} from 'react';
import './PromotionalVideo.css';

function PromotionalVideo({video, show}) {
    const containerRef = useRef(null);
    const videoRef = useRef(null);
    const [showAnimation, setShowAnimation] = useState(false);

    useEffect(() => {
        const element = videoRef.current;
        if (show) {
            element.currentTime = 0;
            const playPromise = element.play();
            if (playPromise !== undefined) {
                playPromise.then(() => {})
                .catch(() => {});
            }
        } else element.pause();
        if (show) {
            addAnimation();
            setShowAnimation(true);
        } else setShowAnimation(false);
    }, [show]);

    useEffect(() => {
        if (!showAnimation) setTimeout(removeAnimation, 1000);
    }, [showAnimation]);

    const addAnimation = () => {
        if (containerRef && containerRef.current) containerRef.current.style.transition = 'top 1s ease';
    };

    const removeAnimation = () => {
        if (containerRef && containerRef.current) containerRef.current.style.transition = 'unset';
    };

    const containerStyle = () => {
        return {
            top: video && showAnimation ? 0 : '-100vh'
        };
    };

    return (
        <div ref={containerRef} className='promotional-video' style={containerStyle()}>
            <video ref={videoRef} width='100%' height='100%' autoPlay={true} controls={false} muted={true} loop={true}>
                <source src={video} type='video/mp4'/>
            </video>
        </div>
    );
}

export default PromotionalVideo;