import React, {useState} from 'react';
import ListItem from './ListItem/ListItem';
import threeDImage from './images/3d.gif';
import './ImageList.css';

function ImageList({product, initialSelectedId, has3dModel, showScrollbar, visible, disabled, onClick}) {
    const [selectedId, setSelectedId] = useState(initialSelectedId);

    const handleClick = (key) => {
        if (onClick) {
            setSelectedId(key);
            onClick(key);
        }
    };

    const renderItems = () => {
        let elements = [];
        if (has3dModel) {
            elements.push(
                <ListItem
                    key={`image-list-item-${product.id}-3D`}
                    product={product}
                    id='3D'
                    image={threeDImage}
                    selected={selectedId === '3D'}
                    padding={40}
                    visible={visible}
                    disabled={disabled}
                    onClick={handleClick}
                />
            );
        }
        let images = [];
        if (product.multimedia && product.multimedia.gallery && product.multimedia.gallery.length > 0) {
            images = product.multimedia.gallery.map((image, index) => (
                <ListItem
                    key={`image-list-item-${product.id}-${index}`}
                    id={`${index}`}
                    image={image.m}
                    selected={selectedId === index.toString()}
                    padding={10}
                    visible={visible}
                    disabled={disabled}
                    onClick={handleClick}
                />
            ));
        }
        elements = elements.concat(images);
        return elements.length > 1 ? elements : [];
    };

    return (
        <div className={`image-list ${showScrollbar ? 'scrollbar' : 'no-scrollbar'}`}>
            {renderItems()}
        </div>
    );
}

export default ImageList;