export default class Pin {
    static format(pin) {
        if (!Pin.isDirty(pin)) {
            let formattedPin = "";
            pin = Pin.clean(pin);
            pin = pin.split("").reverse();
            const verificator = pin.shift();
            const chunks = Math.ceil(pin.length / 3);
            let slices = [];
            for (let i = 0; i < chunks; i++) {
                const slice = pin.slice(0, 3).reverse();
                pin = pin.splice(3);
                slices.push(slice);
            }
            slices = slices.reverse();
            for (let i = 0; i < slices.length; i++) {
                formattedPin += slices[i].join("");
                if (i < slices.length - 1) formattedPin += ".";
            }
            formattedPin += "-" + verificator;
            return formattedPin;
        } else return "";
    };

    static valid(pin) {
        const body = Pin.clean(pin).split("");
        const verificator = body.pop();
        const calculated = Pin.calculateVerificator(pin);
        return body.length >= 7 && calculated === verificator;
    };

    static calculateVerificator(pin) {
        let body = Pin.clean(pin).split("");
        body.pop();
        let sum = 0;
        let multiple = 2;
        for(let i = 1; i <= body.length; i++) {
            let item = parseInt(body[body.length - i]);
            let index = multiple * item;
            sum = sum + index;
            if (multiple < 7) {
                multiple = multiple + 1;
            } else {
                multiple = 2;
            }
        }
        let verificator = 11 - (sum % 11);
        if (verificator === 11) verificator = 0;
        if (verificator === 10) verificator = "k";
        return verificator.toString();
    };

    static clean(pin) {
        pin = pin.toLowerCase();
        return String(pin).replace(/[^0-9k]/gi, "");
    };

    static isDirty(pin) {
        return !(/^([0-9]{0,3}[.]?)*[0-9]{1,3}[-|‐]?[0-9kK]$/.test(pin));
    };
}