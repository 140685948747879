export default class HTMLElement {
    static getDisplayProperties(element) {
        try {
            if (!element) return undefined;
            const rect = element.getBoundingClientRect();
            return {
                left: rect.left + window.scrollX,
                top: rect.top + window.scrollY,
                width: element.offsetWidth,
                height: element.offsetHeight
            };
        } catch (error) {
            return undefined;
        }
    };

    static isElementInViewport(element) {
        if (!element) return false;
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
}