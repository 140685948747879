import React, {useEffect, useRef} from 'react';
import Currency from '../../../../config/Currency';
import CurrencyFormat from 'react-currency-format';
import DateTime from '../../../../helpers/DateTime';
import './Budget.css';

function Budget({
    documentRef,
    title,
    id,
    idPrefix,
    image,
    issuerName,
    issuerAddressLabel,
    issuerAddress,
    issuerActivityLabel,
    issuerActivity,
    issuerBranchLabel,
    issuerBranch,
    receiverName,
    receiverPinLabel,
    receiverPin,
    receiverActivityLabel,
    receiverActivity,
    receiverAddressLabel,
    receiverAddress,
    receiverDistrictLabel,
    receiverDistrict,
    receiverCityLabel,
    receiverCity,
    receiverStateLabel,
    receiverState,
    additionalInstructions,
    expirationMessage,
    shoppingCart,
    showTax,
    unitLabel,
    onRenderComplete
}) {
    const imageRef = useRef(null);
    const currencyFormat = Currency.CLP;

    useEffect(() => {
        const element = imageRef ? imageRef.current : undefined;
        if (element) {
            element.onload = () => {
                if (onRenderComplete) onRenderComplete();
            };
            element.onerror = () => {
                element.remove();
                if (onRenderComplete) onRenderComplete();
            };
            element.src = image;
        } else {
            if (onRenderComplete) onRenderComplete();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCurrentDate = () => {
        return DateTime.formatDate(new Date(), true, "-", ["day", "month", "year"]);
    };

    const renderHeaders = () => {
        return (
            <tr key='budget-headers'>
                <th style={{textAlign: 'left'}}>
                    Artículo
                    <br/>
                    Precio unitario
                </th>
                <th style={{textAlign: 'center'}}>Cantidad</th>
                <th style={{textAlign: 'right'}}>Valor</th>
            </tr>
        );
    };

    const renderItems = () => {
        const items = shoppingCart.items.map((item, index) => {
            const minPrice = item.minPrice();
            const value = minPrice * item.quantity;
            const isFirstRow = index === 0;
            const isLastRow = index === shoppingCart.items.length - 1;
            return (
                <tr key={`budget-item-${item.id}-${index}`}>
                    <td className={`budget-item-cell${isFirstRow ? ' top-padding' : ''}${isLastRow ? ' bottom-padding' : ''}`} style={{textAlign: 'left'}}>
                        {item.name}
                        <br/>
                        <CurrencyFormat
                            prefix={`(${currencyFormat.prefix}`}
                            suffix={`${unitLabel})`}
                            thousandSeparator={currencyFormat.thousandsSeparator}
                            decimalSeparator={currencyFormat.decimalSeparator}
                            decimalScale={currencyFormat.decimalScale}
                            value={minPrice}
                            displayType='text'
                        />
                    </td>
                    <td className={`budget-item-cell${isFirstRow ? ' top-padding' : ''}${isLastRow ? 'bottom-padding' : ''}`} style={{textAlign: 'center'}}>{item.quantity}</td>
                    <td className={`budget-item-cell${isFirstRow ? ' top-padding' : ''}${isLastRow ? 'bottom-padding' : ''}`} style={{textAlign: 'right'}}>
                        <CurrencyFormat
                            prefix={currencyFormat.prefix}
                            suffix={currencyFormat.suffix}
                            thousandSeparator={currencyFormat.thousandsSeparator}
                            decimalSeparator={currencyFormat.decimalSeparator}
                            decimalScale={currencyFormat.decimalScale}
                            value={value}
                            displayType='text'
                        />
                    </td>
                </tr>
            );
        });
        return (
            <React.Fragment>
                {items}
                {renderTotals()}
            </React.Fragment>
        )
    };

    const renderTotals = () => {
        const totals = [];
        const totalBeforeTaxes = shoppingCart.getExclusiveTotal();
        const taxes = totalBeforeTaxes * currencyFormat.taxPercentage;
        const total = totalBeforeTaxes + taxes;
        totals.push((
            <tr key='budget-total-row-1'>
                <td className='budget-total-cell border' style={{textAlign: 'left'}}>{showTax ? 'TOTAL NETO' : 'TOTAL'}</td>
                <td className='budget-total-cell border' style={{textAlign: 'center'}}>{currencyFormat.prefix}</td>
                <td className='budget-total-cell border' style={{textAlign: 'right'}}>
                    <CurrencyFormat
                        prefix={undefined}
                        suffix={currencyFormat.suffix}
                        thousandSeparator={currencyFormat.thousandsSeparator}
                        decimalSeparator={currencyFormat.decimalSeparator}
                        decimalScale={currencyFormat.decimalScale}
                        value={totalBeforeTaxes}
                        displayType='text'
                    />
                </td>
            </tr>
        ));
        if (showTax) {
            totals.push((
                <tr key='budget-total-row-2'>
                    <td className='budget-total-cell' style={{textAlign: 'left'}}>{currencyFormat.taxLabel}</td>
                    <td className='budget-total-cell' style={{textAlign: 'center'}}>{currencyFormat.prefix}</td>
                    <td className='budget-total-cell' style={{textAlign: 'right'}}>
                        <CurrencyFormat
                            prefix={undefined}
                            suffix={currencyFormat.suffix}
                            thousandSeparator={currencyFormat.thousandsSeparator}
                            decimalSeparator={currencyFormat.decimalSeparator}
                            decimalScale={currencyFormat.decimalScale}
                            value={taxes}
                            displayType='text'
                        />
                    </td>
                </tr>
            ));
            totals.push((
                <tr key='budget-total-row-3'>
                    <td className='budget-total-cell bold' style={{textAlign: 'left'}}>TOTAL</td>
                    <td className='budget-total-cell bold' style={{textAlign: 'center'}}>{currencyFormat.prefix}</td>
                    <td className='budget-total-cell bold' style={{textAlign: 'right'}}>
                        <CurrencyFormat
                            prefix={undefined}
                            suffix={currencyFormat.suffix}
                            thousandSeparator={currencyFormat.thousandsSeparator}
                            decimalSeparator={currencyFormat.decimalSeparator}
                            decimalScale={currencyFormat.decimalScale}
                            value={total}
                            displayType='text'
                        />
                    </td>
                </tr>
            ));
        }
        return totals;
    };

    return (
        <div ref={documentRef} className='budget'>
            <h1 className='budget-title'>{title}</h1>
            <div className='budget-id'>{idPrefix} {id}</div>
            {image && (
                <div className='budget-logo-container'>
                    <img ref={imageRef} className='budget-logo' alt='Logo'/>
                </div>
            )}
            <h2 className='budget-subtitle'>{issuerName || 'Desconocido'}</h2>
            <div className='budget-info-item'>
                <div className='budget-info-item-label'>{issuerAddressLabel}: </div>
                <div className='budget-info-item-value'>{issuerAddress || 'No especificada'}</div>
            </div>
            <div className='budget-info-item'>
                <div className='budget-info-item-label'>{issuerActivityLabel}: </div>
                <div className='budget-info-item-value'>{issuerActivity || 'No especificado'}</div>
            </div>
            <div className='budget-info-item'>
                <div className='budget-info-item-label'>{issuerBranchLabel}: </div>
                <div className='budget-info-item-value'>{issuerBranch || 'No especificada'}</div>
            </div>
            <h2 className='budget-subtitle'>{receiverName || 'Desconocido'}</h2>
            <div className='budget-info-item'>
                <div className='budget-info-item-label'>{receiverPinLabel}: </div>
                <div className='budget-info-item-value'>{receiverPin || 'No especificado'}</div>
            </div>
            {receiverActivity && (
                <div className='budget-info-item'>
                    <div className='budget-info-item-label'>{receiverActivityLabel}: </div>
                    <div className='budget-info-item-value'>{receiverActivity}</div>
                </div>
            )}
            {receiverAddress && (
                <div className='budget-info-item'>
                    <div className='budget-info-item-label'>{receiverAddressLabel}: </div>
                    <div className='budget-info-item-value'>{receiverAddress}</div>
                </div>
            )}
            {receiverDistrict && (
                <div className='budget-info-item'>
                    <div className='budget-info-item-label'>{receiverDistrictLabel}: </div>
                    <div className='budget-info-item-value'>{receiverDistrict}</div>
                </div>
            )}
            {receiverCity && (
                <div className='budget-info-item'>
                    <div className='budget-info-item-label'>{receiverCityLabel}: </div>
                    <div className='budget-info-item-value'>{receiverCity}</div>
                </div>
            )}
            {receiverState && (
                <div className='budget-info-item'>
                    <div className='budget-info-item-label'>{receiverStateLabel}: </div>
                    <div className='budget-info-item-value'>{receiverState}</div>
                </div>
            )}
            <p className='budget-date'>Fecha de emisión: {getCurrentDate()}</p>
            {additionalInstructions && (
                <div className='budget-additional-instructions'>
                    {additionalInstructions}
                </div>
            )}
            <div className='budget-items'>
                <table className='budget-items-table'>
                    <thead>{renderHeaders()}</thead>
                    <tbody>{renderItems()}</tbody>
                </table>
            </div>
            {expirationMessage && (
                <div className='budget-expiration-message'>
                    {expirationMessage}
                </div>
            )}
        </div>
    );
}

export default Budget;