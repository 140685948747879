import Debug from './Debug';
import Environment from '../config/Environment';
import Storage from './Storage';
import {
    CLIENT_CHECKOUT_URL,
    UPDATE_PRODUCT_URL,
    GET_CLIENT_ENTITY_URL,
    GET_SII_ENTITY_URL,
    GET_STATES_URL,
    GET_DISTRICTS_URL,
    GET_CITIES_URL,
    GET_ACTIVITIES_URL,
    GET_HQ_DATA_URL,
    GET_SEGMENTS_URL,
    SAVE_CUSTOMER_URL
} from '../config/App';

export default class WebServices {
    static getClientShoppingCartUrl = (clientId, storeId, shoppingCart, externalSale) => {
        const environment = Environment.current;
        const url = `${CLIENT_CHECKOUT_URL}?environment=${environment}&client=${clientId}`;
        let body = shoppingCart.toCheckout();
        body.store_id = storeId;
        body.external_sale_flow = externalSale;
        return fetch(url, {method: 'POST', mode: 'cors', cache: 'no-cache', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)})
            .then(response => this.onResponse(response, true))
            .then(response => {
                if (response) {

                    // TODO: Uncomment.
                    const id = response['id'] || undefined;
                    const url = response['checkout_url'] || undefined;
                    const type = response['details'] && response['details'] && response['details']['type'] ? response['details']['type'] : undefined;
                    const message = response['details'] && response['details'] && response['details']['message'] ? response['details']['message'] : undefined;
                    const status = response['details'] && response['details'] && response['details']['status'] ? response['details']['status'] : undefined;
                    const error = status ? {type: type, message: message, status: status} : undefined;
                    return {id: id, url: url, error: error};

                    // TODO: Remove.
                    // return {
                    //     id: undefined,
                    //     url: undefined,
                    //     error: {
                    //         type: 'NO_STOCK',
                    //         message: 'Productos sin stock',
                    //         status: 520
                    //     }
                    // };

                } else return undefined;
            })
            .catch(() => {
                return undefined;
            });
    };

    static asyncProductUpdate = (clientId, productId) => {
        const environment = Environment.current;
        const url = `${UPDATE_PRODUCT_URL}?environment=${environment}&client=${clientId}&persist=true&skus[]=${productId}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return !!response;
            })
            .catch((error) => {
                Debug.printToLog('error', `Failed to update product ${productId} for client ${clientId}: ${error}`);
                return false;
            })
            .catch(() => {
                return false;
            });
    };

    static getClientEntity = (pin) => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_CLIENT_ENTITY_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}&pin=${encodeURIComponent(pin)}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response;
            })
            .catch(() => {
                return undefined;
            });
    };

    static getSiiEntity = (pin) => {
        const url = `${GET_SII_ENTITY_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&pin=${encodeURIComponent(pin)}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response;
            })
            .catch(() => {
                return undefined;
            });
    };

    static getActivities = () => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_ACTIVITIES_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response ? response : [];
            })
            .catch(() => {
                return [];
            });
    };

    static getStates = () => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_STATES_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response ? response : [];
            })
            .catch(() => {
                return [];
            });
    };

    static getDistricts = () => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_DISTRICTS_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response ? response : [];
            })
            .catch(() => {
                return [];
            });
    };

    static getCities = () => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_CITIES_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response ? response : [];
            })
            .catch(() => {
                return [];
            });
    };

    static getHQData = () => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_HQ_DATA_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response;
            })
            .catch(() => {
                return undefined;
            });
    };

    static getSegments = () => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_SEGMENTS_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response ? response : [];
            })
            .catch(() => {
                return [];
            });
    };

    static saveCustomer = ({segment, pin, name, phone, email, activity, state, city, district, street}) => {
        // TODO: Uncomment.
        const environment = Environment.current;
        const client = Storage.getClient();
        const body = {segment, pin, name, phone, email, activity, state, city, district, street};
        const url = `${SAVE_CUSTOMER_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'POST', mode: 'cors', cache: 'no-cache', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)})
            .then(response => this.onResponse(response))
            .then(response => {
                return response;
            })
            .catch(() => {
                return {status: false};
            });

        // TODO: Remove.
        // return Promise.resolve({status: true});
    };

    static onResponse = (response, parseErrors = false) => {
        if (response.ok || parseErrors) {
            try {
                return response.json();
            } catch (error) {
                Debug.printToLog('error', `Unable to parse JSON response for endpoint ${response.url}`);
                return undefined;
            }
        } else {
            // Debug.printToLog('error', `Server returned status code ${response.status} for endpoint: ${response.url}`); // Redundant because Chrome already prints this information.
            return undefined;
        }
    };
}