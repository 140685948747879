// noinspection JSUnusedGlobalSymbols,JSUnresolvedReference

export class Banner {
    constructor({id, image, linkType, linkTarget, linkOptions, order, catalogs, customType, extracted, extractedAt}) {
        this.id = id;
        this.image = image;
        this.linkType = linkType;
        this.linkTarget = linkTarget;
        this.linkOptions = linkOptions;
        this.order = order;
        this.catalogs = catalogs;
        this.customType = customType;
        this.extracted = extracted;
        this.extractedAt = extractedAt;
    };

    clone() {
        return new Banner({
            id: this.id,
            image: this.image,
            linkType: this.linkType,
            linkTarget: this.linkTarget,
            linkOptions: this.linkOptions,
            order: this.order,
            catalogs: this.catalogs,
            customType: this.customType,
            extracted: this.extracted,
            extractedAt: this.extractedAt
        });
    };
}

export const bannerConverter = {
    toFirestore() {
        return null;
    },
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return new Banner({
            id: snapshot.id,
            image: data.image,
            linkType: data.link_type,
            linkTarget: data.link_target,
            linkOptions: data.link_options,
            order: data.order,
            catalogs: data.catalogs,
            customType: data.custom_type,
            extracted: data.extracted,
            extractedAt: data.extracted_at
        });
    }
};