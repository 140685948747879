import React, {useState, useEffect, useRef} from 'react';
import MainLogo from '../MainLogo/MainLogo';
import Text from '../../helpers/Text';
import './Header.css';

function Header({clientLogo, category, productsQuantity, clientLogoBackgroundColor, categoryBackgroundColor, categoryLabelColor}) {
    const categoryImageRef = useRef(null);
    const [isLoadingCategoryImage, setIsLoadingCategoryImage] = useState(true);
    const [categoryImageLoaded, setCategoryImageLoaded] = useState(false);

    useEffect(() => {
        const element = categoryImageRef.current;
        element.onload = () => {
            setTimeout(() => {
                setIsLoadingCategoryImage(false);
                setCategoryImageLoaded(true);
            }, 1000);
        };
        element.onerror = () => {
            setTimeout(() => {
                setIsLoadingCategoryImage(false);
                setCategoryImageLoaded(false);
            }, 1000);
        };
        element.src = getImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const element = categoryImageRef.current;
        const newImage = getImage();
        const oldImage = element.src;
        if (newImage !== oldImage) {
            setIsLoadingCategoryImage(true);
            setTimeout(() => {element.src = newImage}, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category.image]);

    const getName = () => {
        return category && category.name ? Text.toUpperCase(category.name) : '';
    };

    const getImage = () => {
        return category && category.image ? category.image : undefined;
    };

    const clientLogoContainerStyle = () => {
        return {
            background: clientLogoBackgroundColor || '#005B80'
        };
    };

    const categoryInfoWrapperStyle = () => {
        return {
            background: categoryBackgroundColor || '#B3E9FF'
        };
    };

    const categoryNameStyle = () => {
        return {
            color: categoryLabelColor || '#005B80'
        };
    };

    const imageStyle = () => {
        return {
            transform: `scale(${isLoadingCategoryImage || (!isLoadingCategoryImage && !categoryImageLoaded) ? 0 : 1}) translateY(-50%)`
        };
    };

    return (
        <div id={category.id} className='header'>
            <div className='header-client-logo' style={clientLogoContainerStyle()}>
                <MainLogo
                    backgroundColor='transparent'
                    backgroundTopPadding={0}
                    backgroundRightPadding={50}
                    backgroundBottomPadding={0}
                    backgroundLeftPadding={50}
                    backgroundWidth='auto'
                    image={clientLogo}
                    imageAlign='right'
                    imageHeight={50}
                />
            </div>
            <div className='header-category'>
                <div className='header-category-info-wrapper' style={categoryInfoWrapperStyle()}>
                    <div className='header-category-info-container'>
                        <div className='header-category-name' style={categoryNameStyle()}>{getName()}</div>
                        {productsQuantity && productsQuantity > 0 ? (
                            <div className='header-products-quantity'>{productsQuantity} producto(s)</div>
                        ) : undefined}
                    </div>
                </div>
            </div>
            <img ref={categoryImageRef} className='header-category-image' alt={getName()} style={imageStyle()}/>
        </div>
    );
}

export default Header;