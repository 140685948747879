export default class DateTime {
    // noinspection JSUnusedGlobalSymbols
    static formatTimestamp(dateObject, withSeparators, dateSeparator, dateOrder) {
        const separator = withSeparators ? " " : "";
        const date = this.formatDate(dateObject, withSeparators, dateSeparator, dateOrder);
        const time = this.formatTime(dateObject, withSeparators);
        return date + separator + time;
    };

    static formatDate(date, withSeparators, dateSeparator, dateOrder) {
        const defaultOrderHash = {year: undefined, month: undefined, day: undefined};
        let orderHash = {};
        if (dateOrder) dateOrder.forEach((item) => {orderHash[item] = undefined});
        else orderHash = defaultOrderHash;
        const separator = withSeparators ? (dateSeparator ? dateSeparator : "-") : "";
        const year = "" + date.getFullYear();
        let month = "" + (date.getMonth() + 1);
        if (month.length === 1) month = "0" + month;
        let day = "" + date.getDate();
        if (day.length === 1) day = "0" + day;
        orderHash.day = day;
        orderHash.month = month;
        orderHash.year = year;
        let response = "";
        const keys = Object.keys(orderHash);
        keys.forEach((key, index) => {response += orderHash[key] + (index < keys.length - 1 ? separator : "")});
        return response;
    };

    static formatTime(date, withSeparators) {
        const separator = withSeparators ? ":" : "";
        const millisecondSeparator = withSeparators ? "." : "";
        let hour = "" + date.getHours();
        if (hour.length === 1) hour = "0" + hour;
        let minute = "" + date.getMinutes();
        if (minute.length === 1) minute = "0" + minute;
        let second = "" + date.getSeconds();
        if (second.length === 1) second = "0" + second;
        let millisecond = "" + date.getMilliseconds();
        if (millisecond.length === 1) millisecond = "00" + millisecond;
        if (millisecond.length === 2) millisecond = "0" + millisecond;
        return hour + separator + minute + separator + second + millisecondSeparator + millisecond;
    };

    static secondsToTimer(seconds) {
        let date = new Date(0);
        date.setSeconds(seconds);
        return date.toISOString().substring(11, 19);
    };
}