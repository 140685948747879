import React, {useState, useEffect, useRef} from 'react';
import CrossLoader from '../../CrossLoader/CrossLoader';
import noImage from './images/no-image.png';
import './ListItem.css';

function ListItem({id, image, selected, padding, visible, disabled, onClick}) {
    const imageRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingImage, setIsLoadingImage] = useState(true);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const image = imageRef.current;
        image.onload = () => {
            setTimeout(() => {
                setIsLoadingImage(false);
                setLoaded(true);
            }, 100);
        };
        image.onerror = () => {
            setTimeout(() => {
                setIsLoadingImage(false);
                setLoaded(false);
            }, 100);
        };
        setTimeout(() => {
            setIsLoading(false);
            image.src = getImage();
        }, 200);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const element = imageRef.current;
        const newImage = getImage();
        const oldImage = element.src;
        if (newImage !== oldImage) {
            setIsLoadingImage(true);
            setTimeout(() => {element.src = newImage}, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image]);

    const handleClick = () => {
        if (!disabled && onClick) onClick(id);
    };

    const getImage = () => {
        return !image || (!isLoadingImage && !loaded) ? noImage : image;
    };

    const containerStyle = () => {
        return {
            padding: padding || 10,
            border: selected ? '5px solid #CCCCCC' : '5px solid transparent',
            transform: `scale(${isLoading || !visible ? 0 : (selected ? 1.1 : 1)})`
        };
    };

    const imageStyle = () => {
        return {
            opacity: isLoadingImage ? 0 : 1
        };
    };

    const loaderStyle = () => {
        return {
            opacity: isLoadingImage ? 1 : 0
        };
    };

    return (
        <div className='list-item' onClick={handleClick} style={containerStyle()}>
            <div className='list-item-wrapper'>
                <img ref={imageRef} className='list-item-image' alt='Product' style={imageStyle()}/>
            </div>
            <div className='list-item-loader' style={loaderStyle()}>
                <CrossLoader/>
            </div>
        </div>
    );
}

export default ListItem;