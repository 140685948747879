import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import LinkButton from '../LinkButton/LinkButton';
import ShoppingCartItem from './ShoppingCartItem/ShoppingCartItem';
import DataLoader from '../DataLoader/DataLoader';
import DataError from '../DataError/DataError';
import Currency from '../../config/Currency';
import Debug from '../../helpers/Debug';
import Activity from '../../helpers/Activity';
import CurrencyFormat from 'react-currency-format';
import {Product as _Product, productConverter} from '../../models/Product';
import {firestore, getCollectionPath} from '../../config/Firebase';
import {doc, onSnapshot} from 'firebase/firestore';
import cartIcon from './images/cart.png';
import trashIcon from './images/trash.svg';
import errorIcon from './images/error.png';
import './ShoppingCart.css';

export default class ShoppingCart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shoppingCart: this.props.initialShoppingCart,
            productsData: []
        };
        this._isMounted = true;
        this._currencyFormat = Currency.CLP;
        this._unsubscribeProducts = [];
    };

    changeState = (newState, callback) => {
        if (this._isMounted) {
            this.setState(newState, () => {
                if (callback) callback();
            });
        }
    };

    componentDidMount() {
        this._isMounted = true;
        this.getProducts();
    };

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    };

    getProducts = () => {
        const productIds = this.state.shoppingCart.items.map(item => {
            if (item.type === 'variant') return {parent: item.parent, variant: item.product};
            else return {parent: item.product, variant: undefined};
        });
        productIds.forEach((ids) => {
            const _path = getCollectionPath(this.props.client, 'items');
            const _doc = doc(firestore, _path, ids.parent).withConverter(productConverter);
            const unsubscribe = onSnapshot(_doc, (_document) => {
                if (_document.exists()) {
                    let source = _document.data();
                    if (ids.variant) source = source.variants.find(variant => variant.id === ids.variant);
                    let {shoppingCart} = this.state;
                    const cartMatchIndex = shoppingCart.items.findIndex(item => item.product === source.id);
                    if (cartMatchIndex >= 0) {
                        shoppingCart.items[cartMatchIndex].maxQuantity = source.maxPerItem;
                        if (shoppingCart.items[cartMatchIndex].quantity === 0 && source.maxPerItem > 0) shoppingCart.items[cartMatchIndex].quantity = 1;
                        if (shoppingCart.items[cartMatchIndex].quantity > source.maxPerItem) shoppingCart.items[cartMatchIndex].quantity = source.maxPerItem;
                        shoppingCart.items[cartMatchIndex].normalPrice = source.normalPrice;
                        shoppingCart.items[cartMatchIndex].offerPrice = source.offerPrice;
                        shoppingCart.items[cartMatchIndex].exclusivePrice = source.exclusivePrice;
                    }
                    this.addProduct(source, true);
                    this.changeState({shoppingCart: shoppingCart}, () => {
                        this.handleChange();
                    });
                } else {
                    const product = new _Product({id: ids.variant || ids.parent});
                    this.addProduct(product, false);
                }
            }, (error) => {
                Debug.printToLog('error', error);
                const product = new _Product({id: ids.variant || ids.parent});
                this.addProduct(product, false);
            });
            this._unsubscribeProducts.push(unsubscribe);
        });
    };

    addProduct = (product, valid) => {
        let {productsData} = this.state;
        const productIndex = productsData.findIndex(pd => pd.product.id === product.id);
        if (productIndex >= 0) productsData[productIndex] = {valid, product};
        else productsData.push({valid, product});
        this.changeState({productsData: productsData});
    };

    unsubscribe = () => {
        this._unsubscribeProducts.forEach((unsub) => unsub());
    };

    loadingData = () => {
        return this.state.shoppingCart.items.length !== this.state.productsData.length;
    };

    dataLoaded = () => {
        return this.state.shoppingCart.items.length === this.state.productsData.length;
    };

    hasItems = () => {
        return this.state.shoppingCart && this.state.shoppingCart.items.length > 0;
    };

    handleChange = () => {
        if (this.props.onChange) this.props.onChange(this.state.shoppingCart);
    };

    silentClose = () => {
        if (this.props.onClose) this.props.onClose();
    }

    handleClose = (closeType) => {
        if (this.props.onClose) {
            const {metadata} = this.props;
            Activity.log(metadata, 'shopping-cart', closeType, 'close', undefined);
            this.props.onClose();
        }
    };

    handleSearch = () => {
        if (this.props.openSearch) {
            const {metadata} = this.props;
            Activity.log(metadata, 'shopping-cart', 'empty-search-button', 'search', undefined);
            this.props.openSearch();
            this.silentClose();
        }
    };

    startPaymentAttempt = () => {
        if (this.props.onPaymentAttempt) {
            const {metadata} = this.props;
            Activity.log(metadata, 'shopping-cart', 'checkout-button', 'checkout', this.state.shoppingCart.toActivityLog());
            this.silentClose();
            this.props.onPaymentAttempt();
        }
    };

    printBudget = () => {
        if (this.props.onBudgetPrint) {
            const {metadata} = this.props;
            Activity.log(metadata, 'shopping-cart', 'budget-button', 'budget-print', this.state.shoppingCart.toActivityLog());
            this.silentClose();
            this.props.onBudgetPrint();
        }
    };

    increaseItemQuantity = (id) => {
        const newShoppingCart = this.state.shoppingCart.clone();
        const item = newShoppingCart.items.find(item => item.id === id);
        const itemIndex = newShoppingCart.items.findIndex(item => item.id === id);
        if (itemIndex !== -1 && item && item.quantity < item.maxQuantity) {
            const {metadata} = this.props;
            Activity.log(metadata, 'shopping-cart-item', 'increase-quantity-button', 'increase-quantity', newShoppingCart.items[itemIndex].toActivityLog(1));
            newShoppingCart.items[itemIndex].increaseQuantity();
            this.changeState({shoppingCart: newShoppingCart}, this.handleChange);
        }
    };

    decreaseItemQuantity = (id) => {
        const newShoppingCart = this.state.shoppingCart.clone();
        const item = newShoppingCart.items.find(item => item.id === id);
        const itemIndex = newShoppingCart.items.findIndex(item => item.id === id);
        if (itemIndex !== -1 && item && item.quantity > item.minQuantity) {
            const {metadata} = this.props;
            Activity.log(metadata, 'shopping-cart-item', 'decrease-quantity-button', 'decrease-quantity', newShoppingCart.items[itemIndex].toActivityLog(1));
            newShoppingCart.items[itemIndex].decreaseQuantity();
            this.changeState({shoppingCart: newShoppingCart}, this.handleChange);
        }
    };

    deleteItem = (id) => {
        const {metadata} = this.props;
        const {shoppingCart, productsData} = this.state;
        const item = shoppingCart.items.find(item => item.id === id);
        const productId = item.product;
        const productIndex = productsData.findIndex(data => data.product.id === productId);
        Activity.log(metadata, 'shopping-cart-item', 'delete-item-button', 'delete-item', item.toActivityLog());
        if (productIndex >= 0) productsData.splice(productIndex, 1);
        const newShoppingCart = shoppingCart.clone();
        newShoppingCart.deleteItem(id);
        this.changeState({shoppingCart: newShoppingCart}, this.handleChange);
    };

    clearShoppingCart = () => {
        const {metadata} = this.props;
        Activity.log(metadata, 'shopping-cart', 'clear-button', 'clear', undefined);
        const newShoppingCart = this.state.shoppingCart.clone();
        newShoppingCart.clear();
        this.changeState({shoppingCart: newShoppingCart, productsData: []}, this.handleChange);
    };

    actionsStyle = (hasInvalidItems) => {
        return {
            marginTop: hasInvalidItems ? 0 : 40
        };
    };

    renderItems = () => {
        return this.state.shoppingCart.items.map((item) => {
            const productData = this.state.productsData.find(data => data.product.id === item.product);
            return (
                <ShoppingCartItem
                    key={`shopping-cart-item-${item.id}`}
                    item={item}
                    product={productData.product}
                    valid={productData.valid}
                    discountBackgroundColor={this.props.discountBackgroundColor}
                    discountLabelColor={this.props.discountLabelColor}
                    exclusivePriceLabelColor={this.props.exclusivePriceLabelColor}
                    exclusivePriceIcon={this.props.exclusivePriceIcon}
                    actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                    actionButtonBorderColor={this.props.actionButtonBorderColor}
                    actionButtonLabelColor={this.props.actionButtonLabelColor}
                    onQuantityDecrease={this.decreaseItemQuantity}
                    onQuantityIncrease={this.increaseItemQuantity}
                    onDelete={this.deleteItem}
                    metadata={this.props.metadata}
                />
            );
        });
    };

    renderTotals = () => {
        const total = this.state.shoppingCart.getTotal();
        const exclusiveTotal = this.state.shoppingCart.getExclusiveTotal();
        const hasInvalidItems = !total || !exclusiveTotal;
        return (
            <React.Fragment>
                <div className='shopping-cart-items'>{this.renderItems()}</div>
                <div className='shopping-cart-totals'>
                    <div className='shopping-cart-action'>
                        <LinkButton icon={trashIcon} label='Vaciar carro' labelColor='#7A797A' onClick={this.clearShoppingCart}/>
                    </div>
                    {!hasInvalidItems && (
                        <React.Fragment>
                            <div className='shopping-cart-total'>
                                <div className='shopping-cart-total-label' style={{color: '#414042'}}>Subtotal:</div>
                                <div className='shopping-cart-total-value' style={{color: '#414042'}}>
                                    <CurrencyFormat
                                        prefix={this._currencyFormat['prefix']}
                                        suffix={this._currencyFormat['suffix']}
                                        thousandSeparator={this._currencyFormat['thousandsSeparator']}
                                        decimalSeparator={this._currencyFormat['decimalSeparator']}
                                        decimalScale={this._currencyFormat['decimalScale']}
                                        value={total}
                                        displayType='text'
                                    />
                                </div>
                            </div>
                            <div className='shopping-cart-total'>
                                <div className='shopping-cart-total-label' style={{color: this.props.exclusivePriceLabelColor || '#005B80'}}>Con tarjeta:</div>
                                <div className='shopping-cart-total-value' style={{color: this.props.exclusivePriceLabelColor || '#005B80'}}>
                                    <CurrencyFormat
                                        prefix={this._currencyFormat['prefix']}
                                        suffix={this._currencyFormat['suffix']}
                                        thousandSeparator={this._currencyFormat['thousandsSeparator']}
                                        decimalSeparator={this._currencyFormat['decimalSeparator']}
                                        decimalScale={this._currencyFormat['decimalScale']}
                                        value={exclusiveTotal}
                                        displayType='text'
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    {hasInvalidItems && this.renderTotalError()}
                    <div className='shopping-cart-actions' style={this.actionsStyle(hasInvalidItems)}>
                        {!hasInvalidItems && (
                            <React.Fragment>
                                <div className='shopping-cart-action'>
                                    <Button label={this.props.paymentCallToAction} labelColor={this.props.actionButtonLabelColor} backgroundColor={this.props.actionButtonBackgroundColor} borderColor={this.props.actionButtonBorderColor} borderWidth={1} minWidth={270} maxWidth='100%' disabled={false} boxShadow={undefined} showPulseEffect={true} onClick={this.startPaymentAttempt}/>
                                </div>
                                {this.props.showBudgetPrintOption && (
                                    <div className='shopping-cart-action'>
                                        <Button label='Imprimir presupuesto' labelColor={this.props.secondaryActionButtonLabelColor} backgroundColor={this.props.secondaryActionButtonBackgroundColor} borderColor={this.props.secondaryActionButtonBorderColor} borderWidth={1} minWidth={270} maxWidth='100%' disabled={false} boxShadow='unset' showPulseEffect={false} onClick={this.printBudget}/>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                        <div className='shopping-cart-action'>
                            <Button label='Cerrar' labelColor={this.props.cancelButtonLabelColor} backgroundColor={this.props.cancelButtonBackgroundColor} borderColor={this.props.cancelButtonBorderColor} borderWidth={1} minWidth={270} maxWidth='100%' disabled={false} boxShadow='unset' onClick={() => this.handleClose('custom-close-button')}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    renderTotalError = () => {
        return (
            <div className='shopping-cart-total-error'>
                <img className='shopping-cart-total-error-icon' src={`${errorIcon}`} alt='Error'/>
                <div className='shopping-cart-total-error-label'>Reemplaza los productos sin stock para finalizar la compra</div>
            </div>
        );
    };

    render() {
        return (
            <Modal mode='right' backgroundColor='#FFFFFF' borderRadius={{topLeft: 0, bottomLeft: 0, topRight: 0, bottomRight: 0}} padding={0} width='40%' minWidth={320} maxWidth={500} showDefaultCloseButton={false} onClose={this.handleClose}>
                <div className='shopping-cart'>
                    {this.loadingData() && (
                        <div className='shopping-cart-loader'>
                            <div className='shopping-cart-loader-wrapper'>
                                <DataLoader message='Cargando información del carro de compras...'/>
                            </div>
                        </div>
                    )}
                    {!this.loadingData() && !this.dataLoaded() && (
                        <div className='shopping-cart-error'>
                            <div className='shopping-cart-error-wrapper'>
                                <DataError
                                    message='Se ha producido un error al cargar la información del carro de compras'
                                    actionLabel='Cerrar'
                                    action={() => this.handleClose('error-close-button')}
                                    actionButtonLabelColor={this.props.actionButtonLabelColor}
                                    actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                                    actionButtonBorderColor={this.props.actionButtonBorderColor}
                                />
                            </div>
                        </div>
                    )}
                    {!this.loadingData() && this.dataLoaded() && this.hasItems() && this.renderTotals()}
                    {!this.loadingData() && this.dataLoaded() && !this.hasItems() && (
                        <div className='shopping-cart-empty'>
                            <div className='shopping-cart-empty-icon-container'>
                                <img className='shopping-cart-empty-icon' src={`${cartIcon}`} alt='Shopping Cart'/>
                            </div>
                            <p className='shopping-cart-empty-title'>Tu carro está vacío</p>
                            <p className='shopping-cart-empty-description'>¡Descubre los miles de productos que tenemos para ti!</p>
                            <div className='shopping-cart-empty-action'>
                                <Button label='Buscar productos' labelColor={this.props.actionButtonLabelColor} backgroundColor={this.props.actionButtonBackgroundColor} borderColor={this.props.actionButtonBorderColor} minWidth={250} maxWidth='100%' boxShadow='unset' onClick={this.handleSearch}/>
                            </div>
                            <div className='shopping-cart-empty-action'>
                                <Button label='Cerrar' labelColor={this.props.cancelButtonLabelColor} backgroundColor={this.props.cancelButtonBackgroundColor} borderColor={this.props.cancelButtonBorderColor} minWidth={250} maxWidth='100%' boxShadow='unset' onClick={() => this.handleClose('empty-close-button')}/>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        );
    };
}

ShoppingCart.propTypes = {
    client: PropTypes.string,
    initialShoppingCart: PropTypes.object,
    showBudgetPrintOption: PropTypes.bool,
    discountBackgroundColor: PropTypes.string,
    discountLabelColor: PropTypes.string,
    exclusivePriceLabelColor: PropTypes.string,
    exclusivePriceIcon: PropTypes.string,
    paymentCallToAction: PropTypes.string,
    actionButtonBackgroundColor: PropTypes.string,
    actionButtonBorderColor: PropTypes.string,
    actionButtonLabelColor: PropTypes.string,
    secondaryActionButtonBackgroundColor: PropTypes.string,
    secondaryActionButtonBorderColor: PropTypes.string,
    secondaryActionButtonLabelColor: PropTypes.string,
    cancelButtonBackgroundColor: PropTypes.string,
    cancelButtonBorderColor: PropTypes.string,
    cancelButtonLabelColor: PropTypes.string,
    openSearch: PropTypes.func,
    onPaymentAttempt: PropTypes.func,
    onBudgetPrint: PropTypes.func,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    metadata: PropTypes.object
};