import React, {useRef, useState, useEffect} from 'react';
import ImageWrapper from '../../../ImageWrapper/ImageWrapper';
import PaymentStepActions from '../PaymentStepActions/PaymentStepActions';
import DataError from "../../../DataError/DataError";
import Debug from '../../../../helpers/Debug';
import defaultSmartphoneOptionQrScanImage from '../../PaymentFlows/ExternalCheckout/images/scan.gif';
import QRCode from "qrcode";
import './PaymentQRScan.css';

function PaymentQRScan({
    checkoutUrl,
    smartphoneOptionQrScanImage,
    cancelButtonBackgroundColor,
    cancelButtonLabelColor,
    cancelButtonBorderColor,
    backButtonBackgroundColor,
    backButtonBorderColor,
    backButtonLabelColor,
    onClose,
    onBack
}) {
    const [error, setError] = useState(false);
    const canvasRef = useRef(null);

    useEffect(() => {
        if (checkoutUrl) renderQrCode();
        else setError(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = () => {
        if (onClose) onClose();
    };

    const handleBack = () => {
        if (onBack) onBack();
    };

    const renderQrCode = () => {
        const canvas = canvasRef.current;
        QRCode.toCanvas(canvas, checkoutUrl, {width: 250, height: 250}, (error) => {
            if (error) {
                Debug.printToLog('error', error);
                setError(true);
            }
        });
    };

    return (
        <div className='payment-qr-scan'>
            {!error && (
                <React.Fragment>
                    <p className='payment-qr-scan-title'>
                        <span className='bold'>Ingresa los datos desde tu celular</span>
                    </p>
                    <p className='payment-qr-scan-description'>
                        Escanea el código QR con tu celular y <span className='bold'>completa los datos para obtener tu ticket digital para pagar en caja</span>
                    </p>
                    <div className='payment-qr-scan-content'>
                        <div className='payment-qr-scan-image'>
                            <ImageWrapper image={smartphoneOptionQrScanImage || defaultSmartphoneOptionQrScanImage} alt='Scan' display='block' width='100%' height='auto' borderRadius='100%' loadDelay={0.2}/>
                        </div>
                        <div className='payment-qr-scan-qr-image-container'>
                            <canvas ref={canvasRef} className='payment-qr-scan-qr-image-canvas'></canvas>
                            <p className='payment-qr-scan-qr-image-description'>Escanea el código</p>
                        </div>
                    </div>
                </React.Fragment>
            )}
            {error && (
                <div className='payment-qr-scan-error'>
                    <DataError message='Lo sentimos, se ha producido un error al generar el código QR'/>
                </div>
            )}
            <PaymentStepActions
                isLoading={false}
                loadingLabel={undefined}
                submitLabel={undefined}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                backButtonBackgroundColor={backButtonBackgroundColor}
                backButtonBorderColor={backButtonBorderColor}
                backButtonLabelColor={backButtonLabelColor}
                submitButtonBackgroundColor={undefined}
                submitButtonBorderColor={undefined}
                submitButtonLabelColor={undefined}
                showCloseButton={true}
                showBackButton={true}
                showSubmitButton={false}
                onClose={handleClose}
                onBack={handleBack}
                onSubmit={undefined}
            />
        </div>
    );
}

export default PaymentQRScan;