import React, {useState, useEffect} from 'react';
import Modal from '../Modal/Modal';
import FilterType from './FilterType/FilterType';
import NormalFilter from './NormalFilter/NormalFilter';
import SizeFilter from './SizeFilter/SizeFilter';
import PriceFilter from './PriceFilter/PriceFilter';
import Button from '../Button/Button';
import Viewport from '../../helpers/Viewport';
import Activity from "../../helpers/Activity";
import Text from '../../helpers/Text';
import dimensionsIcon from './images/dimensions.svg';
import triangleIconWhite from './images/triangle-white.png';
import triangleIconBlue from './images/triangle-blue.png';
import rulerIconWhite from './images/ruler-white.png';
import rulerIconBlue from './images/ruler-blue.png';
import dollarIconWhite from './images/dollar-white.png';
import dollarIconBlue from './images/dollar-blue.png';
import squaresIconWhite from './images/squares-white.png';
import squaresIconBlue from './images/squares-blue.png';
import './FiltersModal.css';

function FiltersModal({
    category,
    searchQuery,
    filters,
    onChange,
    onClear,
    onConfirm,
    onClose,
    backgroundColor,
    labelColor,
    checkboxBackgroundColor,
    brandsButtonStyle,
    sizeButtonStyle,
    priceButtonStyle,
    othersButtonStyle,
    actionButtonBackgroundColor,
    actionButtonBorderColor,
    actionButtonLabelColor,
    cancelButtonBackgroundColor,
    cancelButtonBorderColor,
    cancelButtonLabelColor,
    metadata
}) {
    const [mode, setMode] = useState(undefined);
    const borderRadius = {topLeft: 0, topRight: 0, bottomLeft: 50, bottomRight: 50};
    const viewport = Viewport.dimensions;
    const smallModeTreshold = {width: 768, height: 600};

    useEffect(() => {
        Activity.log(metadata, 'filters-modal', 'filters-button', 'open', {category_id: category.id, category_name: category.name, search_query: searchQuery || null});
        const initialMode = hasBrandsFilter() ? 'brands' : (hasSizeFilter() ? 'size' : (hasPriceFilter() ? 'price' : (hasOtherFilters() ? 'other' : undefined)));
        setMode(initialMode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const inSmallMode = () => {
        return viewport.width <= smallModeTreshold.width || viewport.height <= smallModeTreshold.height;
    };

    const handleNormalFilterClick = (filterId, optionId) => {
        if (onChange) onChange(filterId, optionId, []);
    };

    const handleSizeFilterChange = (filterId, optionId, value) => {
        if (onChange) onChange(filterId, optionId, [value]);
    };

    const handlePriceFilterChange = (filterId, optionId, startValue, endValue) => {
        if (onChange) onChange(filterId, optionId, [startValue, endValue]);
    };

    const handleClear = () => {
        if (onClear) {
            Activity.log(metadata, 'filters-modal', 'clear-button', 'clear', {category_id: category.id, category_name: category.name, search_query: searchQuery || null});
            onClear();
        }
        silentClose();
    };

    const handleConfirm = () => {
        if (onConfirm) {
            Activity.log(metadata, 'filters-modal', 'confirm-button', 'confirm', {category_id: category.id, category_name: category.name, search_query: searchQuery || null});
            onConfirm();
        }
        silentClose();
    };

    const silentClose = () => {
        if (onClose) onClose();
    };

    const handleClose = (closeType) => {
        if (onClose) {
            Activity.log(metadata, 'filters-modal', closeType, 'close', {category_id: category.id, category_name: category.name, search_query: searchQuery || null});
            onClose();
        }
    };

    const getDescription = () => {
        switch (mode) {
            case 'brands':
                return 'Seleccione sus marcas preferidas';
            case 'size':
                return 'Seleccione las medidas aproximadas del espacio disponible para el producto';
            case 'price':
                return 'Seleccione el rango de precio de su preferencia';
            default:
                return 'Seleccione más opciones para encontrar el producto perfecto para usted';
        }
    };

    const getOptionColor = (option) => {
        switch (option.label) {
            case 'Ancho':
                return '#FDBB31';
            case 'Alto':
                return '#45AB19';
            case 'Profundidad':
                return '#0071CE';
            default:
                return '#333333';
        }
    };

    const hasBrandsFilter = () => {
        return !!getBrandsFilter();
    };

    const hasSizeFilter = () => {
        return !!getSizeFilter();
    };

    const hasPriceFilter = () => {
        return !!getPriceFilter();
    };

    const hasOtherFilters = () => {
        const otherFilters = getOtherFilters();
        return otherFilters && otherFilters.length > 0;
    };

    const hasAnyFilters = () => {
        return hasBrandsFilter || hasSizeFilter || hasPriceFilter || hasOtherFilters;
    };

    const getBrandsFilter = () => {
        return filters.find(filter => filter.type === 'brands');
    };

    const getSizeFilter = () => {
        return filters.find(filter => filter.type === 'size');
    };

    const getPriceFilter = () => {
        return filters.find(filter => filter.type === 'price');
    };

    const getOtherFilters = () => {
        return filters.filter(filter => !['brands', 'size', 'price'].includes(filter.type));
    };

    const leftPanelStyle = () => {
        const viewport = Viewport.dimensions;
        return {
            width: mode === 'size' && viewport.width > 768 ? '50%' : '100%'
        };
    };

    const rightPanelStyle = () => {
        return {
            width: '50%'
        };
    };

    const titleStyle = () => {
        return {
            color: labelColor || '#111111'
        };
    };

    const descriptionStyle = () => {
        return {
            color: labelColor || '#111111'
        };
    };

    const actionsStyle = () => {
        return {
            background: backgroundColor || '#FFFFFF',
            borderBottomLeftRadius: borderRadius.bottomLeft,
            borderBottomRightRadius: borderRadius.bottomRight
        };
    };

    const renderOptions = () => {
        switch (mode) {
            case 'brands':
                return renderBrandsOptions();
            case'size':
                return renderSizeOptions();
            case 'price':
                return renderPriceOptions();
            case 'other':
                return renderOtherOptions();
            default:
                return undefined;
        }
    };

    const renderBrandsOptions = () => {
        const filter = filters.find(filter => filter.type === 'brands');
        if (filter) {
            const options = filter.options.sort((o1, o2) => o1.label.localeCompare(o2.label));
            const content = options.map((option) => (
                <NormalFilter
                    key={`normal-filter-option-${filter.id}-${option.id}`}
                    category={category}
                    searchQuery={searchQuery}
                    filterId={filter.id}
                    optionId={option.id}
                    filterLabel={filter.label}
                    optionLabel={option.label}
                    selected={option.selected}
                    checkboxBackgroundColor={checkboxBackgroundColor}
                    onClick={handleNormalFilterClick}
                    metadata={metadata}
                />
            ));
            return (
                <div className='filters-modal-brands-options'>
                    {content}
                </div>
            );
        } else return undefined;
    };

    const renderSizeOptions = () => {
        const filter = filters.find(filter => filter.type === 'size');
        if (filter) {
            return filter.options.map((option) => {
                const color = getOptionColor(option);
                return (
                    <SizeFilter
                        key={`size-filter-option-${filter.id}-${option.id}`}
                        category={category}
                        searchQuery={searchQuery}
                        filterId={filter.id}
                        optionId={option.id}
                        filterLabel={filter.label}
                        optionLabel={option.label}
                        value={option.value}
                        min={option.min}
                        max={option.max}
                        step={option.step}
                        unit={option.unit}
                        textColor={color}
                        sliderColor={color}
                        onChange={handleSizeFilterChange}
                        metadata={metadata}
                    />
                );
            });
        } else return undefined;
    };

    const renderPriceOptions = () => {
        const filter = filters.find(filter => filter.type === 'price');
        if (filter) {
            return filter.options.map((option) => (
                <PriceFilter
                    key={`price-filter-option-${filter.id}-${option.id}`}
                    category={category}
                    searchQuery={searchQuery}
                    filterId={filter.id}
                    optionId={option.id}
                    filterLabel={filter.label}
                    optionLabel={option.label}
                    startValue={option.startValue}
                    endValue={option.endValue}
                    min={option.min}
                    max={option.max}
                    step={option.step}
                    currency={option.currency}
                    sliderColor='#FDBB31'
                    onChange={handlePriceFilterChange}
                    metadata={metadata}
                />
            ));
        } else return undefined;
    };

    const renderOtherOptions = () => {
        let content = [];
        const otherFilters = getOtherFilters();
        const externalSaleFilterId = Text.toHash('external-sale-filter');
        const externalSale = otherFilters.find(filter => filter.id === externalSaleFilterId);
        const filteredList = otherFilters.filter(filter => filter.id !== externalSaleFilterId);
        let sortedFilters = [];
        if (externalSale) sortedFilters.push(externalSale);
        sortedFilters = sortedFilters.concat(filteredList.sort((o1, o2) => o1.label.localeCompare(o2.label)));
        sortedFilters.forEach((filter) => {
            const options = filter.options.sort((o1, o2) => o1.label.localeCompare(o2.label));
            content.push(
                <div key={`other-filters-container-${filter.id}`} className='filters-modal-other-options-container'>
                    <div className='filters-modal-other-options-title'>{filter.label}</div>
                    <div className='filters-modal-other-options'>
                        {options.map((option) => (
                            <NormalFilter
                                key={`other-filter-option-${filter.id}-${option.id}`}
                                category={category}
                                searchQuery={searchQuery}
                                filterId={filter.id}
                                optionId={option.id}
                                filterLabel={filter.label}
                                optionLabel={option.label}
                                selected={option.selected}
                                checkboxBackgroundColor={checkboxBackgroundColor}
                                onClick={handleNormalFilterClick}
                                metadata={metadata}
                            />
                        ))}
                    </div>
                </div>
            );
        });
        return content;
    };

    return (
        <Modal mode='top' smallMode={inSmallMode()} backgroundColor={backgroundColor} borderRadius={borderRadius}
               padding={40} width={undefined} height={950} minHeight='unset' maxHeight='100%'
               showDefaultCloseButton={false} onClose={handleClose}>
            <div className='filter-modal'>
                {hasAnyFilters() && (
                    <React.Fragment>
                        <div className='filter-modal-header'>
                            <div style={leftPanelStyle()}>
                                <div className='filter-modal-title' style={titleStyle()}>Filtros</div>
                                <div className='filter-modal-description' style={descriptionStyle()}>{getDescription()}</div>
                            </div>
                            {mode === 'size' && (
                                <div className='filter-modal-header-right-panel' style={rightPanelStyle()}>
                                    <img className='filter-modal-header-size-icon' src={`${dimensionsIcon}`} alt='Size'/>
                                    <div>
                                        <p className='filter-modal-header-size-legend-item' style={{color: '#FDBB31'}}>Ancho</p>
                                        <p className='filter-modal-header-size-legend-item' style={{color: '#45AB19'}}>Alto</p>
                                        <p className='filter-modal-header-size-legend-item' style={{color: '#0071CE'}}>Profundidad</p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='filter-modal-content'>
                            <div className='filter-modal-types'>
                                <div className='filter-modal-types-wrapper'>
                                    {hasBrandsFilter() && <FilterType category={category} searchQuery={searchQuery} type='brands' icon={brandsButtonStyle['inactiveIcon'] || triangleIconBlue} label='Marcas' labelColor={brandsButtonStyle['labelColor']} backgroundColor={brandsButtonStyle['backgroundColor']} active={mode === 'brands'} activeIcon={brandsButtonStyle['activeIcon'] || triangleIconWhite} activeLabelColor={brandsButtonStyle['activeLabelColor']} activeBackgroundColor={brandsButtonStyle['activeBackgroundColor']} onClick={() => setMode('brands')} metadata={metadata}/>}
                                    {hasSizeFilter() && <FilterType category={category} searchQuery={searchQuery} type='size' icon={sizeButtonStyle['inactiveIcon'] || rulerIconBlue} label='Medidas' labelColor={sizeButtonStyle['labelColor']} backgroundColor={sizeButtonStyle['backgroundColor']} active={mode === 'size'} activeIcon={sizeButtonStyle['activeIcon'] || rulerIconWhite} activeLabelColor={sizeButtonStyle['activeLabelColor']} activeBackgroundColor={sizeButtonStyle['activeBackgroundColor']} onClick={() => setMode('size')} metadata={metadata}/>}
                                    {hasPriceFilter() && <FilterType category={category} searchQuery={searchQuery} type='price' icon={priceButtonStyle['inactiveIcon'] || dollarIconBlue} label='Precio' labelColor={priceButtonStyle['labelColor']} backgroundColor={priceButtonStyle['backgroundColor']} active={mode === 'price'} activeIcon={priceButtonStyle['activeIcon'] || dollarIconWhite} activeLabelColor={priceButtonStyle['activeLabelColor']} activeBackgroundColor={priceButtonStyle['activeBackgroundColor']} onClick={() => setMode('price')} metadata={metadata}/>}
                                    {hasOtherFilters() && <FilterType category={category} searchQuery={searchQuery} type='other' icon={othersButtonStyle['inactiveIcon'] || squaresIconBlue} label='Otros' labelColor={othersButtonStyle['labelColor']} backgroundColor={othersButtonStyle['backgroundColor']} active={mode === 'other'} activeIcon={othersButtonStyle['activeIcon'] || squaresIconWhite} activeLabelColor={othersButtonStyle['activeLabelColor']} activeBackgroundColor={othersButtonStyle['activeBackgroundColor']} onClick={() => setMode('other')} metadata={metadata}/>}
                                </div>
                            </div>
                            <div className='filter-modal-options'>{renderOptions()}</div>
                        </div>
                        <div className='filter-modal-actions' style={actionsStyle()}>
                            <div className='filter-modal-action'>
                                <Button label='Limpiar' labelColor={cancelButtonLabelColor} backgroundColor={cancelButtonBackgroundColor} borderColor={cancelButtonBorderColor} minWidth='auto' maxWidth={150} boxShadow='unset' onClick={handleClear}/>
                            </div>
                            <div className='filter-modal-action'>
                                <Button label='Continuar' labelColor={actionButtonLabelColor} backgroundColor={actionButtonBackgroundColor} borderColor={actionButtonBorderColor} minWidth='auto' maxWidth={150} showPulseEffect={true} onClick={handleConfirm}/>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {!hasAnyFilters() && (
                    <div className='filter-modal-error'>
                        <div className='filter-modal-title'>Lo sentimos</div>
                        <div className='filter-modal-description'>No se han encontrado filtros</div>
                    </div>
                )}
            </div>
        </Modal>
    );
}

export default FiltersModal;