// noinspection JSUnusedGlobalSymbols

import Environment from '../config/Environment';
import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';
import {getAnalytics} from 'firebase/analytics';
import {getPerformance} from 'firebase/performance';

const app = initializeApp({
    apiKey: 'AIzaSyBn84J1vD-IqSRfKThGAUNMMoC0Ry6GuLs',
    authDomain: 'kiosk-3d971.firebaseapp.com',
    projectId: 'kiosk-3d971',
    storageBucket: 'kiosk-3d971.appspot.com',
    messagingSenderId: '992524982052',
    appId: '1:992524982052:web:710ba664520296011805b1',
    measurementId: 'G-TEEQSNDVQE'
});

export const firestore = getFirestore(app);

export const analytics = getAnalytics(app);

export const performance = getPerformance(app);

export const getCollectionPath = (client, collection) => {
    const environment = Environment.current;
    return `kiosk/${environment}/client/${client}/${collection}`;
};