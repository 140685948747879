import React from 'react';
import Text from '../../../helpers/Text';
import './CategoryTitle.css';

function CategoryTitle({title, fallbackTitle, icon, backgroundColor, labelColor, borderColor}) {
    const getTitle = () => {
        if (title) return Text.toUpperCase(title);
        else if (fallbackTitle) return Text.toUpperCase(fallbackTitle);
        else return 'Sin nombre';
    };

    const containerStyle = () => {
        return {
            background: backgroundColor || '#B3E9FF',
            color: labelColor || '#6F909D'
        };
    };

    const borderStyle = () => {
        return {
            backgroundColor: borderColor || '#6F909D'
        };
    };

    return (
        <h2 className='category-title' style={containerStyle()}>
            <div className='category-title-info'>
                <p className='category-title-text'>{getTitle()}</p>
                {icon && (
                    <div className='category-title-icon-container'>
                        <img className='category-title-icon' src={`${icon}`} alt={getTitle()}/>
                    </div>
                )}
            </div>
            <div className='category-title-bottom-border' style={borderStyle()}/>
        </h2>
    );
}

export default CategoryTitle;