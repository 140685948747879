import React, {useState, useEffect, useRef} from 'react';
import CrossLoader from '../CrossLoader/CrossLoader';
import noImage from '../CategoriesOptionsV2/CategoryOptionV2/images/no-image.png';
import './ImageViewer.css';

function ImageViewer({product, selectedImageIndex}) {
    const imageRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const loadDelay = 0.2;

    useEffect(() => {
        const element = imageRef.current;
        element.onload = () => {
            setTimeout(() => {
                setIsLoading(false);
                setLoaded(true);
            }, loadDelay * 1000);
        };
        element.onerror = () => {
            setTimeout(() => {
                setIsLoading(false);
                setLoaded(false);
            }, loadDelay * 1000);
        };
        element.src = getImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const element = imageRef.current;
        const newImage = getImage();
        const oldImage = element.src;
        if (newImage !== oldImage) {
            setIsLoading(true);
            setTimeout(() => {element.src = newImage}, loadDelay * 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, selectedImageIndex]);

    const getImage = () => {
        const multimedia = product.multimedia;
        const galleryItem = multimedia && multimedia.gallery && multimedia.gallery.length > 0 ? multimedia.gallery[selectedImageIndex] : undefined;
        const image = galleryItem && galleryItem.l ? galleryItem.l : undefined;
        return !image || (!isLoading && !loaded) ? noImage : image;
    };

    const imageStyle = () => {
        return {
            opacity: isLoading ? 0 : 1
        };
    };

    const loaderStyle = () => {
        return {
            opacity: isLoading ? 1 : 0
        };
    };

    return (
        <div className='image-viewer'>
            <img className='image-viewer-image' ref={imageRef} alt='Product' style={imageStyle()}/>
            <div className='image-viewer-loader' style={loaderStyle()}>
                <CrossLoader size={200}/>
            </div>
        </div>
    );
}

export default ImageViewer;