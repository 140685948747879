import React from 'react';
import ImageWrapper from "../../ImageWrapper/ImageWrapper";
import Activity from '../../../helpers/Activity';
import './FilterType.css';

function FilterType({category, searchQuery, type, icon, label, labelColor, backgroundColor, active, activeIcon, activeLabelColor, activeBackgroundColor, onClick, metadata}) {

    const handleClick = () => {
        if (onClick) {
            Activity.log(metadata, 'filter-type', `${type}-button`, 'click', {category_id: category.id, category_name: category.name, search_query: searchQuery || null});
            onClick();
        }
    };

    const normalStateStyle = () => {
        return {
            opacity: active ? 0 : 1
        };
    };

    const activeStateStyle = () => {
        return {
            opacity: active ? 1 : 0
        };
    };

    const filterTypeStyle = () => {
        return {
            backgroundColor: active ? (activeBackgroundColor || '#005B80') : (backgroundColor || '#FFFFFF')
        };
    };

    const labelStyle = () => {
        return {
            color: active ? (activeLabelColor || '#FFFFFF') : (labelColor || '#005B80'),
            marginTop: icon && activeIcon ? 5 : 0
        };
    };

    return (
        <div className='filter-type' onClick={handleClick} style={filterTypeStyle()}>
            {icon && activeIcon && (
                <div className='filter-type-icon-wrapper'>
                    <div className='filter-type-icon filter-type-icon-normal' style={normalStateStyle()}>
                        <ImageWrapper image={icon} alt={label || 'Label'} display='block' width={60} height='auto' loadDelay={0}/>
                    </div>
                    <div className='filter-type-icon filter-type-icon-active' style={activeStateStyle()}>
                        <ImageWrapper image={activeIcon} alt={label || 'Label'} display='block' width={60} height='auto' loadDelay={0}/>
                    </div>
                </div>
                )}
            <div className='filter-type-label' style={labelStyle()}>{label || 'Label'}</div>
        </div>
    );
}

export default FilterType;