import React from 'react';
import CategoryOptionV2 from './CategoryOptionV2/CategoryOptionV2';
import './CategoriesOptionsV2.css';

function CategoriesOptionsV2({parent, searchQuery, categories, selectedCategories, activeColor, pulseDuration, onClick, metadata}) {
    const elementWidth = 150;
    const marginRight = 15;

    const handleClick = (categoryId) => {
        let newSelectedCategories = [...selectedCategories];
        const exists = newSelectedCategories.includes(categoryId);
        if (exists) newSelectedCategories = newSelectedCategories.filter(id => id !== categoryId);
        else newSelectedCategories.push(categoryId);
        if (onClick) onClick(newSelectedCategories);
    };

    const renderCategories = () => {
        return categories.map((category, index) => (
            <CategoryOptionV2
                key={`category-option-v2-${index}`}
                parent={parent}
                searchQuery={searchQuery}
                category={category}
                width={elementWidth}
                marginRight={marginRight}
                selected={selectedCategories.includes(category.id)}
                activeColor={activeColor}
                pulseDuration={pulseDuration}
                onClick={handleClick}
                metadata={metadata}
            />
        ));
    };

    return (
        <div className='categories-options-v2'>
            {renderCategories()}
        </div>
    );
}

export default CategoriesOptionsV2;