export class Timer {
    constructor({seconds}) {
        this.seconds = seconds;
    };

    secondsToTimer() {
        const hours = Math.floor(this.seconds / 3600);
        const minutes = Math.floor((this.seconds % 3600) / 60);
        const seconds = this.seconds % 60;
        return this.pad(hours) + ':' + this.pad(minutes) + ':' + this.pad(seconds);
    };

    pad(num) {
        return (num < 10 ? '0' : '') + num;
    };
}