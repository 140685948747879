export class StyleMap {
    constructor({global, category, product, categories}) {
        this.global = global;
        this.categories = categories;
        this.category = category;
        this.product = product;
    };

    clone() {
        return new StyleMap({
            global: this.global,
            categories: this.categories,
            category: this.category,
            product: this.product
        });
    };
}

export const styleMapConverter = {
    toFirestore() {
        return null;
    },
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return new StyleMap({
            global: data.global,
            categories: data.categories,
            category: data.category,
            product: data.product
        });
    }
};