import React, {useState} from 'react';
import ImageWrapper from "../../ImageWrapper/ImageWrapper";
import './ActionBarItem.css';

function ActionBarItem({
    active,
    enabled,
    label,
    icon,
    activeIcon,
    disabledIcon,
    labelColor,
    backgroundColor,
    activeLabelColor,
    activeBackgroundColor,
    disabledLabelColor,
    disabledBackgroundColor,
    indicatorBackgroundColor,
    indicatorLabelColor,
    showNotification,
    notificationNumber,
    onClick
}) {
    const [isHovering, setIsHovering] = useState(false);
    const validLabelColor = labelColor || '#414042';
    const validBackgroundColor = backgroundColor || '#FFFFFF';
    const validActiveLabelColor = activeLabelColor || '#FFFFFF';
    const validActiveBackgroundColor = activeBackgroundColor || '#B3E9FF';
    const validDisabledLabelColor = disabledLabelColor || '#E3E3E3';
    const validDisabledBackgroundColor = disabledBackgroundColor || '#FFFFFF';
    const validIndicatorBackgroundColor = indicatorBackgroundColor || '#FFB819';
    const validIndicatorLabelColor = indicatorLabelColor || '#111111';

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const handleClick = () => {
        if (onClick && enabled) onClick();
    };

    const normalStateStyle = () => {
        return {
            background: validBackgroundColor,
            opacity: enabled && !active && !isHovering ? 1 : 0
        };
    };

    const activeStateStyle = () => {
        return {
            background: validActiveBackgroundColor,
            opacity: enabled && (active || isHovering) ? 1 : 0
        };
    };

    const disabledStateStyle = () => {
        return {
            background: validDisabledBackgroundColor,
            opacity: enabled ? 0 : 1
        };
    };

    const indicatorStyle = () => {
        return {
            background: validIndicatorBackgroundColor,
            color: validIndicatorLabelColor
        };
    };

    return (
        <div className='action-bar-item' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
            <div className='action-bar-item-wrapper'>
                <div className='action-bar-item-state action-bar-item-normal-state' style={normalStateStyle()}>
                    <div className='action-bar-item-state-wrapper'>
                        <div className='action-bar-item-icon'>
                            <ImageWrapper image={icon} alt={label} display='block' width={40} height='auto' loadDelay={0}/>
                        </div>
                        <p className='action-bar-item-label' style={{color: validLabelColor}}>{label}</p>
                    </div>
                </div>
                <div className='action-bar-item-state action-bar-item-active-state' style={activeStateStyle()}>
                    <div className='action-bar-item-state-wrapper'>
                        <div className='action-bar-item-icon'>
                            <ImageWrapper image={activeIcon || icon} alt={label} display='block' width={40} height='auto' loadDelay={0}/>
                        </div>
                        <p className='action-bar-item-label' style={{color: validActiveLabelColor}}>{label}</p>
                    </div>
                </div>
                <div className='action-bar-item-state action-bar-item-disabled-state' style={disabledStateStyle()}>
                    <div className='action-bar-item-state-wrapper'>
                        <div className='action-bar-item-icon'>
                            <ImageWrapper image={disabledIcon || icon} alt={label} display='block' width={40} height='auto' loadDelay={0}/>
                        </div>
                        <p className='action-bar-item-label' style={{color: validDisabledLabelColor}}>{label}</p>
                    </div>
                </div>
            </div>
            {showNotification && !!notificationNumber && notificationNumber > 0 && (
                <div className='action-bar-item-number' style={indicatorStyle()}>{notificationNumber}</div>
            )}
        </div>
    );
}

export default ActionBarItem;