import React, {useState} from 'react';
import './BannerCarouselDot.css';

function BannerCarouselDot({index, active, activeColor, onClick}) {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const handleClick = (event) => {
        event.stopPropagation();
        if (onClick) onClick(index);
    };

    const dotStyle = () => {
        return {
            backgroundColor: active ? (activeColor || '#6F909D') : isHovering ? '#CCCCCC' : '#FFFFFF'
        };
    };

    return (
        <div className={`banner-carousel-dot ${active ? 'active' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick} style={dotStyle()}>
        </div>
    );
}

export default BannerCarouselDot;