import md5 from 'md5';

export default class Text {
    static trim(text) {
        return text ? text.trim() : undefined;
    };

    static normalize(text) {
        const specialCharacters = ['á', 'é', 'í', 'ó', 'ú', 'ñ'];
        const normalCharacters = ['a', 'e', 'i', 'o', 'u', 'n'];
        const letters = text.toString().toLowerCase().split('');
        for (let i = 0; i < letters.length; i++) {
            for (let j = 0; j < specialCharacters.length; j++) {
                if (letters[i] === specialCharacters[j]) {
                    letters[i] = normalCharacters[j];
                }
            }
        }
        return letters.join('');
    };

    static minify(text) {
        if (!text) return undefined;
        const trimmed = Text.trim(text);
        return Text.normalize(trimmed);
    };

    static toHash(text) {
        return text ? md5(Text.minify(text)) : undefined;
    };

    static capitalizeFirst(text) {
        if (!text) return undefined;
        text = Text.trim(text);
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    static capitalizeAll(text) {
        if (!text) return undefined;
        text = Text.trim(text);
        return text.split(' ').map(word => Text.capitalizeFirst(word)).join(' ');
    };

    static toUpperCase(text) {
        if (!text) return undefined;
        text = Text.trim(text);
        return text ? text.toUpperCase() : undefined;
    }
}