import React, {useState} from 'react';
import RangeSlider from '../../RangeSlider/RangeSlider';
import CurrencyFormat from 'react-currency-format';
import Currency from '../../../config/Currency';
import Activity from "../../../helpers/Activity";
import './PriceFilter.css';
import {PRICE_FILTER_HIGER_STEP, PRICE_FILTER_LOWER_STEP, PRICE_FILTER_STEP_THRESHOLD} from "../../../config/App";

function PriceFilter({category, searchQuery, filterId, optionId, filterLabel, optionLabel, startValue, endValue, min, max, step, currency, sliderColor, onChange, metadata}) {
    const [renderedStartValue, setRenderedStartValue] = useState(startValue);
    const [renderedEndValue, setRenderedEndValue] = useState(endValue);

    const currencyFormat = Currency[currency];

    const handleChange = (start, end) => {
        setRenderedStartValue(start);
        setRenderedEndValue(end);
    };

    const handleCommit = (start, end) => {
        if (onChange) onChange(filterId, optionId, start, end);
    };

    const handleRelease = (start, end) => {
        Activity.log(metadata, 'filter-option', optionId, 'slide', {category_id: category.id, category_name: category.name, search_query: searchQuery || null, filter_id: filterId, option_id: optionId, filter_label: filterLabel, option_label: optionLabel, start_value: start, end_value: end, currency: currency});
    };

    if (min >= 0 && max >= 0 && min < max) {
        const validMin = Math.floor(min);
        const validMax = Math.ceil(max);
        const validStartValue = startValue >= 0 ? startValue : validMin;
        const validEndValue = endValue >= 0 ? endValue : validMax;
        const validRenderedStartValue = renderedStartValue >= 0 ? renderedStartValue : validStartValue;
        const validRenderedEndValue = renderedEndValue >= 0 ? renderedEndValue : validEndValue;
        const validStep = step > 0 ? step : 1;
        const buttonsStep = validMax < PRICE_FILTER_STEP_THRESHOLD ? PRICE_FILTER_LOWER_STEP : PRICE_FILTER_HIGER_STEP;
        return (
            <div className='price-filter'>
                <div className='price-filter-header'>
                    <p className='price-filter-label'>{optionLabel}</p>
                    <p className='price-filter-value'>
                        <CurrencyFormat
                            prefix={currencyFormat.prefix}
                            suffix={currencyFormat.suffix}
                            thousandSeparator={currencyFormat.thousandsSeparator}
                            decimalSeparator={currencyFormat.decimalSeparator}
                            decimalScale={currencyFormat.decimalScale}
                            value={validRenderedStartValue}
                            displayType='text'
                        />
                        <span> - </span>
                        <CurrencyFormat
                            prefix={currencyFormat.prefix}
                            suffix={currencyFormat.suffix}
                            thousandSeparator={currencyFormat.thousandsSeparator}
                            decimalSeparator={currencyFormat.decimalSeparator}
                            decimalScale={currencyFormat.decimalScale}
                            value={validRenderedEndValue}
                            displayType='text'
                        />
                    </p>
                </div>
                <div className='price-filter-slider'>
                    <RangeSlider
                        initialStartValue={validStartValue}
                        initialEndValue={validEndValue}
                        min={validMin}
                        max={validMax}
                        step={validStep}
                        buttonsStep={buttonsStep}
                        highlightColor={sliderColor}
                        onChange={handleChange}
                        onCommit={handleCommit}
                        onRelease={handleRelease}
                    />
                </div>
            </div>
        );
    } else return undefined;
}

export default PriceFilter;