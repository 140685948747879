import React from 'react';
import FilterOption from './FilterOption/FilterOption';
import './FiltersOptions.css';

function FiltersOptions({category, searchQuery, filters, labelColor, backgroundColor, onDelete, metadata}) {
    const elementWidth = 200;
    const marginRight = 15;

    const handleDelete = (filterId, optionId) => {
        if (onDelete) onDelete(filterId, optionId);
    };

    const renderFilters = () => {
        let content = [];
        filters.forEach((filter) => {
            filter.options.forEach((option) => {
                content.push(
                    <FilterOption
                        key={`filter-option-${filter.id}-${option.id}`}
                        category={category}
                        searchQuery={searchQuery}
                        parentFilter={filter}
                        filterOption={option}
                        width={elementWidth}
                        marginRight={marginRight}
                        labelColor={labelColor}
                        backgroundColor={backgroundColor}
                        onDelete={handleDelete}
                        metadata={metadata}
                    />
                );
            });
        });
        return content;
    };

    return (
        <div className='filters-options'>
            {renderFilters()}
        </div>
    );
}

export default FiltersOptions;