export default class Currency {
    static get CLP() {
        return {
            prefix: '$',
            thousandsSeparator: '.',
            decimalSeparator: ',',
            decimalScale: 0,
            suffix: '',
            symbol: 'CLP',
            taxLabel: 'IVA (19%)',
            taxPercentage: 0.19
        };
    };
}