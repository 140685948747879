import React, {useState, useRef, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import CrossLoader from '../../CrossLoader/CrossLoader';
import Text from "../../../helpers/Text";
import Activity from '../../../helpers/Activity';
import Color from '../../../helpers/Color';
import Navigation from '../../../config/Navigation';
import noImage from './images/no-image.png';
import './Subcategory.css';

function Subcategory({parent, subcategory, pulseColor, pulseDuration, onPulseFinish, metadata}) {
    const wrapperRef = useRef(null);
    const imageRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingImage, setIsLoadingImage] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [pulseEffect, setPulseEffect] = useState(null);
    const [pulseTimeout, setPulseTimeout] = useState(null);
    const navigate = useNavigate();
    const loadDelay = 0.2; // Seconds.

    useEffect(() => {
        const element = imageRef.current;
        element.onload = () => {
            setTimeout(() => {
                setIsLoadingImage(false);
                setImageLoaded(true);
            }, loadDelay * 1000);
        };
        element.onerror = () => {
            setTimeout(() => {
                setIsLoadingImage(false);
                setImageLoaded(false);
            }, loadDelay * 1000);
        };
        element.src = getImage();
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const element = imageRef.current;
        const newImage = getImage();
        const oldImage = element.src;
        if (newImage !== oldImage) {
            setIsLoadingImage(true);
            setTimeout(() => {element.src = newImage}, loadDelay * 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subcategory.image]);

    useEffect(() => {
        if (subcategory.focus) startPulseEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subcategory.focus]);

    useEffect(() => {
        if (pulseEffect) {
            const timeout = setTimeout(() => {
                stopPulseEffect();
                if (onPulseFinish) onPulseFinish(parent.id, subcategory.id);
            }, pulseDuration * 1000);
            setPulseTimeout(timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pulseEffect]);

    useEffect(() => {
        if (pulseEffect && subcategory.focus) startPulseEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pulseColor]);

    const startPulseEffect = () => {
        if (wrapperRef) {
            stopPulseEffect();
            stopPulseTimeout();
            const rgb = Color.hexToRgb(pulseColor) || {r: 0, g: 91, b: 128};
            // noinspection JSUnresolvedReference
            const pulseEffect = wrapperRef.current.animate([
                {boxShadow: `0 0 0 0 rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9)`},
                {boxShadow: `0 0 0 10px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0)`},
                {boxShadow: `0 0 0 0 rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0)`}
            ], {
                duration: 1500,
                iterations: Infinity
            });
            setPulseEffect(pulseEffect);
        }
    };

    const stopPulseEffect = () => {
        if (pulseEffect) {
            pulseEffect.cancel();
            setPulseEffect(undefined);
        }
    };

    const stopPulseTimeout = () => {
        if (pulseTimeout) {
            clearTimeout(pulseTimeout)
            setPulseTimeout(undefined);
        }
    };

    const handleClick = () => {
        Activity.log(metadata, 'category', subcategory.id, 'click', {parent_id: parent.id, parent_name: parent.name, category_id: subcategory.id, category_name: subcategory.name});
        const url = Navigation.getCategoryUrl(subcategory.id);
        navigate(url);
    };

    const getImage = () => {
        return !subcategory.image || (!isLoadingImage && !imageLoaded) ? noImage : subcategory.image;
    };

    const subcategoryStyle = () => {
        return {
            transform: `scale(${isLoading ? 0 : 1})`,
        };
    };

    const imageStyle = () => {
        return {
            opacity: isLoadingImage ? 0 : 1
        };
    };

    const loaderStyle = () => {
        return {
            opacity: isLoadingImage ? 1 : 0
        };
    };

    const name = subcategory.name ? Text.capitalizeAll(subcategory.name) : 'Sin nombre';

    return (
        <div id={subcategory.id} className='subcategory' style={subcategoryStyle()}>
            <div ref={wrapperRef} className='subcategory-image-wrapper' onClick={handleClick}>
                <img ref={imageRef} className='subcategory-image' alt={name} loading="lazy" style={imageStyle()}/>
                <div className='subcategory-loader' style={loaderStyle()}>
                    <CrossLoader/>
                </div>
            </div>
            <h3 className='subcategory-name'>{name}</h3>
        </div>
    );
}

export default Subcategory;