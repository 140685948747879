import React from 'react';
import ProductBox from '../ProductBox/ProductBox';
import './ProductBoxSlider.css';

function ProductBoxSlider({
    category,
    products,
    showScrollbar,
    visible,
    disabled,
    clientName,
    discountBackgroundColor,
    discountLabelColor,
    exclusivePriceLabelColor,
    exclusivePriceIcon,
    offerBackgroundColor,
    offerLabelColor,
    externalSaleBorderColor,
    externalSaleBackgroundColor,
    externalSaleLabelColor,
    sellerLabelColor,
    mainSellerIcon,
    showPulseEffect,
    pulseEffectColor,
    onClick
}) {
    const renderProducts = () => {
        return products.map((product, index) => (
            <ProductBox
                key={`product-${product.id}`}
                index={index}
                category={category}
                product={product}
                showSku={false}
                showIcons={false}
                active={false}
                showBorder={false}
                clientName={clientName}
                _3dIcon={undefined}
                featuredIcon={undefined}
                discountBackgroundColor={discountBackgroundColor}
                discountLabelColor={discountLabelColor}
                exclusivePriceLabelColor={exclusivePriceLabelColor}
                exclusivePriceIcon={exclusivePriceIcon}
                offerBackgroundColor={offerBackgroundColor}
                offerLabelColor={offerLabelColor}
                showTags={false}
                deliveryIcon={undefined}
                deliveryLabelColor={undefined}
                deliveryBackgroundColor={undefined}
                freeDeliveryIcon={undefined}
                freeDeliveryLabelColor={undefined}
                freeDeliveryBackgroundColor={undefined}
                pickupIcon={undefined}
                pickupLabelColor={undefined}
                pickupBackgroundColor={undefined}
                externalSaleBorderColor={externalSaleBorderColor}
                externalSaleBackgroundColor={externalSaleBackgroundColor}
                externalSaleLabelColor={externalSaleLabelColor}
                sellerLabelColor={sellerLabelColor}
                mainSellerIcon={mainSellerIcon}
                showPulseEffect={showPulseEffect}
                pulseEffectColor={pulseEffectColor}
                visible={visible}
                disabled={disabled}
                onClick={onClick}
            />
        ));
    };

    return (
        <div className={`product-box-slider ${showScrollbar ? 'scrollbar' : 'no-scrollbar'}`}>
            {renderProducts()}
        </div>
    );
}

export default ProductBoxSlider;