import React from 'react';
import './SectionTitle.css';

function SectionTitle({title, subtitle}) {
    return (
        <div className='section-title-container'>
            <div className='section-title'>{title || 'Sin título'}</div>
            {subtitle && <div className='section-subtitle'>{subtitle}</div>}
        </div>
    );
}

export default SectionTitle;