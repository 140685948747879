// noinspection JSUnusedGlobalSymbols,JSUnresolvedReference

import {firestore, getCollectionPath} from '../config/Firebase';
import {doc, updateDoc} from 'firebase/firestore';

export class Instance {
    constructor({id, client, name, active, catalog, store}) {
        this.id = id;
        this.client = client;
        this.name = name;
        this.active = active;
        this.catalog = catalog;
        this.store = store;
    };

    updateStatus(active) {
        if (this.id && this.client) {
            const _path = getCollectionPath(this.client, 'instances');
            const _doc = doc(firestore, _path, this.id);
            updateDoc(_doc, {active: active});
        }
    };

    setActive() {
        this.updateStatus(true);
    };

    setInactive() {
        this.updateStatus(false);
    };

    clone() {
        return new Instance({
            id: this.id,
            client: this.client,
            name: this.name,
            active: this.active,
            catalog: this.catalog,
            store: this.store
        });
    };
}

export const instanceConverter = {
    toFirestore() {
        return null;
    },
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return new Instance({
            id: snapshot.id,
            client: undefined,
            name: data.name,
            active: data.active,
            catalog: data.catalog,
            store: data.store
        });
    }
};