// noinspection JSUnusedGlobalSymbols,JSUnresolvedReference

export class Category {
    constructor({id, name, image, icon, deepLevel, productsQuantity, customDeepName, order, isEmpty, isVisible, createdAt, updatedAt, parents, focus, children}) {
        this.id = id;
        this.name = name;
        this.image = image;
        this.icon = icon;
        this.deepLevel = deepLevel;
        this.productsQuantity = productsQuantity;
        this.customDeepName = customDeepName;
        this.order = order;
        this.isEmpty = isEmpty;
        this.isVisible = isVisible;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.parents = parents;
        this.focus = focus;
        this.children = children;
    };

    clone() {
        return new Category({
            id: this.id,
            name: this.name,
            image: this.image,
            icon: this.icon,
            deepLevel: this.deepLevel,
            productsQuantity: this.productsQuantity,
            customDeepName: this.customDeepName,
            order: this.order,
            isEmpty: this.isEmpty,
            isVisible: this.isVisible,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            parents: this.parents,
            focus: this.focus,
            children: this.children
        });
    };
}

export const categoryConverter = {
    toFirestore() {
        return null;
    },
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return new Category({
            id: snapshot.id,
            name: data.label || data.name,
            image: data.image,
            icon: data.icon,
            deepLevel: data.deep_level,
            productsQuantity: data.products_quantity || 0,
            customDeepName: data.custom_deep_name,
            order: data.order,
            isEmpty: data.is_empty,
            isVisible: data.is_visible,
            createdAt: data.created_at,
            updatedAt: data.updated_at,
            parents: data.parents,
            focus: false,
            children: []
        });
    }
};