export default class Debug {
    static printToLog(type, message) {
        if (type === 'info') {
            console.info('KSK_INF:', message);
        } else if (type === 'error') {
            console.error('KSK_ERR:', message);
        } else {
            console.log('KSK_LOG:', message);
        }
    };
}