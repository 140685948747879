import React, {useState} from 'react';
import VariantBox from './VariantBox/VariantBox';
import SidebarTabs from '../SidebarTabs/SidebarTabs';
import Button from '../Button/Button';
import Viewport from '../../helpers/Viewport';
import Activity from '../../helpers/Activity';
import {Product} from '../../models/Product';
import './ProductVariantsSidebar.css';

function ProductVariantsSidebar({
    isOpen,
    product,
    selectedVariant,
    tabHoverLabelColor,
    tabHoverBorderColor,
    discountBackgroundColor,
    discountLabelColor,
    exclusivePriceLabelColor,
    exclusivePriceIcon,
    selectedVariantBorderColor,
    addToCartButtonIcon,
    actionButtonBackgroundColor,
    actionButtonBorderColor,
    actionButtonLabelColor,
    cancelButtonBackgroundColor,
    cancelButtonBorderColor,
    cancelButtonLabelColor,
    onVariantClick,
    onAddToCartClick,
    onBuyOnlineClick,
    onClose,
    metadata
}) {
    const viewport = Viewport.dimensions;

    const inSmallMode = () => {
        return viewport.height <= 1400;
    };

    const contentMargin = inSmallMode() ? 0 : 200;
    const tabsHeight = 40;
    const tabsPadding = 25;
    const actionsHeight = 200;
    const actionsMargin = 0;

    const hasVariants = () => {
        return product && product.variants && product.variants.length > 0;
    };

    let initialMode = undefined;
    if (hasVariants()) initialMode = 'variants';
    const [mode, setMode] = useState(initialMode);

    const getAvailableTabs = () => {
        let tabs = [];
        if (hasVariants()) tabs.push({id: 'variants', label: 'Variantes'});
        return tabs;
    };

    const handleVariantClick = (variantId) => {
        if (onVariantClick) {
            const variant = product.variants.find((variant) => variant.id === variantId);
            if (variant) {
                Activity.log(metadata, 'variant', variantId, 'select', {product_id: product.id, product_name: product.name, variant_id: variant.id, variant_name: variant.name});
                onVariantClick(variantId);
            }
        }
    };

    const handleAddToCart = () => {
        if (onAddToCartClick) onAddToCartClick('variants-sidebar');
    };

    const handleBuyOnline = () => {
        if (onBuyOnlineClick) onBuyOnlineClick('variants-sidebar');
    };

    const handleClose = (closeType) => {
        if (onClose) {
            Activity.log(metadata, 'variants-sidebar', closeType, 'close', {product_id: product.id, product_name: product.name});
            onClose();
        }
    };

    const changeMode = (newMode) => {
        setMode(newMode);
    };

    const inVariantsMode = () => {
        return mode === 'variants';
    };

    const containerStyle = () => {
        return {
            right: isOpen ? 0 : -320,
            opacity: isOpen ? 1 : 0
        };
    };

    const contentStyle = () => {
        const offset = contentMargin + tabsHeight + tabsPadding + actionsHeight + actionsMargin;
        return {
            height: `calc(100% - ${offset}px)`,
            maxHeight: inSmallMode() ? 'unset' : 800
        };
    };

    const availableTabs = getAvailableTabs();

    const renderVariants = () => {
        const validVariants = Product.filterValidProducts(product.variants);
        return validVariants.map((variant, index) => {
            return (
                <VariantBox
                    key={product.id + '-variant-' + variant.id}
                    variant={variant}
                    selected={selectedVariant === variant.id}
                    isLast={index === product.variants.length - 1}
                    discountBackgroundColor={discountBackgroundColor}
                    discountLabelColor={discountLabelColor}
                    exclusivePriceLabelColor={exclusivePriceLabelColor}
                    exclusivePriceIcon={exclusivePriceIcon}
                    selectedBorderColor={selectedVariantBorderColor}
                    onClick={handleVariantClick}
                />
            );
        });
    };

    const renderError = (message) => {
        return (
            <div className='product-variants-sidebar-error'>
                <span className='product-variants-sidebar-error-message'>{message}</span>
                {renderActions()}
            </div>
        );
    };

    const renderActions = () => {
        return (
            <div className='product-variants-sidebar-actions' style={{height: actionsHeight, marginTop: actionsMargin}}>
                <div className='product-variants-sidebar-action'>
                    <React.Fragment>
                        {!product.externalSale && (
                            <Button icon={addToCartButtonIcon} label='Agregar al carro' labelColor={actionButtonLabelColor} backgroundColor={actionButtonBackgroundColor} borderColor={actionButtonBorderColor} width={250} minWidth='auto' maxWidth='100%' boxShadow='unset' showPulseEffect={false} onClick={handleAddToCart}/>
                        )}
                        {product.externalSale && (
                            <Button icon={addToCartButtonIcon} label='Comprar online' labelColor={actionButtonLabelColor} backgroundColor={actionButtonBackgroundColor} borderColor={actionButtonBorderColor} width={250} minWidth='auto' maxWidth='100%' boxShadow='unset' showPulseEffect={false} onClick={handleBuyOnline}/>
                        )}
                    </React.Fragment>
                </div>
                <div className='product-variants-sidebar-action'>
                    <Button label='Cerrar' labelColor={cancelButtonLabelColor} backgroundColor={cancelButtonBackgroundColor} borderColor={cancelButtonBorderColor} width={250} minWidth='auto' maxWidth='100%' boxShadow='unset' showPulseEffect={false} onClick={() => handleClose('custom-close-button')}/>
                </div>
            </div>
        );
    };

    return (
        <div className='product-variants-sidebar' style={containerStyle()}>
            {(hasVariants()) && (
                <React.Fragment>
                    <div className='product-variants-sidebar-tabs-container' style={{padding: `${tabsPadding}px ${tabsPadding}px 0 ${tabsPadding}px`, marginTop: contentMargin}}>
                        <SidebarTabs
                            tabs={availableTabs}
                            currentTab={mode}
                            height={tabsHeight}
                            hoverLabelColor={tabHoverLabelColor}
                            hoverBorderColor={tabHoverBorderColor}
                            onClick={changeMode}/>
                    </div>
                    <div className='product-variants-sidebar-content' style={contentStyle()}>
                        {inVariantsMode() && hasVariants() && renderVariants()}
                    </div>
                    {renderActions()}
                </React.Fragment>
            )}
            {!hasVariants() && renderError('No se han encontrado variantes para este producto')}
        </div>
    );
}

export default ProductVariantsSidebar;