import React, {useState} from "react";
import "./PaginatorPage.css";

function PaginatorPage({type, icon, hoverIcon, name, value, active, enabled, action, activeBackgroundColor, activeLabelColor}) {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const handlePaginationClick = () => {
        if (action && enabled && value !== undefined) {
            action(type, value);
        }
    };

    const getButtonIcon = () => {
        if (enabled) {
            if (active) {
                return hoverIcon;
            } else {
                return isHovering ? hoverIcon : icon;
            }
        } else {
            return icon;
        }
    }

    const buttonStyle = () => {
        const isEllipsis = name === "...";
        let background;
        let color;
        if (isEllipsis) {
            background = "white";
            color = "#333333";
        } else {
            if (enabled) {
                if (active) {
                    background = activeBackgroundColor ? activeBackgroundColor : "#005B80";
                    color = activeLabelColor ? activeLabelColor : "#FFFFFF";
                } else {
                    if (isHovering) {
                        background = activeBackgroundColor ? activeBackgroundColor : "#005B80";
                        color = activeLabelColor ? activeLabelColor : "#FFFFFF";
                    } else {
                        background = "white";
                        color = "#333333";
                    }
                }
            } else {
                background = "#eeeeee";
                color = "#333333";
            }
        }
        return {
            color: color,
            background: background,
            cursor: enabled ? "pointer" : "default"
        };
    };

    let element;
    if (icon && hoverIcon) {
        element = (
            <div className="paginator-page" onClick={handlePaginationClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={buttonStyle()}>
                <img className="paginator-page-icon" src={getButtonIcon()} alt="Arrow"/>
            </div>
        );
    } else {
        element = <div className="paginator-page" onClick={handlePaginationClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={buttonStyle()}>{name}</div>;
    }

    return element;
}

export default PaginatorPage;