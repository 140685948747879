import Storage from "./Storage";

export default class Viewport {
    static get dimensions() {
        const zoomLevel = Storage.getZoomLevel();
        const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        return {
            width: Math.round(width / zoomLevel),
            height: Math.round(height / zoomLevel)
        };
    };

    static get noZoomDimensions() {
        const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        return {
            width: width,
            height: height
        };
    };
}