import {ShoppingCart} from '../models/ShoppingCart';

export default class Storage {
    static getClient() {
        return localStorage.getItem('kiosk_client');
    };

    static setClient(instance) {
        localStorage.setItem('kiosk_client', instance);
    };

    static removeClient() {
        localStorage.removeItem('kiosk_client');
    };

    static getInstance() {
        return localStorage.getItem('kiosk_instance');
    };

    static setInstance(instance) {
        localStorage.setItem('kiosk_instance', instance);
    };

    static removeInstance() {
        localStorage.removeItem('kiosk_instance');
    };

    static getSession() {
        return localStorage.getItem('kiosk_session');
    };

    static setSession(session) {
        localStorage.setItem('kiosk_session', session);
    };

    static removeSession() {
        localStorage.removeItem('kiosk_session');
    };

    static getActionBarPosition() {
        let position = localStorage.getItem('kiosk_action_bar_position');
        if (position === undefined || position === null || position.length === 0) return undefined;
        return parseInt(position);
    };

    static setActionBarPosition(position) {
        localStorage.setItem('kiosk_action_bar_position', position);
    };

    static removeActionBarPosition() {
        localStorage.removeItem('kiosk_action_bar_position');
    };

    static getCategoryFilters() {
        const categoryFilters = localStorage.getItem('kiosk_category_filters');
        return categoryFilters ? JSON.parse(categoryFilters) : undefined;
    };

    static setCategoryFilters(categoryFilters) {
        localStorage.setItem('kiosk_category_filters', JSON.stringify(categoryFilters));
    };

    static removeCategoryFilters() {
        localStorage.removeItem('kiosk_category_filters');
    };

    static getFilters() {
        const filters = localStorage.getItem('kiosk_filters');
        return filters ? JSON.parse(filters) : undefined;
    };

    static setFilters(filters) {
        localStorage.setItem('kiosk_filters', JSON.stringify(filters));
    };

    static removeFilters() {
        localStorage.removeItem('kiosk_filters');
    };

    static getSorting() {
        return localStorage.getItem('kiosk_sorting');
    };

    static setSorting(sorting) {
        localStorage.setItem('kiosk_sorting', sorting);
    };

    static removeSorting() {
        localStorage.removeItem('kiosk_sorting');
    };

    static getPage() {
        const page = localStorage.getItem('kiosk_page');
        return page ? JSON.parse(page) : undefined;
    };

    static setPage(page) {
        localStorage.setItem('kiosk_page', JSON.stringify(page));
    };

    static removePage() {
        localStorage.removeItem('kiosk_page');
    };

    static getLastProduct() {
        return localStorage.getItem('kiosk_last_product');
    };

    static setLastProduct(productId) {
        localStorage.setItem('kiosk_last_product', productId);
    };

    static removeLastProduct() {
        localStorage.removeItem('kiosk_last_product');
    };

    static getAccessibilityMode() {
        return localStorage.getItem('kiosk_accessibility_mode') === 'true';
    };

    static setAccessibilityMode(accessibilityMode) {
        localStorage.setItem('kiosk_accessibility_mode', accessibilityMode);
    };

    static removeAccessibilityMode() {
        localStorage.removeItem('kiosk_accessibility_mode');
    };

    static getShoppingCart() {
        const data = localStorage.getItem('kiosk_shopping_cart');
        if (data) {
            return ShoppingCart.fromStorage(JSON.parse(data));
        } else return undefined;
    };

    static setShoppingCart(shoppingCart) {
        localStorage.setItem('kiosk_shopping_cart', JSON.stringify(shoppingCart.toStorage()));
    };

    static removeShoppingCart() {
        localStorage.removeItem('kiosk_shopping_cart');
    };

    static getZoomLevel() {
        try {
           let zoomLevel = localStorage.getItem('kiosk_zoom_level');
           return zoomLevel ? parseFloat(zoomLevel) : 1.0;
        } catch (error) {
            return 1.0;
        }
    };

    static setZoomLevel(zoomLevel) {
        localStorage.setItem('kiosk_zoom_level', zoomLevel);
    };

    static removeZoomLevel() {
        localStorage.removeItem('kiosk_zoom_level');
    };

    static clearAll() {
        this.removeClient();
        this.removeInstance();
        this.removeSession();
        this.removeActionBarPosition();
        this.removeCategoryFilters();
        this.removeFilters();
        this.removeSorting();
        this.removePage();
        this.removeLastProduct();
        this.removeShoppingCart();
        this.removeAccessibilityMode();
        this.removeZoomLevel();
    };

    static clearSession() {
        this.removeSession();
        this.removeActionBarPosition();
        this.removeCategoryFilters();
        this.removeFilters();
        this.removeSorting();
        this.removePage();
        this.removeLastProduct();
        this.removeShoppingCart();
        this.removeAccessibilityMode();
    };
}