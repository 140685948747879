import React from 'react';
import {SENTRY_ERROR_TESTING_MODE} from '../../config/App';
import './VersionTag.css';

function VersionTag({version}) {
    const handleClick = () => {
        if (SENTRY_ERROR_TESTING_MODE) {
            const randomMessage = Math.random().toString(36).substring(2);
            throw new Error(randomMessage);
        }
    };

    return (
        <div className='version-tag' onClick={handleClick}>v{version}</div>
    );
}

export default VersionTag;