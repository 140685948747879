import React, {useRef, useEffect, useState} from 'react';
import Modal from '../Modal/Modal';
import DataLoader from "../DataLoader/DataLoader";
import Viewport from '../../helpers/Viewport';
import Activity from '../../helpers/Activity';
import './SurveyModal.css';

function SurveyModal({surveyUrl, onClose, metadata}) {
    const iframeRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const viewport = Viewport.dimensions;
    const smallModeTreshold = {width: 900, height: 600};

    useEffect(() => {
        const iframe = iframeRef.current;
        iframe.onload = () => {
            setIsLoading(false);
            setError(false);
        };
        iframe.onerror = () => {
            setIsLoading(false);
            setError(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const inSmallMode = () => {
        return viewport.width <= smallModeTreshold.width || viewport.height <= smallModeTreshold.height;
    };

    const handleClose = (closeType) => {
        if (onClose) {
            Activity.log(metadata, 'survey-modal', closeType, 'close', undefined);
            onClose();
        }
    };

    const getCloseButtonPosition = () => {
        return inSmallMode() ? {top: 10, right: 10} : {top: -10, right: -10};
    };

    const iframeStyle = () => {
        return {
            opacity: isLoading || error ? 0 : 1
        };
    };

    return (
        <Modal
            mode='center'
            smallMode={inSmallMode()}
            backgroundColor='#FFFFFF'
            borderRadius={{topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50}}
            padding={0}
            width='90%'
            maxWidth='100%'
            height={650}
            maxHeight='100%'
            showDefaultCloseButton={true}
            customDefaultCloseButtonPosition={getCloseButtonPosition()}
            onClose={handleClose}
        >
            <div className='survey-modal'>
                {isLoading && (
                    <div className='survey-modal-loader'>
                        <DataLoader message='Cargando encuesta...'/>
                    </div>
                )}
                <iframe ref={iframeRef} title='survey-modal-iframe' src={surveyUrl} className='survey-modal-iframe' style={iframeStyle()}/>
                {error && (
                    <div className='survey-modal-error'>Lo sentimos, se ha producido un error al cargar la encuesta</div>
                )}
            </div>
        </Modal>
    );
}

export default SurveyModal;