import React, {useEffect, useState, useRef} from 'react';
import Viewport from '../../helpers/Viewport';
import HTMLElement from '../../helpers/HTMLElement';
import './Modal.css';

function Modal({mode, smallMode, backgroundColor, borderRadius, padding, width, minWidth, maxWidth, height, minHeight, maxHeight, showDefaultCloseButton, customDefaultCloseButtonPosition, onClose, children}) {
    const modalRef = useRef(null);
    const [isHovering, setIsHovering] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const viewport = Viewport.dimensions;

    useEffect(() => {
        setIsLoading(false);
        setTimeout(() => {
            setLoaded(true);
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getMode = () => {
        return mode === 'center' ? (viewport.height > 1500 ? 'semi-center' : 'center') : mode;
    };

    const handleBlockingPanelClick = (event) => {
        if (event.target && event.target.className === 'modal-blocking-panel') handleClose('external-click');
    };

    const handleCloseButtonMouseEnter = () => {
        setIsHovering(true);
    };

    const handleCloseButtonMouseLeave = () => {
        setIsHovering(false);
    };

    const handleClose = (closeType) => {
        if (onClose) onClose(closeType);
    };

    const blockingPanelStyle = () => {
        return {
            opacity: isLoading ? 0 : 1
        };
    };

    const modalStyle = () => {
        const viewportWidth = Viewport.dimensions.width;
        const viewportHeight = Viewport.dimensions.height;
        const modalWidth = modalRef.current ? HTMLElement.getDisplayProperties(modalRef.current).width : 0;
        const modalHeight = modalRef.current ? HTMLElement.getDisplayProperties(modalRef.current).height : 0;
        const hasMaxWidth = viewportWidth <= modalWidth;
        const hasMaxHeight = viewportHeight <= modalHeight;
        const anyMaxSize = hasMaxWidth || hasMaxHeight;
        let style = {
            background: backgroundColor ? backgroundColor : '#FFFFFF',
            padding: padding !== undefined ? padding : 20,
            transformOrigin: '0 0'
        };
        if (smallMode) {
            style.padding = 0;
            style.height = '100%';
            style.minWidth = '100%';
            style.maxWidth = '100%';
            style.minHeight = '100%';
            style.maxHeight = '100%';
            style.top = 0;
            style.left = 0;
            style.borderTopLeftRadius = 0;
            style.borderTopRightRadius = 0;
            style.borderBottomLeftRadius = 0;
            style.borderBottomRightRadius = 0;
            style.transition = 'background 0.5s ease';
        } else {
            switch(mode) {
                case 'top':
                    style.height = height || '50%';
                    style.minHeight = minHeight || 'unset';
                    style.maxHeight = maxHeight || 'unset';
                    style.top = isLoading ? -1000 : 0;
                    style.borderBottomLeftRadius = !hasMaxHeight && borderRadius && borderRadius.bottomLeft ? borderRadius.bottomLeft : 0;
                    style.borderBottomRightRadius = !hasMaxHeight && borderRadius && borderRadius.bottomRight ? borderRadius.bottomRight : 0;
                    style.transition = loaded ? 'background 0.5s ease' : 'background 0.5s ease, top 0.5s ease';
                    break;
                case 'right':
                    style.width = width || '20%';
                    style.minWidth = minWidth || 'unset';
                    style.maxWidth = maxWidth || 'unset';
                    style.right = isLoading ? -1000 : 0;
                    style.borderTopLeftRadius = !hasMaxWidth && borderRadius && borderRadius.topLeft ? borderRadius.topLeft : 0;
                    style.borderBottomLeftRadius = !hasMaxWidth && borderRadius && borderRadius.bottomLeft ? borderRadius.bottomLeft : 0;
                    style.transition = loaded ? 'background 0.5s ease' : 'background 0.5s ease, right 0.5s ease';
                    break;
                case 'bottom':
                    style.height = height || '50%';
                    style.minHeight = minHeight || 'unset';
                    style.maxHeight = maxHeight || 'unset';
                    style.bottom = isLoading ? -1000 : 0;
                    style.borderTopLeftRadius = !hasMaxHeight && borderRadius && borderRadius.topLeft ? borderRadius.topLeft : 0;
                    style.borderTopRightRadius = !hasMaxHeight && borderRadius && borderRadius.topRight ? borderRadius.topRight : 0;
                    style.transition = loaded ? 'background 0.5s ease' : 'background 0.5s ease, bottom 0.5s ease';
                    break;
                case 'left':
                    style.width = width || '20%';
                    style.minWidth = minWidth || 'unset';
                    style.maxWidth = maxWidth || 'unset';
                    style.left = isLoading ? -1000 : 0;
                    style.borderTopRightRadius = !hasMaxWidth && borderRadius && borderRadius.topRight ? borderRadius.topRight : 0;
                    style.borderBottomRightRadius = !hasMaxWidth && borderRadius && borderRadius.bottomRight ? borderRadius.bottomRight : 0;
                    style.transition = loaded ? 'background 0.5s ease' : 'background 0.5s ease, left 0.5s ease';
                    break;
                case 'center':
                    style.width = width || '50%';
                    style.minWidth = minWidth || 'unset';
                    style.maxWidth = maxWidth || 'unset';
                    style.height = height || '50%';
                    style.minHeight = minHeight || 'unset';
                    style.maxHeight = maxHeight || 'unset';
                    style.transform = `scale(${isLoading? 0 : 1}) translate(-50%, -50%)`;
                    style.borderTopLeftRadius = !anyMaxSize && borderRadius && borderRadius.topLeft ? borderRadius.topLeft : 0;
                    style.borderTopRightRadius = !anyMaxSize && borderRadius && borderRadius.topRight ? borderRadius.topRight : 0;
                    style.borderBottomLeftRadius = !anyMaxSize && borderRadius && borderRadius.bottomLeft ? borderRadius.bottomLeft : 0;
                    style.borderBottomRightRadius = !anyMaxSize && borderRadius && borderRadius.bottomRight ? borderRadius.bottomRight : 0;
                    style.transition = loaded ? 'background 0.5s ease' : 'background 0.5s ease, transform 0.5s ease';
                    break;
                default:
                    break;
            }
        }
        return style;
    };

    const closeButtonStyle = () => {
        let style = {
            filter: isHovering ? 'brightness(85%)' : 'unset'
        };
        if (customDefaultCloseButtonPosition) {
            style.top = customDefaultCloseButtonPosition.top || 'unset';
            style.right = customDefaultCloseButtonPosition.right || 'unset';
            style.bottom = customDefaultCloseButtonPosition.bottom || 'unset';
            style.left = customDefaultCloseButtonPosition.left || 'unset';
            style.transformOrigin = '50% 50%';
            style.transform = `scale(${isHovering ? 1.1 : 1})`;
        } else {
            if (smallMode) {
                style.top = 10;
                style.right = 10;
                style.bottom = 'unset';
                style.left = 'unset';
                style.transformOrigin = '50% 50%';
                style.transform = `scale(${isHovering ? 1.1 : 1})`;
            } else {
                switch(mode) {
                    case 'top':
                        style.bottom = -60;
                        style.left = '50%';
                        style.transformOrigin = 0;
                        style.transform = isHovering ? 'scale(1.1) translateX(-50%)' : 'translateX(-50%)';
                        break;
                    case 'right':
                        style.top = '50%';
                        style.right = 'calc(100% + 10px)';
                        style.transformOrigin = '50% 0';
                        style.transform = isHovering ? 'scale(1.1) translateY(-50%)' : 'translateY(-50%)';
                        break;
                    case 'bottom':
                        style.top = -60;
                        style.left = '50%';
                        style.transformOrigin = 0;
                        style.transform = isHovering ? 'scale(1.1) translateX(-50%)' : 'translateX(-50%)';
                        break;
                    case 'left':
                        style.top = '50%';
                        style.left = 'calc(100% + 10px)';
                        style.transformOrigin = '50% 0';
                        style.transform = isHovering ? 'scale(1.1) translateY(-50%)' : 'translateY(-50%)';
                        break;
                    case 'center':
                        style.bottom = -60;
                        style.left = '50%';
                        style.transformOrigin = 0;
                        style.transform = isHovering ? 'scale(1.1) translateX(-50%)' : 'translateX(-50%)';
                        break;
                    default:
                        break;
                }
            }
        }
        return style;
    };

    return (
        <div className='modal-blocking-panel' style={blockingPanelStyle()} onClick={handleBlockingPanelClick}>
            <div ref={modalRef} className={`modal-container ${getMode()}`} style={modalStyle()}>
                {showDefaultCloseButton && (
                    <div className='modal-close-button' onMouseEnter={handleCloseButtonMouseEnter} onMouseLeave={handleCloseButtonMouseLeave} onClick={() => handleClose('close-button')} style={closeButtonStyle()}>
                        <span className='modal-close-button-icon'>x</span>
                    </div>
                )}
                <div className='modal-content'>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Modal;