import React from 'react';
import PaymentOption from '../PaymentOptionSelection/PaymentOption/PaymentOption';
import DateTime from "../../../../helpers/DateTime";
import './WaitingPayment.css';

function WaitingPayment({
    title1,
    title2,
    titleLineBreak,
    cardOptionImage,
    remainingTime
}) {
    return (
        <div className='waiting-payment'>
            <p className='waiting-payment-title'>
                {title1}
                {titleLineBreak && <br/>}
                <span className='bold'>{title2}</span>
            </p>
            <div className='waiting-payment-content'>
                <PaymentOption
                    key='waiting-payment'
                    type='waiting-payment'
                    icon={cardOptionImage}
                    label1={DateTime.secondsToTimer(remainingTime)}
                    label2='Esperando pago...'
                    boldLabel1={true}
                    boldLabel2={false}
                    labelLineBreak={true}
                    waitingMode={false}
                    waitingModeIcon={undefined}
                    waitingModeLabel={undefined}
                    onClick={undefined}
                />
            </div>
        </div>
    );
}

export default WaitingPayment;