import React from 'react';
import Modal from '../Modal/Modal';
import DataError from '../DataError/DataError';
import Viewport from '../../helpers/Viewport';
import Activity from '../../helpers/Activity';
import './OnRequestProductModal.css';

function OnRequestProductModal({product, variant, cancelButtonBackgroundColor, cancelButtonBorderColor, cancelButtonLabelColor, onClose, metadata}) {
    const viewport = Viewport.dimensions;
    const smallModeTreshold = {width: 768, height: 600};

    const inSmallMode = () => {
        return viewport.width <= smallModeTreshold.width || viewport.height <= smallModeTreshold.height;
    };

    const handleClose = (closeType) => {
        Activity.log(metadata, 'on-request-product-modal', closeType, 'close', {product_id: product.id, product_name: product.name, variant_id: variant ? variant.id : null, variant_name: variant ? variant.name : null});
        if (onClose) onClose();
    };

    return (
        <Modal mode='center' smallMode={inSmallMode()} backgroundColor='#FFFFFF' borderRadius={{topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50}} padding={40} width={800} maxWidth='100%' height='auto' maxHeight='100%' showDefaultCloseButton={false} onClose={handleClose}>
            <div className='on-request-product-modal'>
                <DataError
                    message='Este producto sólo está disponible a pedido, por favor contacte a nuestro equipo de ventas para más información.'
                    actionLabel='Cerrar'
                    action={() => handleClose('custom-close-button')}
                    actionButtonLabelColor={cancelButtonLabelColor}
                    actionButtonBackgroundColor={cancelButtonBackgroundColor}
                    actionButtonBorderColor={cancelButtonBorderColor}
                    maxWidth={500}
                />
            </div>
        </Modal>
    );
}

export default OnRequestProductModal;