import React from 'react';
import CrossLoader from '../CrossLoader/CrossLoader';
import './DataLoader.css';

function DataLoader({message}) {
    return (
        <div className='data-loader'>
            <div className='data-loader-spinner'>
                <CrossLoader size={200}/>
            </div>
            <p className='data-loader-message'>{message}</p>
        </div>
    )
}

export default DataLoader;