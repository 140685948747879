export default class ObjectAddons {
    static clone(object) {
        if (object === null || object === undefined || typeof object !== 'object') {
            return object;
        }
        if (object instanceof Array) {
            let arrayClone = [];
            for (let i = 0; i < object.length; ++i) {
                arrayClone[i] = ObjectAddons.clone(object[i]);
            }
            return arrayClone;
        }
        let objectClone = {};
        for (let key in object) {
            if (object.hasOwnProperty(key)) {
                objectClone[key] = ObjectAddons.clone(object[key]);
            }
        }
        return objectClone;
    };
}