import React from 'react';
import {useNavigate} from 'react-router-dom';
import MainLogo from "../../components/MainLogo/MainLogo";
import Button from '../../components/Button/Button';
import Navigation from '../../config/Navigation';
import './NotFound.css';

function NotFound({message, actionLabel, action, style}) {
    const navigate = useNavigate();

    const getMessage = () => {
        return message || 'Página no encontrada';
    };

    const getActionLabel = () => {
        return actionLabel || 'Volver al inicio';
    };

    const getAction = () => {
        return action || goToHome;
    };

    const goToHome = () => {
        const url = Navigation.getHomeUrl();
        navigate(url);
    };

    const mainLogo = style && style.global && style.global['mainClientLogo'] ? style.global['mainClientLogo'] : undefined;
    const buttonBackgroundColor = style && style.global && style.global['actionButton'] && style.global['actionButton']['backgroundColor'] ? style.global['actionButton']['backgroundColor'] : undefined;
    const buttonLabelColor = style && style.global && style.global['actionButton'] && style.global['actionButton']['labelColor'] ? style.global['actionButton']['labelColor'] : undefined;
    const buttonBorderColor = style && style.global && style.global['actionButton'] && style.global['actionButton']['borderColor'] ? style.global['actionButton']['borderColor'] : undefined;

    return (
        <div className='not-found'>
            <div className='not-found-wrapper'>
                <div className='not-found-client-logo-wrapper'>
                    <MainLogo
                        backgroundColor='transparent'
                        backgroundTopPadding={20}
                        backgroundRightPadding={20}
                        backgroundBottomPadding={20}
                        backgroundLeftPadding={20}
                        backgroundWidth='100%'
                        image={mainLogo}
                        imageAlign='center'
                        imageWidth={undefined}
                        imageHeight={67}
                    />
                </div>
                <h1 className='not-found-title'>404</h1>
                <h2 className='not-found-description'>{getMessage()}</h2>
                <div className='not-found-action'>
                    <Button
                        label={getActionLabel()}
                        labelColor={buttonLabelColor}
                        backgroundColor={buttonBackgroundColor}
                        borderColor={buttonBorderColor}
                        minWidth='auto'
                        maxWidth='100%'
                        boxShadow={undefined}
                        onClick={getAction()}
                    />
                </div>
            </div>
        </div>
    );
}

export default NotFound;