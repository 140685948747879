import * as Sentry from '@sentry/react';
import appData from '../../package.json';
import Environment from './Environment';
import {SENTRY_ENABLED_ENVIRONMENTS} from './App';

export const SENTRY_CONFIG = {
    enabled: SENTRY_ENABLED_ENVIRONMENTS.includes(Environment.current),
    release: `${appData.name}@${appData.version}`,
    environment: Environment.current,
    dsn: 'https://2b940946ee416e64c8cc174562dcb8cf@o4505567923404800.ingest.sentry.io/4506509255311360',
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: ['localhost', 'https://kiosk-3d971--development-m0z0mtxd.web.app/', 'https://kiosk-3d971--staging-6ieics0b.web.app/', 'https://kiosco.motiondisplays.com'],
        }),
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
};

export const setTags = (tags) => {
    if (SENTRY_CONFIG.enabled) {
        tags.forEach(tag => Sentry.setTag(tag.key, tag.value));
    }
};