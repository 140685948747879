import React from 'react';
import ImageWrapper from "../../ImageWrapper/ImageWrapper";
import './ProductTag.css';

function ProductTag({icon, label, labelColor, backgroundColor, borderColor, margin}) {
    const tagStyle = () => {
        return {
            background: backgroundColor || '#005B80',
            borderColor: borderColor || backgroundColor || '#005B80',
            margin: margin !== undefined ? margin : 5
        };
    };

    const labelStyle = () => {
        return {
            color: labelColor || '#FFFFFF'
        };
    };

    return (
        <div className='product-tag' style={tagStyle()}>
            {icon && (
                <div className='product-tag-icon'>
                    <ImageWrapper image={icon} alt={label} display='block' width={20} height='auto' loadDelay={0.2}/>
                </div>
            )}
            <div className='product-tag-label' style={labelStyle()}>{label}</div>
        </div>
    );
}

export default ProductTag;