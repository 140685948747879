export default class Measure {
    static get default() {
        return {
            prefix: '',
            thousandsSeparator: '.',
            decimalSeparator: ',',
            decimalScale: 0,
            suffix: ''
        };
    };
}