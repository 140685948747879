import React, {useState} from 'react';
import Slider from '../../Slider/Slider';
import CurrencyFormat from 'react-currency-format';
import Measure from '../../../config/Measure';
import Activity from "../../../helpers/Activity";
import './SizeFilter.css';

function SizeFilter({category, searchQuery, filterId, optionId, filterLabel, optionLabel, value, min, max, step, unit, textColor, sliderColor, onChange, metadata}) {
    const [renderedValue, setRenderedValue] = useState(value);
    const measureFormat = Measure.default;

    const handleChange = (value) => {
        setRenderedValue(value);
    };

    const handleCommit = (value) => {
        if (onChange) onChange(filterId, optionId, value);
    };

    const handleRelease = (value) => {
        Activity.log(metadata, 'filter-option', optionId, 'slide', {category_id: category.id, category_name: category.name, search_query: searchQuery || null, filter_id: filterId, option_id: optionId, filter_label: filterLabel, option_label: optionLabel, value: value, unit: unit});
    };

    const labelStyle = () => ({
        color: textColor || '#333333'
    });

    if (min >= 0 && max >= 0 && min < max) {
        const validMin = Math.floor(min);
        const validMax = Math.ceil(max);
        const validValue = value >= 0 ? Math.floor(value) : validMin;
        const validRenderedValue = renderedValue >= 0 ? renderedValue : validMin;
        const validStep = step > 0 ? step : 1;
        return (
            <div className='size-filter'>
                <div className='size-filter-header'>
                    <p className='size-filter-label' style={labelStyle()}>{optionLabel}</p>
                    <p className='size-filter-value'>
                        <CurrencyFormat
                            prefix={measureFormat.prefix}
                            suffix={` ${unit}`}
                            thousandSeparator={measureFormat.thousandsSeparator}
                            decimalSeparator={measureFormat.decimalSeparator}
                            decimalScale={measureFormat.decimalScale}
                            value={validRenderedValue}
                            displayType='text'
                        />
                    </p>
                </div>
                <div className='size-filter-slider'>
                    <Slider
                        initialValue={validValue}
                        min={validMin}
                        max={validMax}
                        step={validStep}
                        highlightColor={sliderColor}
                        onChange={handleChange}
                        onCommit={handleCommit}
                        onRelease={handleRelease}
                    />
                </div>
            </div>
        );
    } else return undefined;
}

export default SizeFilter;