// noinspection JSUnusedGlobalSymbols,JSUnresolvedReference

import {firestore, getCollectionPath} from '../config/Firebase';
import {doc, updateDoc, Timestamp} from 'firebase/firestore';

export class Session {
    constructor({id, client, instance, catalog, store, active, startedAt, finishedAt, lastPing}) {
        this.id = id;
        this.client = client;
        this.instance = instance;
        this.catalog = catalog;
        this.store = store;
        this.active = active;
        this.startedAt = startedAt;
        this.finishedAt = finishedAt;
        this.lastPing = lastPing;
    };

    updateStatus(active) {
        if (this.id) {
            const _path = getCollectionPath(this.client, 'sessions');
            const _doc = doc(firestore, _path, this.id);
            const _data = {active: active};
            if (active) _data.last_ping = Timestamp.now();
            if (!active) _data.finished_at = Timestamp.now();
            updateDoc(_doc, _data);
        }
    };

    setActive() {
        this.updateStatus(true);
    };

    setInactive() {
        this.updateStatus(false);
    };

    clone() {
        return new Session({
            id: this.id,
            client: this.client,
            instance: this.instance,
            catalog: this.catalog,
            store: this.store,
            active: this.active,
            startedAt: this.startedAt,
            finishedAt: this.finishedAt,
            lastPing: this.lastPing
        });
    };
}

export const sessionConverter = {
    toFirestore() {
        return null
    },
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return new Session({
            id: snapshot.id,
            client: data.client,
            instance: data.instance,
            catalog: data.catalog,
            store: data.store,
            active: data.active,
            startedAt: data.started_at,
            finishedAt: data.finished_at,
            lastPing: data.last_ping
        });
    }
};