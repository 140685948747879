import React, {useState, useRef, useEffect} from 'react';
import Button from '../Button/Button';
import HTMLElement from '../../helpers/HTMLElement';
import tutorialImage from './images/tutorial.gif';
import './RangeSlider.css';

function RangeSlider({initialStartValue, initialEndValue, min, max, step, buttonsStep, highlightColor, onChange, onCommit, onRelease}) {
    const [startValue, setStartValue] = useState(initialStartValue);
    const [endValue, setEndValue] = useState(initialEndValue);
    const [showTutorial, setShowTutorial] = useState(true);
    const [showButtons, setShowButtons] = useState(undefined);
    const [_timeout, _setTimeout] = useState(null);
    const containerRef = useRef(null);
    const lowerRef = useRef(null);
    const upperRef = useRef(null);
    const lowerTutorialRef = useRef(null);
    const upperTutorialRef = useRef(null);
    const buttonsRef = useRef(null);

    useEffect(() => {
        addResizeListener();
        addOutsideClickListener();
        return () => {
            removeResizeListener();
            removeOutsideClickListener();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (initialStartValue !== startValue) setStartValue(initialStartValue);
        if (initialEndValue !== endValue) setEndValue(initialEndValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialStartValue, initialEndValue]);

    useEffect(() => {
        if (showButtons) setButtonsPosition();
        else setTutorialsPosition();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showButtons, startValue, endValue]);

    const addResizeListener = () => {
        window.addEventListener('resize', handleResize);
    };

    const removeResizeListener = () => {
        window.removeEventListener('resize', handleResize);
    };

    const handleResize = () => {
        setShowButtons(undefined);
        setTutorialsPosition();
    };

    const addOutsideClickListener = () => {
        window.addEventListener('click', handleOutsideClick);
    };

    const removeOutsideClickListener = () => {
        window.removeEventListener('click', handleOutsideClick);
    };

    const handleOutsideClick = (event) => {
        const lowerSliderClicked = lowerRef.current.contains(event.target);
        const upperSliderClicked = upperRef.current.contains(event.target);
        const buttonClicked = buttonsRef.current.contains(event.target);
        if (!lowerSliderClicked && !upperSliderClicked && !buttonClicked) setShowButtons(undefined);
    };

    const setTutorialsPosition = () => {
        const lowerTutorialPositionX = calculateSliderPosition('lower', lowerTutorialRef.current);
        const upperTutorialPositionX = calculateSliderPosition('upper', upperTutorialRef.current);
        if (lowerTutorialRef && lowerTutorialRef.current) lowerTutorialRef.current.style.left = `${lowerTutorialPositionX}px`;
        if (upperTutorialRef && upperTutorialRef.current) upperTutorialRef.current.style.left = `${upperTutorialPositionX}px`;
    };

    const setButtonsPosition = () => {
        const buttonsPositionX = calculateSliderPosition(showButtons, buttonsRef.current);
        if (buttonsRef && buttonsRef.current) buttonsRef.current.style.left = `${buttonsPositionX}px`;
    };

    const calculateSliderPosition = (slider, targetElement) => {
        const sliderElement = slider === 'lower' ? lowerRef.current : upperRef.current;
        if (!sliderElement || !targetElement || !buttonsRef.current) return 0;
        const sourceValue = slider === 'lower' ? startValue : endValue;
        const percent = (sourceValue - min) / (max - min);
        const targetWidth = HTMLElement.getDisplayProperties(targetElement).width;
        const sliderWidth = HTMLElement.getDisplayProperties(sliderElement).width;
        const thumbWidth = 40;
        return ((sliderWidth - thumbWidth) * percent) - (targetWidth / 2) + (thumbWidth / 2);
    };

    const disableTutorial = () => {
        if (showTutorial) setShowTutorial(false);
    };

    const handleLowerValueClick = () => {
        disableTutorial();
        if (showButtons !== 'lower') setShowButtons('lower');
    };

    const handleUpperValueClick = () => {
        disableTutorial();
        if (showButtons !== 'upper') setShowButtons('upper');
    };

    const handleLowerValueChange = () => {
        disableTutorial();
        const values = getValues('lower');
        handleValueChange(values.lower, values.upper);
    };

    const handleUpperValueChange = () => {
        disableTutorial();
        const values = getValues('upper');
        handleValueChange(values.lower, values.upper);
    };

    const handleValueChange = (lowerValue, upperValue) => {
        setStartValue(lowerValue);
        setEndValue(upperValue);
        if (onChange) onChange(lowerValue, upperValue);
        if (_timeout) clearTimeout(_timeout);
        _setTimeout(setTimeout(() => {
            if (onCommit) onCommit(lowerValue, upperValue);
        }, 100));
    };

    const handleLowerValueRelease = () => {
        disableTutorial();
        const values = getValues('lower');
        handleRelease(values.lower, values.upper);
    };

    const handleUpperValueRelease = () => {
        disableTutorial();
        const values = getValues('upper');
        handleRelease(values.lower, values.upper);
    };

    const handleButtonClick = (action) => {
        const source = showButtons === 'lower' ? lowerRef.current : upperRef.current;
        if (action === 'decrease') source.value = parseInt(source.value) - buttonsStep;
        if (action === 'increase') source.value = parseInt(source.value) + buttonsStep;
        const values = getValues(showButtons);
        handleValueChange(values.lower, values.upper);
    };

    const handleRelease = (lowerValue, upperValue) => {
        if (onRelease) onRelease(lowerValue, upperValue);
    };

    const getValues = (input) => {
        let lowerValue = parseInt(lowerRef.current.value);
        let upperValue = parseInt(upperRef.current.value);
        if (input === 'lower') upperValue = upperValue < lowerValue ? lowerValue : upperValue;
        if (input === 'upper') lowerValue = lowerValue > upperValue ? upperValue : lowerValue;
        return {lower: lowerValue, upper: upperValue};
    };

    const segmentStyle = () => {
        const color = highlightColor || '#FDBB31';
        const startPercent = Math.round((max - min) > 0 ? (((startValue - min) * 100) / (max - min)) : 0);
        const endPercent = Math.round((max - min) > 0 ? (((endValue - min) * 100) / (max - min)) : 0);
        return {
            background: `linear-gradient(to right, #EDEDED 0%, #EDEDED ${startPercent}%, ${color} ${startPercent}%, ${color} ${endPercent}%, #EDEDED ${endPercent}%, #EDEDED 100%)`
        };
    };

    const buttonsStyle = () => {
        return {
            opacity: showButtons ? 1 : 0
        };
    };

    return (
        <div ref={containerRef} className='range-slider'>
            <input ref={lowerRef} className='range-slider-input lower' type='range' min={min} max={max} step={step} value={startValue} onMouseDown={handleLowerValueClick} onTouchStart={handleLowerValueClick} onMouseUp={handleLowerValueRelease} onTouchEnd={handleLowerValueRelease} onChange={handleLowerValueChange} style={segmentStyle()}/>
            <input ref={upperRef} className='range-slider-input upper' type='range' min={min} max={max} step={step} value={endValue} onMouseDown={handleUpperValueClick} onTouchStart={handleUpperValueClick} onMouseUp={handleUpperValueRelease} onTouchEnd={handleUpperValueRelease} onChange={handleUpperValueChange}/>
            {showTutorial && (
                <React.Fragment>
                    <div ref={lowerTutorialRef} className='range-slider-tutorial'>
                        <img className='range-slider-tutorial-image' src={`${tutorialImage}`} alt='Lower Tutorial'/>
                    </div>
                    <div ref={upperTutorialRef} className='range-slider-tutorial'>
                        <img className='range-slider-tutorial-image' src={`${tutorialImage}`} alt='Upper Tutorial'/>
                    </div>
                </React.Fragment>
            )}
            <div ref={buttonsRef} className='range-slider-buttons' style={buttonsStyle()}>
                <Button
                    label='-'
                    labelColor='#DFDFDF'
                    backgroundColor='#FFFFFF'
                    borderWidth='0'
                    borderRadius='10px'
                    padding='0'
                    margin='0 5px 0 0'
                    width={63}
                    minWidth={32}
                    height={32}
                    boxShadow='0 0 5px rgba(0, 0, 0, 0.207878)'
                    isLoading={false}
                    disabled={false}
                    showPulseEffect={false}
                    onClick={() => {handleButtonClick("decrease")}}
                />
                <Button
                    label='+'
                    labelColor='#DFDFDF'
                    backgroundColor='#FFFFFF'
                    borderWidth='0'
                    borderRadius='10px'
                    padding='0'
                    margin='0'
                    width={32}
                    minWidth={32}
                    height={32}
                    boxShadow='0 0 5px rgba(0, 0, 0, 0.207878)'
                    isLoading={false}
                    disabled={false}
                    showPulseEffect={false}
                    onClick={() => {handleButtonClick("increase")}}
                />
            </div>
        </div>
    );
}

export default RangeSlider;