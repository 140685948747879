// noinspection JSUnusedGlobalSymbols,JSUnresolvedReference

import Text from '../helpers/Text';

export class Product {
    constructor({
        parent,
        id,
        sku,
        ean,
        name,
        brand,
        description,
        longDescription,
        isFeatured,
        categories,
        categoryPaths,
        multimedia,
        createdAt,
        updatedAt,
        originUpdatedAt,
        normalPrice,
        offerPrice,
        exclusivePrice,
        sellerName,
        externalSale,
        externalSaleUrl,
        clientDelivery,
        internationalShipping,
        onRequest,
        maxPerItem,
        has3dModel,
        viewItSceneUrl,
        viewItArUrl,
        delivery,
        freeDelivery,
        pickup,
        features,
        measures,
        variants,
        variantsMatches,
        order,
        score
    }) {
        this.parent = parent;
        this.id = id;
        this.sku = sku;
        this.ean = ean;
        this.name = name;
        this.brand = brand;
        this.description = description;
        this.longDescription = longDescription;
        this.isFeatured = isFeatured;
        this.categories = categories;
        this.categoryPaths = categoryPaths;
        this.multimedia = multimedia;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.originUpdatedAt = originUpdatedAt;
        this.normalPrice = normalPrice;
        this.offerPrice = offerPrice;
        this.exclusivePrice = exclusivePrice;
        this.sellerName = sellerName;
        this.externalSale = externalSale;
        this.externalSaleUrl = externalSaleUrl;
        this.clientDelivery = clientDelivery;
        this.internationalShipping = internationalShipping;
        this.onRequest = onRequest;
        this.maxPerItem = maxPerItem || 0;
        this.has3dModel = has3dModel;
        this.viewItSceneUrl = viewItSceneUrl;
        this.viewItArUrl = viewItArUrl;
        this.delivery = delivery;
        this.freeDelivery = freeDelivery;
        this.pickup = pickup;
        this.features = features;
        this.measures = measures;
        this.variants = variants;
        this.variantsMatches = variantsMatches;
        this.order = order;
        this.score = score;
    };

    getMainImage(size) {
        return this.multimedia && this.multimedia.mainImage && this.multimedia.mainImage[size] ? this.multimedia.mainImage[size] : undefined;
    };

    getMainCategory() {
        let categoryId;
        try {
            categoryId = this.categoryPaths && this.categoryPaths.length > 0 ? this.categoryPaths[0][1] : undefined;
        } catch (error) {
            console.error(error);
            categoryId = undefined;
        }
        return categoryId;
    };

    getOfferDiscountPercentage() {
        return Math.round((this.normalPrice || 0) > 0 && this.offerPrice ? 100 - (((this.offerPrice || 0) * 100) / this.normalPrice) : 0);
    };

    getExclusiveDiscountPercentage() {
        return Math.round((this.normalPrice || 0) > 0 && this.exclusivePrice ? 100 - (((this.exclusivePrice || 0) * 100) / this.normalPrice) : 0);
    };

    indexableFeatures() {
        return this.features.filter(feature => feature.indexable);
    };

    visibleFeatures() {
        return this.features.filter(feature => feature.visible);
    };

    getSortedFeatures() {
        return this.visibleFeatures().sort((a, b) => a.order - b.order);
    };

    minPrice() {
        return this.exclusivePrice || this.offerPrice || this.normalPrice;
    };

    maxPrice() {
        return this.normalPrice || this.offerPrice || this.exclusivePrice;
    };

    maxDiscount() {
        const offerDiscount = this.getOfferDiscountPercentage();
        const exclusiveDiscount = this.getExclusiveDiscountPercentage();
        return Math.max(offerDiscount, exclusiveDiscount);
    };

    hasStock() {
        return this.maxPerItem > 0;
    };

    hasAnyPrice() {
        return !!this.normalPrice || !!this.offerPrice || !!this.exclusivePrice;
    };

    isValid() {
        return this.hasStock() && this.hasAnyPrice();
    };

    size() {
        let size = {width: undefined, height: undefined, depth: undefined, unit: undefined};
        if (this.measures && this.measures.length > 0) {
            const widthMeasure  = this.measures.find(measure => measure.name === 'width');
            const heightMeasure = this.measures.find(measure => measure.name === 'height');
            const depthMeasure  = this.measures.find(measure => measure.name === 'length');
            if (widthMeasure)  {
                size.width = widthMeasure.value;
                if (!size.unit) size.unit = widthMeasure.unit;
            }
            if (heightMeasure) {
                size.height = heightMeasure.value;
                if (!size.unit) size.unit = heightMeasure.unit;
            }
            if (depthMeasure) {
                size.depth = depthMeasure.value;
                if (!size.unit) size.unit = depthMeasure.unit;
            }
        }
        return size;
    };

    static filterByCategories = (products, categories) => {
        if (categories.length > 0) {
            return products.filter(product => {
                const productCategories = product.categories;
                const match = productCategories.find(productCategory => categories.includes(productCategory));
                return match !== undefined;
            });
        } else return [];
    };

    static filterByFilters = (products, filters, showExternalSale, externalSaleLabel, clientName) => {
        const resultsByFilter = [];
        if (filters.length > 0) {
            filters.forEach((filter) => {
                let results = [];
                if (filter.type === 'brands') results = this.filterByBrands(products, filter);
                if (filter.type === 'size')   results = this.filterBySize(products, filter);
                if (filter.type === 'price')  results = this.filterByPrice(products, filter);
                if (filter.type === 'external-sale' && showExternalSale) results = this.filterByExternalSale(products, filter, externalSaleLabel, clientName);
                if (filter.type === 'normal') results = this.filterByFeatures(products, filter);
                resultsByFilter.push({id: filter.id, label: filter.label, type: filter.type, results: results});
            });
        }
        let results = [...products];
        resultsByFilter.forEach((byFilter) => {
            results = results.filter(product => byFilter.results.includes(product));
        });
        return results;
    };

    static filterByBrands = (products, filter) => {
        const brandsValues = filter.options.map(option => Text.minify(option.label));
        return products.filter(product => {
            const brand = Text.minify(product.brand);
            return brandsValues.includes(brand);
        });
    };

    static filterBySize = (products, filter) => {
        let results = products;
        ['width-range', 'height-range', 'depth-range'].forEach(id => {
            const option = filter.options.find(option => option.id === id);
            const min = option ? option.min : undefined;
            const value = option ? option.value : undefined;
            if (min && value) {
                const inRange = products.filter(product => {
                    const type = id.split('-')[0];
                    const size = product.size();
                    const measure = size[type];
                    return measure >= min && measure <= value;
                });
                results = results.filter(product => inRange.includes(product));
            }
        });
        return results;
    };

    static filterByPrice = (products, filter) => {
        const option = filter.options[0];
        const startValue = option && option.startValue ? option.startValue : undefined;
        const endValue = option && option.endValue ? option.endValue : undefined;
        if (startValue === undefined || endValue === undefined) return [];
        return products.filter(product => {
            const minPrice = product.minPrice();
            const maxPrice = product.maxPrice();
            const minPriceInRange = minPrice >= startValue && minPrice <= endValue;
            const maxPriceInRange = maxPrice >= startValue && maxPrice <= endValue;
            return minPriceInRange || maxPriceInRange;
        });
    };

    static filterByExternalSale = (products, filter, externalSaleLabel, clientName) => {
        const externalSaleValues = filter.options.map(option => Text.minify(option.label));
        return products.filter(product => {
            const externalSale = Text.minify(product.externalSale ? externalSaleLabel : clientName);
            return externalSaleValues.includes(externalSale);
        });
    };

    static filterByFeatures = (products, filter) => {
        const valuesMap = filter.options.map(option => ({name: filter.id, value: option.id}));
        return products.filter(product => {
            return !!product.indexableFeatures().find(feature => {
                const featureNameHash = Text.toHash(feature.name);
                const featureValueHash = Text.toHash(feature.value);
                return !!valuesMap.find(valuesMapItem => valuesMapItem.name === featureNameHash && valuesMapItem.value === featureValueHash);
            });
        });
    };

    static filterValidProducts = (products) => {
        return products && products.length > 0 ? (products.filter(product => product.isValid())) : [];
    };

    static sortByOrder = (products) => {
        return products.sort((p1, p2) => {
            const order1 = p1.order || Number.MAX_SAFE_INTEGER;
            const order2 = p2.order || Number.MAX_SAFE_INTEGER;
            return order1 - order2;
        });
    };

    static sortByHighestPrice = (products) => {
        return products.sort((p1, p2) => p2.minPrice() - p1.minPrice());
    };

    static sortByLowestPrice = (products) => {
        return products.sort((p1, p2) => p1.minPrice() - p2.minPrice());
    };

    static sortByFeatured = (products) => {
        let featured = products.filter(product => product.isFeatured);
        featured = Product.sortByOrder(featured);
        let notFeatured = products.filter(product => !product.isFeatured);
        notFeatured = Product.sortByOrder(notFeatured);
        return featured.concat(notFeatured);
    };

    static sortBy3dModel = (products) => {
        let with3dModel = products.filter(product => product.has3dModel);
        with3dModel = Product.sortByHighestPrice(with3dModel);
        let without3dModel = products.filter(product => !product.has3dModel);
        without3dModel = Product.sortByHighestPrice(without3dModel);
        return with3dModel.concat(without3dModel);
    };

    static sortByDelivery = (products) => {
        let with3dModel = products.filter(product => product.delivery);
        with3dModel = Product.sortByHighestPrice(with3dModel);
        let without3dModel = products.filter(product => !product.delivery);
        without3dModel = Product.sortByHighestPrice(without3dModel);
        return with3dModel.concat(without3dModel);
    };

    static sortByFreeDelivery = (products) => {
        let with3dModel = products.filter(product => product.freeDelivery);
        with3dModel = Product.sortByHighestPrice(with3dModel);
        let without3dModel = products.filter(product => !product.freeDelivery);
        without3dModel = Product.sortByHighestPrice(without3dModel);
        return with3dModel.concat(without3dModel);
    };

    static sortByPickup = (products) => {
        let with3dModel = products.filter(product => product.pickup);
        with3dModel = Product.sortByHighestPrice(with3dModel);
        let without3dModel = products.filter(product => !product.pickup);
        without3dModel = Product.sortByHighestPrice(without3dModel);
        return with3dModel.concat(without3dModel);
    };

    static sortByRelevance = (products, searchSorting) => {
        let unsortedProducts = [];
        products.forEach((product) => {
            const index = searchSorting.findIndex((productId) => productId === product.id);
            unsortedProducts.push({index: index, product: product});
        });
        const sortedProducts = unsortedProducts.sort((a, b) => a.index - b.index);
        return sortedProducts.map((item) => item.product);
    };

    static getSimilarByPriceAndRelevance = (baseProduct, products, maxResults) => {
        const moreExpensiveProducts = products.filter((p) => p.minPrice() > baseProduct.minPrice());
        const cheaperProducts = products.filter((p) => p.minPrice() <= baseProduct.minPrice());
        const results = [];
        moreExpensiveProducts.sort((p1, p2) => p2.score - p1.score);
        cheaperProducts.sort((p1, p2) => p2.score - p1.score);
        for (let i = 0; i < moreExpensiveProducts.length; i++) {
            if (results.length < maxResults) results.push(moreExpensiveProducts[i]);
            else break;
        }
        if (results.length < maxResults) {
            for (let i = 0; i < cheaperProducts.length; i++) {
                if (results.length < maxResults) results.push(cheaperProducts[i]);
                else break;
            }
        }
        return results;
    };

    static getSameCategoryProducts = (baseProduct, otherProducts, deepLevel) => {
        if (baseProduct && baseProduct.categoryPaths && otherProducts && otherProducts.length > 0 && deepLevel >= 0) {
            let allowedCategories = [];
            baseProduct.categoryPaths.forEach((path) => {
                const category = path[deepLevel];
                const exists = allowedCategories.includes(category);
                if (!exists) allowedCategories.push(category);
            });
            return otherProducts.filter((product) => {
                let presentInAllowedCategories = false;
                product.categories.forEach((category) => {
                    if (allowedCategories.includes(category)) presentInAllowedCategories = true;
                });
                return presentInAllowedCategories;
            });
        } else return otherProducts;
    };

    static getDatabaseMainPriceGroup = (pricesData) => {
        let response = {normal: undefined, offer: undefined, exclusive: undefined};
        if (pricesData) {
            let storeId = undefined;
            const storeIds = Object.keys(pricesData);
            for (let i = 0; i < storeIds.length; i++) {
                const prices = pricesData[storeIds[i]].prices;
                if (prices && prices.length > 0) {
                    storeId = storeIds[i];
                    break;
                }
            }
            if (storeId) {
                const prices = pricesData[storeId].prices;
                response.normal    = prices.find(price => price.type === 'normal'   );
                response.offer     = prices.find(price => price.type === 'sale'     );
                response.exclusive = prices.find(price => price.type === 'exclusive');
            }
        }
        return response;
    };

    clone() {
        return new Product({
            parent: this.parent,
            id: this.id,
            sku: this.sku,
            ean: this.ean,
            name: this.name,
            brand: this.brand,
            description: this.description,
            longDescription: this.longDescription,
            isFeatured: this.isFeatured,
            categories: this.categories,
            categoryPaths: this.categoryPaths,
            multimedia: this.multimedia ? this.multimedia.clone() : undefined,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            originUpdatedAt: this.originUpdatedAt,
            normalPrice: this.normalPrice,
            offerPrice: this.offerPrice,
            exclusivePrice: this.exclusivePrice,
            sellerName: this.sellerName,
            externalSale: this.externalSale,
            externalSaleUrl: this.externalSaleUrl,
            clientDelivery: this.clientDelivery,
            internationalShipping: this.internationalShipping,
            onRequest: this.onRequest,
            maxPerItem: this.maxPerItem,
            has3dModel: this.has3dModel,
            viewItSceneUrl: this.viewItSceneUrl,
            viewItArUrl: this.viewItArUrl,
            delivery: this.delivery,
            freeDelivery: this.freeDelivery,
            pickup: this.pickup,
            features: this.features && this.features.length > 0 ? this.features.map(feature => feature.clone()) : [],
            measures: this.measures && this.measures.length > 0 ? this.measures.map(measure => measure.clone()) : [],
            variants: this.variants && this.variants.length > 0 ? this.variants.map(variant => variant.clone()) : [],
            variantsMatches: this.variantsMatches,
            order: this.order,
            score: this.score
        });
    };
}

export const productConverter = {
    toFirestore() {
        return null
    },
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        const prices = Product.getDatabaseMainPriceGroup(data.info_stores);
        const productMultimedia = data.multimedia;
        const productGallery = productMultimedia && productMultimedia.gallery && productMultimedia.gallery.length > 0 ? productMultimedia.gallery.map(item => new MultimediaItem({
            l: item.l || undefined,
            m: item.m || undefined,
            s: item.s || undefined
        })) : [];
        const productMainImage = new MultimediaItem({
            l: productMultimedia && productMultimedia.main_image && productMultimedia.main_image.l ? productMultimedia.main_image.l : undefined,
            m: productMultimedia && productMultimedia.main_image && productMultimedia.main_image.m ? productMultimedia.main_image.m : undefined,
            s: productMultimedia && productMultimedia.main_image && productMultimedia.main_image.s ? productMultimedia.main_image.s : undefined
        });
        const deliveryOptions = data.delivery_options && data.delivery_options.length > 0 ? data.delivery_options : [];
        return new Product({
            id: snapshot.id,
            sku: data.sku,
            ean: data.ean,
            name: data.name,
            brand: data.brand,
            description: data.description,
            longDescription: data.long_description,
            isFeatured: data.is_featured,
            categories: data.categories,
            categoryPaths: data.category_paths,
            multimedia: new Multimedia({gallery: productGallery, mainImage: productMainImage}),
            createdAt: data.created_at,
            updatedAt: data.updated_at,
            originUpdatedAt: data.origin_updated_at,
            normalPrice: prices.normal ? prices.normal.value : undefined,
            offerPrice: prices.offer ? prices.offer.value : undefined,
            exclusivePrice: prices.exclusive ? prices.exclusive.value : undefined,
            sellerName: data.seller_name || undefined,
            externalSale: data.is_external_site_sell || false,
            externalSaleUrl: data.external_site_url || undefined,
            clientDelivery: data.is_client_delivery,
            internationalShipping: data.is_international_shipping,
            onRequest: data.is_custom_price || false,
            maxPerItem: data.max_per_item,
            has3dModel: data.has_3d_model || false,
            viewItSceneUrl: data.has_3d_model ? data.services.view_it.sceneUrl : undefined,
            viewItArUrl: data.has_3d_model ? data.services.view_it.arUrl : undefined,
            delivery: deliveryOptions.includes('Shipping'),
            freeDelivery: deliveryOptions.includes('FreeShipping'),
            pickup: deliveryOptions.includes('Pickup'),
            features: data.features && data.features.length > 0 ? data.features.map(feature => {
                return new Feature({
                    name: feature.name,
                    value: feature.value,
                    order: feature.order,
                    visible: feature.visibility,
                    indexable: feature.indexable
                });
            }) : [],
            measures: data.measures && data.measures.length > 0 ? data.measures.map(measure => {
                return new Measure({
                    name: measure.name,
                    value: measure.value,
                    unit: measure.unit
                });
            }) : [],
            variants: data.variants && data.variants.length > 0 && data.variants.map((variant) => {
                const variantMultimedia = variant.multimedia;
                const variantGallery = variantMultimedia && variantMultimedia.gallery && variantMultimedia.gallery.length > 0 ? variantMultimedia.gallery.map(item => new MultimediaItem({
                    l: item.l || undefined,
                    m: item.m || undefined,
                    s: item.s || undefined
                })) : [];
                const variantMainImage = new MultimediaItem({
                    l: variantMultimedia && variantMultimedia.main_image && variantMultimedia.main_image.l ? variantMultimedia.main_image.l : undefined,
                    m: variantMultimedia && variantMultimedia.main_image && variantMultimedia.main_image.m ? variantMultimedia.main_image.m : undefined,
                    s: variantMultimedia && variantMultimedia.main_image && variantMultimedia.main_image.s ? variantMultimedia.main_image.s : undefined
                });
                const variantPrices = Product.getDatabaseMainPriceGroup(variant.info_stores);
                return new Product({
                    id: variant.id,
                    sku: variant.sku,
                    name: variant.name,
                    normalPrice: variantPrices.normal ? variantPrices.normal.value : undefined,
                    offerPrice: variantPrices.offer ? variantPrices.offer.value : undefined,
                    exclusivePrice: variantPrices.exclusive ? variantPrices.exclusive.value : undefined,
                    maxPerItem: variant.max_per_item,
                    multimedia: new Multimedia({gallery: variantGallery, mainImage: variantMainImage}),
                    onRequest: variant.is_custom_price || false
                });
            }),
            order: data.order || Number.MAX_SAFE_INTEGER,
            score: undefined
        });
    }
};

export class Multimedia {
    constructor({gallery, mainImage}) {
        this.gallery = gallery;
        this.mainImage = mainImage;
    };

    clone() {
        return new Multimedia({
            gallery: this.gallery && this.gallery.length > 0 ? this.gallery.map(item => item.clone()) : [],
            mainImage: this.mainImage ? this.mainImage.clone() : undefined
        });
    };
}

export class MultimediaItem {
    constructor({l, m, s}) {
        this.l = l;
        this.m = m;
        this.s = s;
    };

    clone() {
        return new MultimediaItem({
            l: this.l,
            m: this.m,
            s: this.s
        });
    }
}

export class Feature {
    constructor({name, value, order, visible, indexable}) {
        this.name = name;
        this.value = value;
        this.order = order;
        this.visible = visible;
        this.indexable = indexable;
    };

    clone() {
        return new Feature({
            name: this.name,
            value: this.value,
            order: this.order,
            visible: this.visible,
            indexable: this.indexable
        });
    }
}

export class Measure {
    constructor({name, value, unit}) {
        this.name = name;
        this.value = value;
        this.unit = unit;
    };

    clone() {
        return new Measure({
            name: this.name,
            value: this.value,
            unit: this.unit
        });
    };
}