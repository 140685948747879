import React from 'react';
import './SpinnerLoader.css';

function SpinnerLoader({size, color}) {
    const containerStyle = () => {
        return {
            width: size ? size : 100,
            height: size ? size : 100
        };
    };

    const itemStyle = () => {
        return {
            width: size ? size : 100,
            height: size ? size : 100,
            borderColor: `${color ? color : '#111111'} transparent transparent transparent`
        };
    };

    return (
        <div className='spinner-loader' style={containerStyle()}>
            <div style={itemStyle()}></div>
            <div style={itemStyle()}></div>
            <div style={itemStyle()}></div>
            <div style={itemStyle()}></div>
        </div>
    );
}

export default SpinnerLoader;