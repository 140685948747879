export default class Image {
    static getBase64(url) {
        return new Promise((resolve, reject) => {
            fetch(url)
                .then((data) => {
                    return data.blob();
                })
                .then((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = () => {
                        reject(undefined);
                    };
                    reader.readAsDataURL(blob);
                })
                .catch(() => {
                    reject(undefined);
                });
        });
    };
}