import React, {useState, useEffect} from 'react';
import Button from "../Button/Button";
import './Slider.css';

function Slider({initialValue, min, max, step, highlightColor, onChange, onCommit, onRelease}) {
    const [value, setValue] = useState(initialValue);
    const [_timeout, _setTimeout] = useState(null);

    useEffect(() => {
        if (initialValue !== value) setValue(initialValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    const handleChange = (event) => {
        const value = parseInt(event.target.value);
        handleValueChange(value);
    };

    const handleRelease = (event) => {
        const value = parseInt(event.target.value);
        if (onRelease) onRelease(value);
    };

    const handleMinusButtonClick = () => {
        if (value > min) {
            const newValue = value - step;
            handleValueChange(newValue);
            if (onRelease) onRelease(newValue);
        }
    };

    const handlePlusButtonClick = () => {
        if (value < max) {
            const newValue = value + step;
            handleValueChange(newValue);
            if (onRelease) onRelease(newValue);
        }
    };

    const handleValueChange = (value) => {
        setValue(value);
        if (onChange) onChange(value);
        if (_timeout) clearTimeout(_timeout);
        _setTimeout(setTimeout(() => {
            if (onCommit) onCommit(value);
        }, 100));
    };

    const sliderStyle = () => {
        const color = highlightColor || '#FDBB31';
        const percent = Math.round((max - min) > 0 ? (((value - min) * 100) / (max - min)) : 0);
        return {
            background: `linear-gradient(to right, ${color} 0%, ${color} ${percent}%, #EDEDED ${percent}%, #EDEDED 100%)`
        };
    };

    return (
        <div className='slider'>
            <Button
                label='-'
                labelColor='#DFDFDF'
                backgroundColor='#FFFFFF'
                borderWidth='0'
                borderRadius='10px'
                padding='0'
                margin='10px'
                width={32}
                minWidth={32}
                height={32}
                boxShadow='0 0 5px rgba(0, 0, 0, 0.207878)'
                isLoading={false}
                disabled={false}
                showPulseEffect={false}
                onClick={handleMinusButtonClick}
            />
            <div className='slider-wrapper'>
                <input
                    className='slider-input'
                    type='range'
                    min={min}
                    max={max}
                    value={value}
                    step={step}
                    onMouseUp={handleRelease}
                    onTouchEnd={handleRelease}
                    onChange={handleChange}
                    style={sliderStyle()}
                />
            </div>
            <Button
                label='+'
                labelColor='#DFDFDF'
                backgroundColor='#FFFFFF'
                borderWidth='0'
                borderRadius='10px'
                padding='0'
                margin='10px'
                width={32}
                minWidth={32}
                height={32}
                boxShadow='0 0 5px rgba(0, 0, 0, 0.207878)'
                isLoading={false}
                disabled={false}
                showPulseEffect={false}
                onClick={handlePlusButtonClick}
            />
        </div>
    );
}

export default Slider;