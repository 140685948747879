import {firestore, getCollectionPath} from "../config/Firebase";
import {doc, updateDoc} from "firebase/firestore";

export class ShoppingCart {
    constructor({id, dbId, client, items}) {
        this.id = id;
        this.dbId = dbId;
        this.client = client;
        this.items = items || [];
    };

    addItem(item) {
        const matchIndex = this.items.findIndex(i => i.id === item.id);
        if (matchIndex >= 0) this.items[matchIndex].quantity += 1;
        else this.items.push(item);
    };

    deleteItem(id) {
        this.items = this.items.filter(item => item.id !== id);
    };

    clear() {
        this.items = [];
    };

    getTotal() {
        let total = 0;
        for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i];
            const itemTotal = (item.offerPrice || item.normalPrice) && item.quantity ? (item.offerPrice || item.normalPrice) * item.quantity : undefined;
            if (itemTotal) total += itemTotal;
            else {
                total = undefined;
                break;
            }
        }
        return total;
    };

    getExclusiveTotal() {
        let total = 0;
        for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i];
            const itemTotal = (item.exclusivePrice || item.offerPrice || item.normalPrice) && item.quantity ? (item.exclusivePrice || item.offerPrice || item.normalPrice) * item.quantity : undefined;
            if (itemTotal) total += itemTotal;
            else {
                total = undefined;
                break;
            }
        }
        return total;
    };

    updateMode(mode) {
        if (this.client && this.dbId && mode) {
            const _path = getCollectionPath(this.client, 'carts');
            const _doc = doc(firestore, _path, this.dbId);
            updateDoc(_doc, {checkout_mode: mode});
        }
    };

    getMaxPriceItem() {
        let maxPrice = 0;
        let maxItem = undefined;
        this.items.forEach(item => {
            const price = item.exclusivePrice || item.offerPrice || item.normalPrice;
            if (price > maxPrice) {
                maxPrice = price;
                maxItem = item;
            }
        });
        return maxItem;
    };

    hasQueryIds = () => {
        return this.items.some(item => !!item.queryId);
    };

    listProductsIds = () => {
        return this.items.map((item) => {
            if (item.type === 'variant') return item.parent;
            else return item.product;
        });
    };

    static fromStorage(data) {
        const shoppingCart = new ShoppingCart({id: data.id, dbId: undefined, client: data.client, items: []});
        data.items.forEach(item => shoppingCart.addItem(ShoppingCartItem.fromStorage(item)));
        return shoppingCart;
    };

    toStorage() {
        return {
            id: this.id,
            client: this.client,
            items: this.items.map(item => item.toStorage())
        };
    };

    toActivityLog() {
        return {
            id: this.id,
            client: this.client,
            items: this.items.map((item) => item.toActivityLog())
        };
    };

    toCheckout() {
        return {
            details: this.items.map(item => item.toCheckout()).filter(item => item.quantity > 0)
        };
    };

    clone() {
        return new ShoppingCart({
            id: this.id,
            dbId: this.dbId,
            client: this.client,
            items: this.items.map(item => item.clone())
        });
    };
}

export class ShoppingCartItem {
    constructor({id, product, type, parent, name, quantity, minQuantity, maxQuantity, normalPrice, offerPrice, exclusivePrice, queryId}) {
        this.id = id;
        this.product = product;
        this.type = type;
        this.parent = parent;
        this.name = name;
        this.quantity = quantity;
        this.minQuantity = minQuantity;
        this.maxQuantity = maxQuantity;
        this.normalPrice = normalPrice;
        this.offerPrice = offerPrice;
        this.exclusivePrice = exclusivePrice;
        this.queryId = queryId;
    };

    increaseQuantity() {
        if (this.quantity < this.maxQuantity) this.quantity += 1;
    };

    decreaseQuantity() {
        if (this.quantity > this.minQuantity) this.quantity -= 1;
    };

    minPrice() {
        return this.exclusivePrice || this.offerPrice || this.normalPrice;
    };

    minKioskPrice() { // This function exists because we cannot detect which card the user has to be able to apply the exclusive price (e.g. Lider card), so the minimum value for buying with integrated POS should be always offerPrice.
        return this.offerPrice || this.normalPrice;
    };

    getOfferDiscount() {
        return this.offerPrice ? this.normalPrice - this.offerPrice : 0;
    };

    toStorage() {
        return {
            id: this.id,
            product: this.product,
            type: this.type,
            parent: this.parent,
            name: this.name,
            quantity: this.quantity,
            minQuantity: this.minQuantity,
            maxQuantity: this.maxQuantity,
            normalPrice: this.normalPrice,
            offerPrice: this.offerPrice,
            exclusivePrice: this.exclusivePrice,
            queryId: this.queryId
        };
    };

    toActivityLog(quantity) {
        return {
            id: this.id,
            product: this.product,
            type: this.type,
            parent: this.parent || null,
            name: this.name || null,
            quantity: quantity >= 0 ? quantity : this.quantity,
            min_quantity: this.minQuantity !== undefined ? this.minQuantity : null,
            max_quantity: this.maxQuantity !== undefined ? this.maxQuantity : null,
            normal_price: this.normalPrice !== undefined ? this.normalPrice : null,
            offer_price: this.offerPrice !== undefined ? this.offerPrice : null,
            exclusive_price: this.exclusivePrice !== undefined ? this.exclusivePrice : null,
            query_id: this.queryId || null
        };
    };

    toCheckout() {
        return {
            id: this.parent ? this.parent : this.product,
            variant: this.parent ? this.product : null,
            quantity: this.quantity
        };
    };

    toAlgoliaInsights() {
        const itemData = {
            price: this.minKioskPrice(),
            quantity: this.quantity,
            discount: this.getOfferDiscount()
        };
        if (this.queryId) itemData.queryID = this.queryId;
        return itemData;
    };

    static fromStorage(data) {
        return new ShoppingCartItem({
            id: data.id,
            product: data.product,
            type: data.type,
            parent: data.parent,
            name: data.name,
            quantity: data.quantity,
            minQuantity: data.minQuantity,
            maxQuantity: data.maxQuantity,
            normalPrice: data.normalPrice,
            offerPrice: data.offerPrice,
            exclusivePrice: data.exclusivePrice,
            queryId: data.queryId
        });
    };

    clone() {
        return new ShoppingCartItem({
            id: this.id,
            product: this.product,
            type: this.type,
            parent: this.parent,
            name: this.name,
            quantity: this.quantity,
            minQuantity: this.minQuantity,
            maxQuantity: this.maxQuantity,
            normalPrice: this.normalPrice,
            offerPrice: this.offerPrice,
            exclusivePrice: this.exclusivePrice,
            queryId: this.queryId
        });
    };
}