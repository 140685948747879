import React from 'react';
import './LinkButton.css';

function LinkButton({icon, label, labelColor, onClick}) {
    const handleClick = (event) => {
        event.stopPropagation();
        if (onClick) onClick();
    };

    const buttonStyle = () => {
        return {
            color: labelColor || '#FFFFFF'
        };
    };

    return (
        <div className='link-button' onClick={handleClick}>
            {icon && <img className='link-button-icon' src={icon} alt={label || 'Label'}/>}
            <div className='link-button-label' style={buttonStyle()}>{label || 'Label'}</div>
        </div>
    );
}

export default LinkButton;