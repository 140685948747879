import React, {useRef, useState, useEffect} from 'react';
import usePrevious from "../../../../helpers/UsePrevious";
import PaymentOptionSelection from '../../PaymentSteps/PaymentOptionSelection/PaymentOptionSelection';
import PaymentQRScan from '../../PaymentSteps/PaymentQRScan/PaymentQRScan';
import PaymentIframe from '../../PaymentSteps/PaymentIframe/PaymentIframe';
import Activity from '../../../../helpers/Activity';
import defaultSmartphoneOptionImage from './images/smartphone-option.png';
import defaultKioskOptionImage from './images/kiosk-option.png';
import hourglassIcon from './images/hourglass.png';
import './ExternalCheckout.css';

function ExternalCheckout({
    checkoutUrl,
    shoppingCart,
    smartphoneOptionImage,
    kioskOptionImage,
    smartphoneOptionQrScanImage,
    cancelButtonBackgroundColor,
    cancelButtonBorderColor,
    cancelButtonLabelColor,
    backButtonBackgroundColor,
    backButtonBorderColor,
    backButtonLabelColor,
    onClose,
    metadata
}) {
    const intervalRef = useRef(null);
    const [step, setStep] = useState('start');
    const [waitingMode, setWaitingMode] = useState(false);
    const [isInitialIframeLoad, setIsInitialIframeLoad] = useState(true);
    const [iframeIntervalTime, setIframeIntervalTime] = useState(0);
    const previousStep = usePrevious(step);
    const iframeWaitPeriod = 5; // Seconds.

    useEffect(() => {
        switch (step) {
            case 'start':
                if (previousStep === 'kiosk') initializeSelectionMode();
                break;
            case 'smartphone':
                break;
            case 'kiosk':
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (iframeIntervalTime >= iframeWaitPeriod) stopIframeInterval();
    }, [iframeIntervalTime]);

    const initializeSelectionMode = () => {
        if (!isInitialIframeLoad) startIframeInterval();
    };

    const onIframeStartup = () => {
        setIsInitialIframeLoad(false);
    };

    const startIframeInterval = () => {
        setWaitingMode(true);
        intervalRef.current = setInterval(() => {
            setIframeIntervalTime(iframeIntervalTime => iframeIntervalTime + 1);
        }, 1000);
    };

    const stopIframeInterval = () => {
        setWaitingMode(false);
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            setIframeIntervalTime(0);
        }
    };

    const handleOptionClick = (type) => {
        if (['smartphone', 'kiosk'].includes(type)) Activity.log(metadata, 'checkout', `${type}-option`, `pay-in-${type}`, {type: 'external-checkout', shoppingCart: shoppingCart.toActivityLog()});
        setStep(type);
        saveStep(type);
    };

    const handleClose = (closeType) => {
        Activity.log(metadata, 'checkout', closeType, 'close', {type: 'external-checkout'});
        if (onClose) onClose();
    };

    const handleBack = () => {
        Activity.log(metadata, 'checkout', 'back-button', 'back', {type: 'external-checkout'});
        setStep('start');
    };

    const saveStep = (step) => {
        shoppingCart.updateMode(step);
    };

    const renderStep = () => {
        switch (step) {
            case 'start':
                return renderStartStep();
            case 'smartphone':
                return renderSmartphoneStep();
            case 'kiosk':
                return renderKioskStep();
            default:
                return undefined;
        }
    };

    const renderStartStep = () => {
        const options = [
            {
                type: 'smartphone',
                icon: smartphoneOptionImage || defaultSmartphoneOptionImage,
                label1: 'Ingreso de datos desde celular y ',
                label2: 'ticket digital',
                boldLabel1: false,
                boldLabel2: true,
                labelLineBreak: false,
                waitingMode: false,
                waitingModeIcon: undefined,
                waitingModeLabel: undefined,
                onClick: handleOptionClick
            },
            {
                type: 'kiosk',
                icon: kioskOptionImage || defaultKioskOptionImage,
                label1: 'Ingreso de datos desde esta pantalla y ',
                label2: 'ticket impreso',
                boldLabel1: false,
                boldLabel2: true,
                labelLineBreak: false,
                waitingMode: waitingMode,
                waitingModeIcon: hourglassIcon,
                waitingModeLabel: `Preparando checkout, ${iframeWaitPeriod - iframeIntervalTime} segundo(s) restantes...`,
                onClick: handleOptionClick
            }
        ];
        return (
            <PaymentOptionSelection
                title1='Selecciona como quieres completar tus datos y '
                title2='recibir tu ticket para pagar en caja'
                titleLineBreak={false}
                options={options}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                backButtonBackgroundColor={undefined}
                backButtonBorderColor={undefined}
                backButtonLabelColor={undefined}
                onClose={() => handleClose('custom-close-button')}
                onBack={undefined}
            />
        );
    };

    const renderSmartphoneStep = () => {
        return (
            <PaymentQRScan
                checkoutUrl={checkoutUrl}
                smartphoneOptionQrScanImage={smartphoneOptionQrScanImage}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                backButtonBackgroundColor={backButtonBackgroundColor}
                backButtonBorderColor={backButtonBorderColor}
                backButtonLabelColor={backButtonLabelColor}
                onClose={() => handleClose('custom-close-button')}
                onBack={handleBack}
            />
        );
    };

    const renderKioskStep = () => {
        return (
            <PaymentIframe
                checkoutUrl={checkoutUrl}
                onStartup={onIframeStartup}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                backButtonBackgroundColor={backButtonBackgroundColor}
                backButtonBorderColor={backButtonBorderColor}
                backButtonLabelColor={backButtonLabelColor}
                onClose={() => handleClose('custom-close-button')}
                onBack={handleBack}
            />
        );
    };

    return (
        <div className='external-checkout'>
            {renderStep()}
        </div>
    );
}

export default ExternalCheckout;